import React from "react";
import DarkFooter from "components/Footers/DarkFooter.js";
import MainNavbar from "components/Navbars/MainNavbar";
import "../../assets/css/news.scss";
import { Col, Row } from "reactstrap";
import ReactPaginate from "react-paginate"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import ReadMoreReact from 'read-more-react';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import moment from "moment";

function News() {
    const [selectedNewsTab, setNewsTab] = React.useState(0);
    const [newsArrayList, setNewsArrayList] = React.useState([]);
    const [totalPages, setTotalPages] = React.useState(0);
    const [pageIndex, setPageIndex] = React.useState(1);
    const [loadingState, setLoadingState] = React.useState(false);
    const [apiLink, setApiLink] = React.useState("https://yeebet.info/wp-json/wp/v2/posts?tags=26");
    TimeAgo.addDefaultLocale(en);
    const timeAgo = new TimeAgo('en-US');

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    React.useEffect(() => {
        setLoadingState(true);
        getAllNewsPost();
    }, [pageIndex, apiLink]);

    React.useEffect(() => {
        if (selectedNewsTab === 0) {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=45,46,47,48")
        }
        else if (selectedNewsTab === 1) {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=63,1288,43,68")
        }
        else if (selectedNewsTab === 2) {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=49,50,51,52,53,54,55,56")
        }
        else if (selectedNewsTab === 3) {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=126")
        }
        setPageIndex(1);
    }, [selectedNewsTab]);


    const changePage = (event) => {
        setPageIndex(event.selected + 1);
    }

    // https://yeebet.info/wp-json/wp/v2/posts?tags=26 = NEWS
    // https://yeebet.info/wp-json/wp/v2/posts?categories=52,56 = Regions
    // https://yeebet.info/wp-json/wp/v2/posts?categories=126 = Videos
    // https://yeebet.info/wp-json/wp/v2/posts?categories=63,1288,43,68 = iGaming

    function getAllNewsPost() {
        fetch(apiLink + "&per_page=10&page=" + pageIndex.toString(), {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": "en"
            }),
        })
            .then((response) => {
                return Promise.all([response.json(), response.headers]);
            }).then(([responseJson, headers]) => {
                // console.log("Header", headers.get('X-WP-TotalPages'));
                console.log("response", newsArrayList);
                setNewsArrayList(responseJson);
                setTotalPages(headers.get('X-WP-TotalPages'));
                setLoadingState(false);
            })
            .catch(error => {
                console.error("error", error);
            });
    }

    function ConvertToMilliSecond(publishDateTime) {
        var myDate = new Date(publishDateTime);
        // var result = myDate.getTime();
        var result = moment(myDate).valueOf();
        console.log("RESULT", result);
        var dateNow = moment().valueOf();
        var dateDiff = dateNow - result;
        return dateDiff;
    }

    return (
        <>
            <MainNavbar />

            <div className="" id="news">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">News</span>
                </div> */}

                <div className="news-Bg">
                    {/* <div className="header-section position-relative"></div> */}

                    <div className="featuredVideos py-5 position-relative">
                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={6} md={9} sm={12} xs={12}>
                                <div className="text-center">
                                    <span className="headerTitle">All Latest News</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    <span className="brandsDesc">
                                        You may watch the latest news in here.
                                    </span> <br />
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={9} md={11} sm={12} xs={12} className="paddingNarrow">
                                <Row className="justify-content-start justify-content-md-center align-items-center py-5 text-center">
                                    <Col lg={2} md={6} sm={6} xs={6} className="mb-3 paddingNarrow">
                                        <div className={selectedNewsTab === 0 ? "newsActiveTab py-2" : "newsTab py-2"} onClick={() => setNewsTab(0)}>
                                            <span>News</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={6} sm={6} xs={6} className="mb-3 paddingNarrow">
                                        <div className={selectedNewsTab === 1 ? "newsActiveTab py-2" : "newsTab py-2"} onClick={() => setNewsTab(1)}>
                                            <span>iGaming</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={6} sm={6} xs={6} className="mb-3 paddingNarrow">
                                        <div className={selectedNewsTab === 2 ? "newsActiveTab py-2" : "newsTab py-2"} onClick={() => setNewsTab(2)}>
                                            <span>Regions</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={6} sm={6} xs={6} className="mb-3 paddingNarrow">
                                        <div className={selectedNewsTab === 3 ? "newsActiveTab py-2" : "newsTab py-2"} onClick={() => setNewsTab(3)}>
                                            <span>Videos</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="justify-content-center align-items-center pb-5 text-center " style={{ position: "sticky", zIndex: "10" }}>
                            <Col lg={12} md={11} sm={12} xs={12}>
                                <div className="brands-card py-5 mx-auto position-relative" style={{ minHeight: "100vh", maxWidth: "1300px" }}>
                                    {!loadingState ?
                                        newsArrayList.map((item, i) => (
                                            <a href={'/news-details/' + selectedNewsTab + '/' + item.slug}>
                                                <Row className="justify-content-center align-items-center text-center px-1 px-lg-5 pb-4">
                                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center mb-4">
                                                        <div className="newsBorderDivider"></div>
                                                    </Col>
                                                    <Col lg={6} md={12} sm={12} xs={12} className="text-center">
                                                        <img src={item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "WebPage") && item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "WebPage").image ?
                                                            item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "WebPage").image.url : require('../../assets/img/news/dummyImg.PNG').default}
                                                            className="mx-auto newsImg" />
                                                    </Col>
                                                    <Col lg={6} md={12} sm={12} xs={12} className="text-center text-lg-left pt-3 pt-lg-0">
                                                        {/* <span className="newsCategory">{item.aioseo_head_json.schema["@graph"][0].articleSection}</span> <br /> */}
                                                        <span className="newsTitle">{(item.aioseo_head_json.title).substring(0, item.aioseo_head_json.title.length - 13)}</span> <br />

                                                        <div className="pt-3">
                                                            <span className="newsDesc">
                                                                {/* <ReadMoreReact
                                                                    text={item.aioseo_head_json.description}
                                                                    min={100}
                                                                    ideal={100}
                                                                    max={100}
                                                                    readMoreText={""}
                                                                /> */}
                                                                {item.aioseo_head_json.description}
                                                            </span>
                                                        </div>

                                                        <div className="pt-3">
                                                            <div className="d-flex justify-content-center justify-content-lg-start align-items-center text-center">
                                                                <div className="d-flex justify-content-center justify-content-lg-start">
                                                                    <img src={item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person") && item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").image ?
                                                                        item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").image.url : "https://secure.gravatar.com/avatar/c6dff3a489168ff0434c028d870e09f0?s=96&d=mm&r=g"}
                                                                        className="mx-auto newsAuthorPic" />
                                                                    <div className="ml-2 pt-1">
                                                                        <span className="newsDesc">{item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person") && item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").image ?
                                                                            item.aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").name : "Sukizu_metasoft"}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="newsDetailsDivider d-md-block mx-3"></div>
                                                                <div className="d-flex justify-content-center justify-content-lg-start">
                                                                    <img src={require('../../assets/img/news/time-icon.png').default} className="img-fluid timeIconSize" />
                                                                    <div className="ml-3">
                                                                        <span className="newsDesc">{timeAgo.format(Date.now() - ConvertToMilliSecond(item.date))}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </a>
                                        ))
                                        :
                                        <div className="position-absolute" style={{ left: "0", right: "0", top: "40%" }}>
                                            <div class="loader mx-auto">
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                            </div>
                                        </div>
                                    }
                                    <div className={!loadingState && totalPages > 1 ? "d-block" : "d-none"}>
                                        <Row className="justify-content-center align-items-center text-center px-1 px-lg-5">
                                            <Col lg={12} md={12} sm={12} xs={12} className="text-center mb-5">
                                                <div className="newsBorderDivider"></div>
                                                <div className='text-center mx-auto d-flex justify-content-center pt-5'>
                                                    <ReactPaginate
                                                        previousLabel={<FaAngleLeft />}
                                                        nextLabel={<FaAngleRight />}
                                                        pageCount={totalPages}
                                                        onPageChange={changePage}
                                                        containerClassName={"pagination"}
                                                        previousLinkClassName={"pagination__previouslink"}
                                                        nextLinkClassName={"pagination__nextlink"}
                                                        disabledClassName={"pagination__link--disabled"}
                                                        activeClassName={"pagination__link--active"}
                                                        breakLabel="..."
                                                        pageRangeDisplayed={1}
                                                        marginPagesDisplayed={2}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="sideItem1Pos-news">
                            <img src={require('../../assets/img/news/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-news">
                            <img src={require('../../assets/img/news/sideItem2.png').default} className="img-fluid" />
                        </div>
                    </div>

                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default News;
