import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import MainNavbar from "components/Navbars/MainNavbar";
import "../../assets/css/yeebetLive.scss";
import { Col, Row } from "reactstrap";
import Marquee from "react-fast-marquee";
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import currency_logo1 from "../../assets/img/yeebetLive/currency1.png";
import currency_logo2 from "../../assets/img/yeebetLive/currency2.png";
import currency_logo3 from "../../assets/img/yeebetLive/currency3.png";
import currency_logo4 from "../../assets/img/yeebetLive/currency4.png";
import currency_logo5 from "../../assets/img/yeebetLive/currency5.png";
import currency_logo6 from "../../assets/img/yeebetLive/currency6.png";
import currency_logo7 from "../../assets/img/yeebetLive/currency7.png";
import currency_logo8 from "../../assets/img/yeebetLive/currency8.png";
import currency_logo9 from "../../assets/img/yeebetLive/currency9.png";
import currency_logo10 from "../../assets/img/yeebetLive/currency10.png";
import currency_logo11 from "../../assets/img/yeebetLive/currency11.png";

import country_flag1 from "../../assets/img/yeebetLive/country1.png";
import country_flag2 from "../../assets/img/yeebetLive/country2.png";
import country_flag3 from "../../assets/img/yeebetLive/country3.png";
import country_flag4 from "../../assets/img/yeebetLive/country4.png";
import country_flag5 from "../../assets/img/yeebetLive/country5.png";
import country_flag6 from "../../assets/img/yeebetLive/country6.png";
import country_flag7 from "../../assets/img/yeebetLive/country7.png";
import country_flag8 from "../../assets/img/yeebetLive/country8.png";
import country_flag9 from "../../assets/img/yeebetLive/country9.png";
import country_flag10 from "../../assets/img/yeebetLive/country10.png";
import country_flag11 from "../../assets/img/yeebetLive/country11.png";
import country_flag12 from "../../assets/img/yeebetLive/country12.png";
import country_flag13 from "../../assets/img/yeebetLive/country13.png";
import country_flag14 from "../../assets/img/yeebetLive/country14.png";

const currency_Array = [
    { img: currency_logo1, name: "Malaysia Ringgit" },
    { img: currency_logo2, name: "Hong Kong Dollar" },
    { img: currency_logo3, name: "Singapore Dollar" },
    { img: currency_logo4, name: "Thailand Baht" },
    { img: currency_logo5, name: "Brazil Real" },
    { img: currency_logo6, name: "Viet Nam Dong" },
    { img: currency_logo7, name: "Indonesia Rupiah" },
    { img: currency_logo8, name: "India Rupee" },
    { img: currency_logo9, name: "China Yuan Renminbi" },
    { img: currency_logo10, name: "Philippines Peso" },
    { img: currency_logo11, name: "Cryptocurrency" },
]

const country_flagList = [
    { img: country_flag1, name: "简体中文" },
    { img: country_flag2, name: "繁体中文" },
    { img: country_flag3, name: "English" },
    { img: country_flag4, name: "한국어" },
    { img: country_flag5, name: "ภาษาไทย" },
    { img: country_flag6, name: "Tiếng Việt" },
    { img: country_flag7, name: "हिंदी" },
    { img: country_flag8, name: "Bahasa Malay" },
    { img: country_flag9, name: "日本語" },
    { img: country_flag10, name: "Русский" },
    { img: country_flag11, name: "၁။ ဗမာ" },
    { img: country_flag12, name: "Português" },
    { img: country_flag13, name: "Türkçe" },
    { img: country_flag14, name: "українська" },
]

function YeebetLive() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <MainNavbar />

            <div className="" id="yeebetLive">
                <div className="d-none d-lg-block">
                    <Fullpage>
                        <FullPageSections>
                            <FullpageSection className="fullPageHeight">
                                <div className="header-section position-relative">
                                    <Row className="justify-content-center align-items-center text-center pt-5 pt-md-0">
                                        <Col lg={10} md={11} sm={12} xs={12}>
                                            <Row className="justify-content-center align-items-center text-center">
                                                <Col lg={4} md={6} sm={8} xs={8}>
                                                    <img src={require('../../assets/img/yeebetLive/header-logo.png').default} className="img-fluid mb-4" />
                                                </Col>
                                                <Col lg={5} md={10} sm={12} xs={12} className="text-center text-lg-left">
                                                    <span className="headerTitle">Introduction</span> <br />
                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                    <span className="brandsDesc">
                                                        Yeebet Live recognized as “The Fastest Growing Live Games Provider In Asia ” Yeebet Live is well known for their magnificent Baccarat gaming platform developed through the experience and hard work of dozens of gaming experts leveraging the advantages of network technology. The designs and function of the platform have all been developed with the purpose to exceed client expectations.
                                                        <br /><br />
                                                        Yeebet Live adheres their goals by providing “Fairness, Safety, and Assurance” in the gaming environment, and is authorized by Curacao E-gaming — the Curacao government’s official regulating department. Furthermore, Yeebet Live operation platform is certified by the independent party, Gaming Laboratory International (GLI) & MGA as well.
                                                    </span> <br />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="liveCasino-bg position-relative alignPosCenter">
                                    <Row className="justify-content-center align-items-center text-center pt-5">
                                        <Col lg={10} md={10} sm={11} xs={11}>
                                            <span className="headerTitle">Live Casino</span> <br />
                                            <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                            <span className="brandsDesc">
                                                Yeebet Live provides an immersive and interactive gaming experience with its extensive selection of Live Casino games, such as Baccarat, BlackJack, Roulette, Dragon Tiger, Hi-Lo Game, Casino Hold’em, Poker Bet-on, Keno, Swift Roulette, Russian Poker and Super Six Baccarat. For players from different parts of the world there are 14 languages available. The interface offers animation and sound effects that enhance the gaming experience as well as chat with the dealer or other players at the table. Moreover we offer more than 30 side bets in all games plus various limits on each table game. Our multi-game view technology makes it possible to follow several games at once while our multi-camera technology ensures a realistic touch to every round of play.
                                            </span>
                                        </Col>

                                        <Col lg={10} md={11} sm={12} xs={12}>
                                            <Row className="justify-content-center align-items-center pt-5 text-center">
                                                <Col lg={12} md={12} sm={12} xs={12} className="text-center mb-5">
                                                    <div className="pb-4">
                                                        <Carousel className="px-5" showArrows={false} preventMovementUntilSwipeScrollTolerance={true} infiniteLoop autoPlay showThumbs={false} showStatus={false}>

                                                            <Row className="justify-content-center align-items-center text-center pb-5">
                                                                <Col lg={5} md={7} sm={12} xs={12} className="text-center">
                                                                    <img src={require('../../assets/img/yeebetLive/liveCasino1.png').default} className="img-fluid yeebetLiveAPIImg" />
                                                                </Col>
                                                                <Col lg={7} md={10} sm={12} xs={12} className="text-left">
                                                                    <span className="headerTitle">Yeebet Live API Solution</span> <br />
                                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                                    <span className="brandsDesc">
                                                                        At Yeebet Live, our live games api solution is of the highest quality and competitively priced - providing a gateway to achieve business growth. Our games are especially attractive to players who generally engage in higher wager values, as each and every one of them offers betting elements and online-facing features. Our Omni-channel capability means our games can be accessed via retail betting shops, land-based casinos, online or mobile platforms on any device. We also offer an innovative, highly customisable and user-friendly gaming interface with multiple table limits, rich game stats and animation/sound effects. Furthermore, players have the option to chat with dealers/fellow players at the table. To elevate the level of entertainment further, we provide multiple game bonuses & promotions which will help extend playing hours of existing customers while also enabling you to attract new players.
                                                                    </span>
                                                                </Col>
                                                            </Row>

                                                            <Row className="justify-content-center align-items-center text-center pb-5">
                                                                <Col lg={7} md={10} sm={12} xs={12} className="text-left mb-5 order-2 order-lg-1">
                                                                    <span className="headerTitle">Yeebet Feed Solution</span> <br />
                                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                                    <span className="brandsDesc">
                                                                        Yeebet live casino data & feed has found massive success due to its quality and quantity. Each game is certified by industry-leading authorities, making it a universal choice for players across all demographics. With the options to modify interfaces, operators have the freedom to make the product their own. The video streaming, results and monitored game flow can be changed with added accompaniments in any language desired. We are proud of this level of flexibility available through our offering.
                                                                    </span>
                                                                </Col>
                                                                <Col lg={5} md={7} sm={12} xs={12} className="text-center mb-5 order-1 order-lg-2">
                                                                    <img src={require('../../assets/img/yeebetLive/liveCasino2.png').default} className="img-fluid yeebetLiveAPIImg" />
                                                                </Col>
                                                            </Row>

                                                            <Row className="justify-content-center align-items-center text-center pb-5">
                                                                <Col lg={5} md={7} sm={12} xs={12} className="text-center">
                                                                    <img src={require('../../assets/img/yeebetLive/liveCasino3.png').default} className="img-fluid yeebetLiveAPIImg" />
                                                                </Col>
                                                                <Col lg={7} md={10} sm={12} xs={12} className="text-left">
                                                                    <span className="headerTitle">Yeebet Dedicated Table Solution</span> <br />
                                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                                    <span className="brandsDesc">
                                                                        With dedicated solutions, we offer a variety of options, including tables with appropriate equipment and design, halls with different games, or enabling our partner's table to become independent providers, as Yeebet Live did. Furthermore, these variations can include a variety of solutions based on the client's needs, such as developing dedicated live casino games, constructing branded halls and tables, or setting up professional equipment.
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        </Carousel>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <div className="sideItem1Pos-about">
                                        <img src={require('../../assets/img/aboutUs/sideItem1.png').default} className="img-fluid" />
                                    </div>

                                    <div className="sideItem2Pos-about">
                                        <img src={require('../../assets/img/aboutUs/sideItem2.png').default} className="img-fluid" />
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="casinoFeatured-bg position-relative py-5">
                                    <div>
                                        <Row className="justify-content-center align-items-center py-5 text-center">
                                            <Col lg={8} md={10} sm={11} xs={11}>
                                                <span className="headerTitle">Yeebet Live Casino Featured</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" />
                                                {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.                            
                                </span> <br /> */}
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center align-items-center py-2 text-center">
                                            <Col lg={9} md={11} sm={12} xs={12}>
                                                <Row className="justify-content-center align-items-start py-0 py-lg-2 text-center">
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature1.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">HD to full HD quality streaming</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature2.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Multiple providers via single channel</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature3.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">HTML5 game interface</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature5.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Real games in real time 24/7</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature6.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Multi-game view</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature7.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Live chat with dealers and between players</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature8.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Flexible customisation</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature9.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Additional and side bets in each game</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature10.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Customisable limitation for any currency and any market</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature11.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Side bet control</span>
                                                    </Col>
                                                    <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/feature12.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Multi-camera view</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="licensed-By position-relative py-5 alignPosCenter">
                                    <div>
                                        <Row className="justify-content-center align-items-center text-center pt-5">
                                            <Col lg={8} md={10} sm={11} xs={11}>
                                                <span className="headerTitle">Promotional Tools</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                <span className="brandsDesc">With multiple promotional tools provided by Yeebet Live, operators can run their businesses smoothly while attracting massive numbers of players at the same time. With these tools accessible through your back office, you can ensure that your audience is engaged and interested in bonuses, tournaments, and prizes.</span> <br />
                                            </Col>
                                        </Row>

                                        <div className="mx-auto pt-3" style={{ maxWidth: "1200px" }}>
                                            <Row className="justify-content-center align-items-center pt-5 text-center">
                                                <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                                    <div className="secondLayerPromo-container" />
                                                    <div className="whatWeDo-container py-5 px-4 px-lg-5">
                                                        <img src={require('../../assets/img/yeebetLive/promo1.png').default} className="img-fluid whatwedo-logo" /> <br />
                                                        <span className="whatwedo-title">Tournaments Management System</span>

                                                        <div className="pt-4">
                                                            <span className="whatwedo-desc">Host online live dealer tournaments by customizing every aspect of the tournament, setting up admission and tournament type, and much more.</span>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                                    <div className="secondLayerPromo-container" />
                                                    <div className="whatWeDo-container py-5 px-4 px-lg-5">
                                                        <img src={require('../../assets/img/yeebetLive/promo2.png').default} className="img-fluid whatwedo-logo" /> <br />
                                                        <span className="whatwedo-title">Bonus Management System</span>

                                                        <div className="pt-4">
                                                            <span className="whatwedo-desc">Yeebet Live allows you to create bonuses that are exclusive to your player audience, such as free spins, deposit bonuses, and manual bonuses.</span>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                                    <div className="secondLayerPromo-container" />
                                                    <div className="whatWeDo-container py-5 px-4 px-lg-5">
                                                        <img src={require('../../assets/img/yeebetLive/promo3.png').default} className="img-fluid whatwedo-logo" /> <br />
                                                        <span className="whatwedo-title">Wonder Wheel <br />Engine</span>

                                                        <div className="pt-4">
                                                            <span className="whatwedo-desc">Make sure your welcome promotion looks like a spinning wheel. Yeebet Wheel can be played daily, weekly, or monthly, giving players the chance to win cash, free spins, or physical prizes.</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="revenue_bg pt-3 alignPosCenter vh-100">
                                    <div className="position-relative py-5">
                                        <Row className="justify-content-center align-items-center text-center py-5">
                                            <Col lg={8} md={8} sm={11} xs={11}>
                                                <span className="headerTitle">Gross Gaming Revenue</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                <span className="brandsDesc">
                                                    *Available in your local currency or cryptocurrency*
                                                </span> <br />
                                            </Col>

                                            <Col lg={9} md={11} sm={12} xs={12}>
                                                <Row className="justify-content-center align-items-center my-5 px-3 text-center currency-container">
                                                    <Col lg={4} md={6} sm={12} xs={12} className="text-left py-5 pl-0 pr-0">
                                                        <div className="revenueContainer py-0 px-5">
                                                            <div className="py-5">
                                                                <span className="revenue-title">Yeebet API Solution</span>
                                                                <div className="pt-5">
                                                                    <span className="revenue-title">7%</span> <br />
                                                                    <span className="revenue-desc">Carry Forward</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col lg={4} md={6} sm={12} xs={12} className="text-center py-5 pl-0 pr-0">
                                                        <div className="revenueContainer py-0 px-5">
                                                            <div className="py-5">
                                                                <span className="revenue-title">Yeebet Feed Solution</span>
                                                                <div className="pt-5 mt-4">
                                                                    <a href="/contact-us">
                                                                        <div className="learnMoreBtn my-1 text-center">
                                                                            <span>Learn More</span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                    <Col lg={4} md={8} sm={12} xs={12} className="text-center py-5 pl-0 pr-0">
                                                        <div className="revenueContainer py-0 px-5">
                                                            <div className="py-5">
                                                                <span className="revenue-title">Yeebet Dedicated <br />Tables Solution</span>
                                                                <div className="pt-5">
                                                                    <a href="/contact-us">
                                                                        <div className="learnMoreBtn my-1 text-center">
                                                                            <span>Learn More</span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="revenue_bg pt-3 alignPosCenter vh-100">
                                    <div className="position-relative py-5">
                                        <Row className="justify-content-center align-items-center text-center pt-5">
                                            <Col lg={8} md={8} sm={11} xs={11}>
                                                <span className="headerTitle">Supported Wallet</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                            </Col>

                                            <Col lg={9} md={11} sm={12} xs={12}>
                                                <Row className="justify-content-center align-items-start my-5 text-center">
                                                    <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2 walletBoxDiv">
                                                            <img src={require('../../assets/img/yeebetLive/wallet1.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Seamless</span>
                                                    </Col>

                                                    <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2 walletBoxDiv">
                                                            <img src={require('../../assets/img/yeebetLive/wallet2.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Transfer</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="revenue_bg pt-3 alignPosCenter vh-100">
                                    <div className="position-relative py-5">
                                        <Row className="justify-content-center align-items-center text-center pt-5">
                                            <Col lg={8} md={8} sm={11} xs={11}>
                                                <span className="headerTitle">Supported Currency</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                            </Col>

                                            <Col lg={9} md={11} sm={12} xs={12}>
                                                <Row className="justify-content-center align-items-start my-5 text-center">
                                                    {
                                                        currency_Array.map((item, i) => (
                                                            <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                                <div className="casinoFeatured-container mb-2">
                                                                    <img src={item.img} className="img-fluid" />
                                                                </div>
                                                                <span className="casinoFeatured-text">{item.name}</span>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="revenue_bg pt-3 alignPosCenter vh-100">
                                    <div className="position-relative py-5">
                                        <Row className="justify-content-center align-items-center text-center pt-5">
                                            <Col lg={8} md={8} sm={11} xs={11}>
                                                <span className="headerTitle">Supported Language</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                            </Col>

                                            <Col lg={9} md={11} sm={12} xs={12}>
                                                <Row className="justify-content-center align-items-center my-5 text-center">
                                                    {
                                                        country_flagList.map((item, i) => (
                                                            <Col lg={2} md={3} sm={6} xs={6} className="mb-4">
                                                                <div className="casinoFeatured-container mb-2 py-4 px-3">
                                                                    <img src={item.img} className="img-fluid" />
                                                                </div>
                                                                <span className="casinoFeatured-text">{item.name}</span>
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="revenue_bg pt-3 alignPosCenter vh-100">
                                    <div className="position-relative py-5">
                                        <Row className="justify-content-center align-items-center text-center pt-5">
                                            <Col lg={8} md={8} sm={11} xs={11}>
                                                <span className="headerTitle">Supported OS</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                            </Col>

                                            <Col lg={9} md={11} sm={12} xs={12}>
                                                <Row className="justify-content-center align-items-center my-5 text-center">
                                                    <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/OS1.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">IOS</span>
                                                    </Col>

                                                    <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/OS2.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Android</span>
                                                    </Col>

                                                    <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/OS3.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">HTML5</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight">
                                <div className="market-Bg position-relative py-5 alignPosCenter">
                                    <Row className="justify-content-center align-items-center text-center pt-5">
                                        <Col lg={8} md={10} sm={11} xs={11}>
                                            <span className="headerTitle">Market Restriction</span> <br />
                                            <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                            {/* <span className="brandsDesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.</span> <br /> */}
                                        </Col>

                                        <Col lg={5} md={11} sm={12} xs={12}>
                                            <img src={require('../../assets/img/yeebetLive/market-map.png').default} className="img-fluid" /> <br />
                                            <span className="marketText"><span className="semicolon-color">"</span> We Support All Countries <span className="semicolon-color">"</span> </span>
                                        </Col>
                                    </Row>
                                </div>
                            </FullpageSection>
                            <FullpageSection className="fullPageHeight">
                                <div className="revenue_bg alignPosCenter vh-100">
                                    <div className="position-relative">
                                        <Row className="justify-content-center align-items-center text-center pt-5">
                                            <Col lg={8} md={8} sm={11} xs={11}>
                                                <span className="headerTitle">Settlement Currency</span> <br />
                                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.
                                </span> <br /> */}
                                            </Col>

                                            <Col lg={10} md={11} sm={12} xs={12}>
                                                <Row className="justify-content-center align-items-center my-5 text-center">
                                                    <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/settlement1.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">USDT</span>
                                                    </Col>


                                                    <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                        <div className="casinoFeatured-container mb-2">
                                                            <img src={require('../../assets/img/yeebetLive/settlement2.png').default} className="img-fluid" />
                                                        </div>
                                                        <span className="casinoFeatured-text">Fiat</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </FullpageSection>

                            <FullpageSection className="fullPageHeight alignPosCenter vh-100">
                                <div className="w-100">
                                    <div className="revenue_bg">
                                        <div className="position-relative">
                                            <Row className="justify-content-center align-items-center text-center py-5">
                                                <Col lg={8} md={8} sm={11} xs={11}>
                                                    <span className="headerTitle">Demo Account</span> <br />
                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                    <span className="brandsDesc">
                                                        Explore our games at below content :
                                                    </span> <br />
                                                </Col>

                                                <Col lg={10} md={11} sm={12} xs={12}>
                                                    <Row className="justify-content-center align-items-center my-5 text-center currency-container">
                                                        <Col lg={5} md={12} sm={12} xs={12} className="text-center py-5 pl-0 pr-0">
                                                            <div className="revenueContainer py-5 px-5">
                                                                <div className="py-5">
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
                                                                            <img src={require('../../assets/img/yeebetLive/demo1.png').default} className="img-fluid mb-2" />
                                                                        </Col>
                                                                        <Col lg={8} md={8} sm={12} xs={12} className="text-center text-md-left">
                                                                            <span className="revenue-title">Experience Now</span>
                                                                            <div className="pt-4">
                                                                                <a href="https://www.yeebet.vip/" target={"_blank"}>
                                                                                    <div className="demoBtn my-1 text-center">
                                                                                        <span>Demo</span>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col lg={5} md={12} sm={12} xs={12} className="text-center py-5 pl-0 pr-0">
                                                            <div className="revenueContainer px-5">
                                                                <div className="py-5">
                                                                    <Row className="justify-content-center align-items-center">
                                                                        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
                                                                            <img src={require('../../assets/img/yeebetLive/demo2.png').default} className="img-fluid mb-2" />
                                                                        </Col>
                                                                        <Col lg={8} md={8} sm={12} xs={12} className="text-center text-md-left">
                                                                            <span className="revenue-title">Download <br /> Yeebet Live App</span>
                                                                            <div className="pt-4">
                                                                                <a href="https://www.yeebet.club/ " target={"_blank"}>
                                                                                    <div className="demoBtn my-1 text-center">
                                                                                        <span>Download App</span>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </FullpageSection>
                        </FullPageSections>
                    </Fullpage>
                    <div className="w-100 position-sticky">
                        <DarkFooter />
                    </div>
                </div>

                <div className="d-block d-lg-none">
                    <div className="header-section position-relative">
                        <Row className="justify-content-center align-items-center text-center pt-5 pt-md-0">
                            <Col lg={10} md={11} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center text-center">
                                    <Col lg={4} md={6} sm={8} xs={8}>
                                        <img src={require('../../assets/img/yeebetLive/header-logo.png').default} className="img-fluid mb-4" />
                                    </Col>
                                    <Col lg={5} md={10} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">Introduction</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Yeebet Live recognized as “The Fastest Growing Live Games Provider In Asia ” Yeebet Live is well known for their magnificent Baccarat gaming platform developed through the experience and hard work of dozens of gaming experts leveraging the advantages of network technology. The designs and function of the platform have all been developed with the purpose to exceed client expectations.
                                            <br /><br />
                                            Yeebet Live adheres their goals by providing “Fairness, Safety, and Assurance” in the gaming environment, and is authorized by Curacao E-gaming — the Curacao government’s official regulating department. Furthermore, Yeebet Live operation platform is certified by the independent party, Gaming Laboratory International (GLI) & MGA as well.
                                        </span> <br />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <div className="liveCasino-bg position-relative py-5">
                        <Row className="justify-content-center align-items-center pt-5 text-center">
                            <Col lg={9} md={10} sm={11} xs={11}>
                                <span className="headerTitle">Live Casino</span> <br />
                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                <span className="brandsDesc">
                                    Yeebet Live provides an immersive and interactive gaming experience with its extensive selection of Live Casino games, such as Baccarat, BlackJack, Roulette, Dragon Tiger, Hi-Lo Game, Casino Hold’em, Poker Bet-on, Keno, Swift Roulette, Russian Poker and Super Six Baccarat. For players from different parts of the world there are 14 languages available. The interface offers animation and sound effects that enhance the gaming experience as well as chat with the dealer or other players at the table. Moreover we offer more than 30 side bets in all games plus various limits on each table game. Our multi-game view technology makes it possible to follow several games at once while our multi-camera technology ensures a realistic touch to every round of play.
                                </span> <br />
                            </Col>

                            <Col lg={11} md={11} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center pt-5 text-center">
                                    <Col lg={11} md={12} sm={12} xs={12} className="text-center mb-5">
                                        <div className="liveCasino-container py-3 mb-5 px-1 px-lg-5">
                                            <Row className="justify-content-center align-items-center text-center mb-5">
                                                <Col lg={5} md={7} sm={12} xs={12} className="text-center">
                                                    <img src={require('../../assets/img/yeebetLive/liveCasino1.png').default} className="img-fluid yeebetLiveAPIImg" />
                                                </Col>
                                                <Col lg={7} md={10} sm={12} xs={12} className="text-left">
                                                    <span className="headerTitle">Yeebet Live API Solution</span> <br />
                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                    <span className="brandsDesc">
                                                        At Yeebet Live, our live games api solution is of the highest quality and competitively priced - providing a gateway to achieve business growth. Our games are especially attractive to players who generally engage in higher wager values, as each and every one of them offers betting elements and online-facing features. Our Omni-channel capability means our games can be accessed via retail betting shops, land-based casinos, online or mobile platforms on any device. We also offer an innovative, highly customisable and user-friendly gaming interface with multiple table limits, rich game stats and animation/sound effects. Furthermore, players have the option to chat with dealers/fellow players at the table. To elevate the level of entertainment further, we provide multiple game bonuses & promotions which will help extend playing hours of existing customers while also enabling you to attract new players.
                                                    </span>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="liveCasino-container py-3 mb-5 px-1 px-lg-5">
                                            <Row className="justify-content-center align-items-center text-center">
                                                <Col lg={7} md={10} sm={12} xs={12} className="text-left mb-5 order-2 order-lg-1">
                                                    <span className="headerTitle">Yeebet Feed Solution</span> <br />
                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                    <span className="brandsDesc">
                                                        Yeebet live casino data & feed has found massive success due to its quality and quantity. Each game is certified by industry-leading authorities, making it a universal choice for players across all demographics. With the options to modify interfaces, operators have the freedom to make the product their own. The video streaming, results and monitored game flow can be changed with added accompaniments in any language desired. We are proud of this level of flexibility available through our offering.
                                                    </span>
                                                </Col>
                                                <Col lg={5} md={7} sm={12} xs={12} className="text-center mb-5 order-1 order-lg-2">
                                                    <img src={require('../../assets/img/yeebetLive/liveCasino2.png').default} className="img-fluid yeebetLiveAPIImg" />
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="liveCasino-container pt-3 pb-5 mb-5 px-1 px-lg-5">
                                            <Row className="justify-content-center align-items-center text-center">
                                                <Col lg={5} md={7} sm={12} xs={12} className="text-center">
                                                    <img src={require('../../assets/img/yeebetLive/liveCasino3.png').default} className="img-fluid yeebetLiveAPIImg" />
                                                </Col>
                                                <Col lg={7} md={10} sm={12} xs={12} className="text-left">
                                                    <span className="headerTitle">Yeebet Dedicated Table Solution</span> <br />
                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                    <span className="brandsDesc">
                                                        With dedicated solutions, we offer a variety of options, including tables with appropriate equipment and design, halls with different games, or enabling our partner's table to become independent providers, as Yeebet Live did. Furthermore, these variations can include a variety of solutions based on the client's needs, such as developing dedicated live casino games, constructing branded halls and tables, or setting up professional equipment.
                                                    </span>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="sideItem1Pos-about">
                            <img src={require('../../assets/img/aboutUs/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-about">
                            <img src={require('../../assets/img/aboutUs/sideItem2.png').default} className="img-fluid" />
                        </div>
                    </div>

                    <div className="casinoFeatured-bg position-relative py-5">
                        <div>
                            <Row className="justify-content-center align-items-center py-5 text-center">
                                <Col lg={8} md={10} sm={11} xs={11}>
                                    <span className="headerTitle">Yeebet Live Casino Featured</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" />
                                    {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.                            
                                </span> <br /> */}
                                </Col>
                            </Row>

                            <Row className="justify-content-center align-items-center py-2 text-center">
                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-start py-0 py-lg-2 text-center">
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature1.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">HD to full HD quality streaming</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature2.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Multiple providers via single channel</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature3.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">HTML5 game interface</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature5.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Real games in real time 24/7</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature6.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Multi-game view</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature7.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Live chat with dealers and between players</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature8.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Flexible customisation</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature9.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Additional and side bets in each game</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature10.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Customisable limitation for any currency and any market</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature11.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Side bet control</span>
                                        </Col>
                                        <Col lg={2} md={3} sm={4} xs={4} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/feature12.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Multi-camera view</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="licensed-By position-relative py-5">
                        <Row className="justify-content-center align-items-center text-center pt-5">
                            <Col lg={8} md={10} sm={11} xs={11}>
                                <span className="headerTitle">Promotional Tools</span> <br />
                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                <span className="brandsDesc">With multiple promotional tools provided by Yeebet Live, operators can run their businesses smoothly while attracting massive numbers of players at the same time. With these tools accessible through your back office, you can ensure that your audience is engaged and interested in bonuses, tournaments, and prizes.</span> <br />
                            </Col>

                            <Col lg={10} md={11} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center pt-5 text-center">
                                    <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                        <div className="secondLayerPromo-container" />
                                        <div className="whatWeDo-container py-5 px-4 px-lg-5">
                                            <img src={require('../../assets/img/yeebetLive/promo1.png').default} className="img-fluid whatwedo-logo" /> <br />
                                            <span className="whatwedo-title">Tournaments Management System</span>

                                            <div className="pt-4">
                                                <span className="whatwedo-desc">Host online live dealer tournaments by customizing every aspect of the tournament, setting up admission and tournament type, and much more.</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                        <div className="secondLayerPromo-container" />
                                        <div className="whatWeDo-container py-5 px-4 px-lg-5">
                                            <img src={require('../../assets/img/yeebetLive/promo2.png').default} className="img-fluid whatwedo-logo" /> <br />
                                            <span className="whatwedo-title">Bonus Management System</span>

                                            <div className="pt-4">
                                                <span className="whatwedo-desc">Yeebet Live allows you to create bonuses that are exclusive to your player audience, such as free spins, deposit bonuses, and manual bonuses.</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                        <div className="secondLayerPromo-container" />
                                        <div className="whatWeDo-container py-5 px-4 px-lg-5">
                                            <img src={require('../../assets/img/yeebetLive/promo3.png').default} className="img-fluid whatwedo-logo" /> <br />
                                            <span className="whatwedo-title">Wonder Wheel <br />Engine</span>

                                            <div className="pt-4">
                                                <span className="whatwedo-desc">Make sure your welcome promotion looks like a spinning wheel. Yeebet Wheel can be played daily, weekly, or monthly, giving players the chance to win cash, free spins, or physical prizes.</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>


                        {/* <div className="sideItem3Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem3.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem4Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem4.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem5Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem5.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem6Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem6.png').default} className="img-fluid" />
                    </div> */}
                    </div>

                    <div className="revenue_bg pt-3">
                        <div className="position-relative py-5">
                            <Row className="justify-content-center align-items-center text-center py-5">
                                <Col lg={8} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Gross Gaming Revenue</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    <span className="brandsDesc">
                                        *Available in your local currency or cryptocurrency*
                                    </span> <br />
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-center my-5 px-5 py-5 text-center currency-container">
                                        <Col lg={4} md={6} sm={12} xs={12} className="text-left pl-0 pr-0 mb-4">
                                            <div className="revenueContainer py-0 px-5">
                                                <div className="py-5">
                                                    <span className="revenue-title">Yeebet API Solution</span>
                                                    <div className="pt-5">
                                                        <span className="revenue-title">7%</span> <br />
                                                        <span className="revenue-desc">Carry Forward</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} md={6} sm={12} xs={12} className="text-center pl-0 pr-0 mb-4">
                                            <div className="revenueContainer py-0 px-5">
                                                <div className="py-5">
                                                    <span className="revenue-title">Yeebet Feed Solution</span>
                                                    <div className="pt-5">
                                                        <a href="/contact-us">
                                                            <div className="learnMoreBtn my-1 text-center">
                                                                <span>Learn More</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={4} md={8} sm={12} xs={12} className="text-center pl-0 pr-0 mb-4">
                                            <div className="revenueContainer py-0 px-5">
                                                <div className="py-5">
                                                    <span className="revenue-title">Yeebet Dedicated <br />Tables Solution</span>
                                                    <div className="pt-5">
                                                        <a href="/contact-us">
                                                            <div className="learnMoreBtn my-1 text-center">
                                                                <span>Learn More</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-center align-items-center text-center pt-5">
                                <Col lg={8} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Supported Wallet</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.
                                </span> <br /> */}
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-start my-5 text-center">
                                        <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                            <div className="casinoFeatured-container mb-2 walletBoxDiv">
                                                <img src={require('../../assets/img/yeebetLive/wallet1.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Seamless</span>
                                        </Col>

                                        <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                            <div className="casinoFeatured-container mb-2 walletBoxDiv">
                                                <img src={require('../../assets/img/yeebetLive/wallet2.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Transfer</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-center align-items-center text-center pt-5">
                                <Col lg={8} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Supported Currency</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.
                                </span> <br /> */}
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-start my-5 text-center">
                                        {
                                            currency_Array.map((item, i) => (
                                                <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                    <div className="casinoFeatured-container mb-2">
                                                        <img src={item.img} className="img-fluid" />
                                                    </div>
                                                    <span className="casinoFeatured-text">{item.name}</span>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-center align-items-center text-center pt-5">
                                <Col lg={8} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Supported Language</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.
                                </span> <br /> */}
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-center my-5 text-center">
                                        {
                                            country_flagList.map((item, i) => (
                                                <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                                    <div className="casinoFeatured-container mb-2 py-4 px-3">
                                                        <img src={item.img} className="img-fluid" />
                                                    </div>
                                                    <span className="casinoFeatured-text">{item.name}</span>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-center align-items-center text-center pt-5">
                                <Col lg={8} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Supported OS</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.
                                </span> <br /> */}
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-center my-5 text-center">
                                        <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/OS1.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">IOS</span>
                                        </Col>

                                        <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/OS2.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Android</span>
                                        </Col>

                                        <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/OS3.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">HTML5</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="market-Bg position-relative py-5">
                        <Row className="justify-content-center align-items-center text-center pt-5">
                            <Col lg={8} md={10} sm={11} xs={11}>
                                <span className="headerTitle">Market Restriction</span> <br />
                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                {/* <span className="brandsDesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.</span> <br /> */}
                            </Col>

                            <Col lg={5} md={11} sm={12} xs={12}>
                                <img src={require('../../assets/img/yeebetLive/market-map.png').default} className="img-fluid" /> <br />
                                <span className="marketText"><span className="semicolon-color">"</span> We Support All Countries <span className="semicolon-color">"</span> </span>
                            </Col>
                        </Row>
                    </div>

                    <div className="revenue_bg">
                        <div className="position-relative">
                            <Row className="justify-content-center align-items-center text-center pt-5">
                                <Col lg={8} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Settlement Currency</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    {/* <span className="brandsDesc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet.
                                </span> <br /> */}
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-center my-5 text-center">
                                        <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/settlement1.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">USDT</span>
                                        </Col>


                                        <Col lg={2} md={3} sm={6} xs={6} className="mb-5">
                                            <div className="casinoFeatured-container mb-2">
                                                <img src={require('../../assets/img/yeebetLive/settlement2.png').default} className="img-fluid" />
                                            </div>
                                            <span className="casinoFeatured-text">Fiat</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="justify-content-center align-items-center text-center py-5">
                                <Col lg={8} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Demo Account</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    <span className="brandsDesc">
                                        Explore our games at below content :
                                    </span> <br />
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-center my-5 text-center currency-container">
                                        <Col lg={5} md={12} sm={12} xs={12} className="text-center py-5 pl-0 pr-0">
                                            <div className="revenueContainer py-5 px-5">
                                                <div className="py-5">
                                                    <Row className="justify-content-center align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
                                                            <img src={require('../../assets/img/yeebetLive/demo1.png').default} className="img-fluid mb-2" />
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="text-center text-md-left">
                                                            <span className="revenue-title">Experience Now</span>
                                                            <div className="pt-4">
                                                                <a href="https://www.yeebet.vip/" target={"_blank"}>
                                                                    <div className="demoBtn my-1 text-center">
                                                                        <span>Demo</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={5} md={12} sm={12} xs={12} className="text-center py-5 pl-0 pr-0">
                                            <div className="revenueContainer px-5">
                                                <div className="py-5">
                                                    <Row className="justify-content-center align-items-center">
                                                        <Col lg={4} md={4} sm={12} xs={12} className="text-center">
                                                            <img src={require('../../assets/img/yeebetLive/demo2.png').default} className="img-fluid mb-2" />
                                                        </Col>
                                                        <Col lg={8} md={8} sm={12} xs={12} className="text-center text-md-left">
                                                            <span className="revenue-title">Download <br /> Yeebet Live App</span>
                                                            <div className="pt-4">
                                                                <a href="https://www.yeebet.club/ " target={"_blank"}>
                                                                    <div className="demoBtn my-1 text-center">
                                                                        <span>Download App</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <DarkFooter />
                </div>
            </div>
        </>
    );
}

export default YeebetLive;
