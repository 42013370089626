import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import MainNavbar from "components/Navbars/MainNavbar";
import "../../assets/css/contactUs.scss";
import { Col, Row, Input } from "reactstrap";
import { FaArrowDown, FaCaretDown } from "react-icons/fa";
import ReactWhatsapp from 'react-whatsapp';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function ContactUs() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    const [values, setValues] = React.useState({
        firstName: "",
        lastName: "",
        companyWebsite: "",
        phoneNumber: "",
        message: ""
    });

    const handleChange = (prop) => (event) => {
        if (prop === "phoneNumber") {
            setValues({ values, [prop]: event });
        }
        else {
            setValues({ ...values, [prop]: event.target.value });
        }
    };


    return (
        <>
            <MainNavbar />

            <div className="" id="contactUs">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">Contact Us</span>
                </div> */}

                <div className="contactUs-liveBg position-relative">
                    <div className="header-section position-relative py-5">
                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={10} md={10} sm={12} xs={12} className="px-0">
                                <Row className="justify-content-center align-items-center text-center" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={6} md={12} sm={12} xs={12} className="mb-5">
                                        <div className="enquiryFormDiv w-100 text-left">
                                            <Row className="justify-content-center align-items-center py-5 text-center pt-4">
                                                <Col lg={11} md={12} sm={12} xs={12} className="text-left mb-4">
                                                    <Row className="justify-content-center align-items-center text-center pt-4">
                                                        <Col lg={12} md={12} sm={12} xs={12} className="text-left mb-4">
                                                            <span className="enquiryFormTitle">Enquiry Form</span>
                                                        </Col>
                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <div className="mb-4">
                                                                <Input
                                                                    placeholder="First Name"
                                                                    type="text"
                                                                    name="firstName"
                                                                    value={values.firstName}
                                                                    onChange={handleChange("firstName")}
                                                                    className="contactUs-textBox"
                                                                />
                                                                {/* <span className="error">{error.name}</span> */}
                                                            </div>
                                                        </Col>

                                                        <Col lg={6} md={6} sm={12} xs={12}>
                                                            <div className="mb-4">
                                                                <Input
                                                                    placeholder="Last Name"
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={values.lastName}
                                                                    onChange={handleChange("lastName")}
                                                                    className="contactUs-textBox"
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                            {/* <div className="mb-4">
                                                                <Input
                                                                    placeholder="Company Website"
                                                                    type="text"
                                                                    name="companyWebsite"
                                                                    value={values.companyWebsite}
                                                                    onChange={handleChange("companyWebsite")}
                                                                    className="contactUs-textBox"
                                                                />
                                                            </div> */}

                                                            <div className="mb-4 text-start">
                                                                <div className="dropdownCountryCode d-flex align-items-center">
                                                                    <div className="width-20 text-center" >
                                                                        <span className='cursor-pointer'>https://</span>
                                                                    </div>
                                                                    <div className="w-100">
                                                                        <Input
                                                                            placeholder="Company Website"
                                                                            type="text"
                                                                            name="companyWebsite"
                                                                            value={values.companyWebsite}
                                                                            onChange={handleChange("companyWebsite")}
                                                                            className="contactUs-textBox"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <span className="error">{error}</span> */}
                                                            </div>
                                                        </Col>

                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                            <div className="mb-4 text-start">
                                                                <PhoneInput
                                                                    className="contactUs-textBox"
                                                                    type="text"
                                                                    country={'my'}
                                                                    name="phoneNumber"
                                                                    value={values.phoneNumber}
                                                                    onChange={handleChange("phoneNumber")}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                            <div className="mb-4">
                                                                <Input
                                                                    placeholder="Your Message"
                                                                    type="textarea"
                                                                    rows="100"
                                                                    name="message"
                                                                    className="contactUs-textArea"
                                                                    value={values.message}
                                                                    onChange={handleChange("message")}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                            <ReactWhatsapp number="+44 7508 874951" message={
                                                                "First Name: " + values.firstName +
                                                                "\nLast Name: " + values.lastName +
                                                                "\nCompany Website: " + "https://" + values.companyWebsite +
                                                                "\nPhone Number: " + values.phoneNumber +
                                                                "\nMessage: " + values.message
                                                            } className="w-100 submitFormBtn mb-4 py-2">
                                                                <span className="">Submit</span>
                                                            </ReactWhatsapp>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                    <Col lg={6} md={9} sm={11} xs={11} className="text-center text-lg-left px-0 px-lg-5">
                                        <span className="headerTitle">Contact Sales</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Please meet our Business Development team. <br />
                                            Feel free to ask questions about your needs, your business or anything regarding gaming.
                                        </span> <br />

                                        <div className="pt-5 text-left">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <img src={require('../../assets/img/contactUs/office_name.png').default} className="img-fluid contactUs-iconSize" />
                                                <div className="ml-3">
                                                    <span className="brandsDesc">Yeebet Gaming Holding Ltd.</span>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-start align-items-start pt-4">
                                                <img src={require('../../assets/img/contactUs/address.png').default} className="img-fluid contactUs-iconSize" />
                                                <div className="ml-3">
                                                    <span className="brandsDesc">
                                                        271 Sea Breeze Ave <br />
                                                        Brooklyn, NY 11224 <br />
                                                        USA</span>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-start align-items-center pt-4">
                                                <img src={require('../../assets/img/contactUs/telegram.png').default} className="img-fluid contactUs-iconSize" />
                                                <div className="ml-3">
                                                    <a href="http://t.me/sukizu_metasoft" target={"_blank"} >
                                                        <span className="brandsDesc">Sukizu Metasoft</span>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-start align-items-center pt-4">
                                                <img src={require('../../assets/img/contactUs/email.png').default} className="img-fluid contactUs-iconSize" />
                                                <div className="ml-3">
                                                    <a href="mailto:info@yeebetgaming.com">
                                                        <span className="brandsDesc">info@yeebetgaming.com</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="sideItem1Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem2.png').default} className="img-fluid" />
                        </div>
                    </div>

                    <Row className="justify-content-center align-item-center text-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="p-0">
                            <div className="iframe" id="iframe-map">
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.299242631766!2d101.79160901460902!3d3.0140079978050105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcb5e3f845c8f%3A0xaf86054ee61e844b!2sPerunding%20IRZI%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1665020982142!5m2!1sen!2smy" title="irzi" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" width={"100%"} height={"100%"} loading="lazy" referrerPolicy="no-referrer-when-downgrade" aria-label="11-2 , Jalan Impian Perdana 1, Saujana Impian, 43000 Kajang, Selangor."></iframe> */}
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12121.754735925566!2d-73.98025313022463!3d40.5760713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2444809f1c737%3A0xda06fe5710766f2c!2s271%20Sea%20Breeze%20Ave%2C%20Brooklyn%2C%20NY%2011224%2C%20USA!5e0!3m2!1sen!2smy!4v1675219545583!5m2!1sen!2smy" title="irzi" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" width={"100%"} height={"100%"} loading="lazy" referrerPolicy="no-referrer-when-downgrade" aria-label="271 Sea Breeze Ave Brooklyn, NY 11224 USA"></iframe>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* <div className="contactUs-newsletterBg pt-5">
                    <div className="subscribe-content py-5 py-md-0">
                        <Row className="justify-content-center align-items-center text-center py-5 py-md-0">
                            <Col lg={10} md={10} sm={12} xs={12} className="pt-0 pt-lg-5">
                                <Row className="justify-content-center align-items-start pt-0 pt-lg-5 text-center">
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                        <Row className="justify-content-center align-items-start pt-0 pt-lg-5 text-center">
                                            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                                <div className="newsletterBg py-5">
                                                    <span className="newsletterTitle">
                                                        Subscribe To Our Newsletter
                                                    </span> <br />
                                                    <Row className="justify-content-center align-items-start pt-5 text-center">
                                                        <Col lg={9} md={10} sm={12} xs={12} className="text-center">
                                                            <div className="d-block d-lg-flex position-relative">
                                                                <input
                                                                    type="text"
                                                                    className="form-control subscribe-textBox py-3"
                                                                    name="otpTag"
                                                                    placeholder="Your Email"
                                                                />
                                                                <div className='mx-2 subscribeBtnPos'>
                                                                    <button className="newsletterSubscribeBtn px-3 py-2 d-flex justify-content-center align-items-center cursor-pointer">
                                                                        <span className="requestCodeBtnText">Subscribe</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div> */}

            </div>
            <DarkFooter />
        </>
    );
}

export default ContactUs;
