import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import MainNavbar from "components/Navbars/MainNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import "../assets/css/home.scss";
import { Col, Row } from "reactstrap";
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

import brands1 from "../assets/img/home/brands1.png";
import brands2 from "../assets/img/home/brands2.png";
import brands3 from "../assets/img/home/brands3.png";
import brands4 from "../assets/img/home/brands4.png";
import brands5 from "../assets/img/home/brands5.png";
import brands6 from "../assets/img/home/brands6.png";
import brands7 from "../assets/img/home/brands7.png";
import brands8 from "../assets/img/home/brands8.png";
import brands9 from "../assets/img/home/brands9.png";
import brands10 from "../assets/img/home/brands10.png";
import brands11 from "../assets/img/home/brands11.png";
import brands12 from "../assets/img/home/brands12.png";
import brands13 from "../assets/img/home/brands13.png";

import liveCasino1 from "../assets/img/home/liveCasino1.png";
import liveCasino2 from "../assets/img/home/liveCasino2.png";
import liveCasino3 from "../assets/img/home/liveCasino3.png";

import slot1 from "../assets/img/home/slot1.png";
import slot2 from "../assets/img/home/slot2.png";
import slot3 from "../assets/img/home/slot3.png";

import sports1 from "../assets/img/home/sports1.png";
import sports2 from "../assets/img/home/sports2.png";
import sports3 from "../assets/img/home/sports3.png";

import ex_logo1 from "../assets/img/partner/exclusive/logo1.png";
import ex_logo2 from "../assets/img/partner/exclusive/logo2.png";

// import b2b_logo1 from "../assets/img/partner/b2b/logo1.png";
import b2b_logo2 from "../assets/img/partner/b2b/logo2.png";
import b2b_logo3 from "../assets/img/partner/b2b/logo3.png";
import b2b_logo4 from "../assets/img/partner/b2b/logo4.png";
import b2b_logo5 from "../assets/img/partner/b2b/logo5.png";
import b2b_logo6 from "../assets/img/partner/b2b/logo6.png";
import b2b_logo7 from "../assets/img/partner/b2b/logo7.png";
import b2b_logo8 from "../assets/img/partner/b2b/logo8.png";
import b2b_logo9 from "../assets/img/partner/b2b/logo9.png";
import b2b_logo10 from "../assets/img/partner/b2b/logo10.png";
import b2b_logo11 from "../assets/img/partner/b2b/logo11.png";
import b2b_logo12 from "../assets/img/partner/b2b/logo12.png";
import b2b_logo13 from "../assets/img/partner/b2b/logo13.png";
import b2b_logo14 from "../assets/img/partner/b2b/logo14.png";
import b2b_logo15 from "../assets/img/partner/b2b/logo15.png";

import b2c_logo1 from "../assets/img/partner/b2c/logo1.png";
import b2c_logo2 from "../assets/img/partner/b2c/logo2.png";
import b2c_logo3 from "../assets/img/partner/b2c/logo3.png";
import b2c_logo4 from "../assets/img/partner/b2c/logo4.png";
import b2c_logo5 from "../assets/img/partner/b2c/logo5.png";
import b2c_logo6 from "../assets/img/partner/b2c/logo6.png";
import b2c_logo7 from "../assets/img/partner/b2c/logo7.png";
import b2c_logo8 from "../assets/img/partner/b2c/logo8.png";
import b2c_logo9 from "../assets/img/partner/b2c/logo9.png";
import b2c_logo10 from "../assets/img/partner/b2c/logo10.png";
import b2c_logo11 from "../assets/img/partner/b2c/logo11.png";
import b2c_logo12 from "../assets/img/partner/b2c/logo12.png";
import b2c_logo13 from "../assets/img/partner/b2c/logo13.png";
import b2c_logo14 from "../assets/img/partner/b2c/logo14.png";
import b2c_logo15 from "../assets/img/partner/b2c/logo15.png";
import b2c_logo16 from "../assets/img/partner/b2c/logo16.png";
import b2c_logo17 from "../assets/img/partner/b2c/logo17.png";
import b2c_logo18 from "../assets/img/partner/b2c/logo18.png";
import b2c_logo19 from "../assets/img/partner/b2c/logo19.png";
import b2c_logo20 from "../assets/img/partner/b2c/logo20.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Marquee from "react-fast-marquee";
import { FaAngleDoubleRight } from "react-icons/fa";

const brandsLogo = [
  { key: 1, img: brands1 },
  { key: 2, img: brands11 },
  { key: 3, img: brands12 },
  { key: 4, img: brands13 },
  { key: 3, img: brands3 },
  // { key: 6, img: brands5 },
  { key: 7, img: brands9 },

]

const gameCat_LiveCasino = [
  { img: liveCasino1, title: "Classic Baccarat", desc: "Slow check in 30 seconds with lower return." },
  { img: liveCasino2, title: "Auto Roulette", desc: "Spinning non stop like your money cash in." },
  { img: liveCasino3, title: "Blackjack", desc: "Calculate it & Win" },
]

const gameCat_Sports = [
  { img: sports1, title: "", desc: "" },
  { img: sports2, title: "", desc: "" },
  { img: sports3, title: "", desc: "" },
]


const gameCat_Slot = [
  { img: slot1, title: "", desc: "" },
  { img: slot2, title: "", desc: "" },
  { img: slot3, title: "", desc: "" },
]

const ex_logoList = [
  { img: ex_logo1 },
  { img: ex_logo2 },
]

const b2b_logoList = [
  // { img: b2b_logo1 },
  { img: b2b_logo2 },
  { img: b2b_logo3 },
  { img: b2b_logo4 },
  { img: b2b_logo5 },
  { img: b2b_logo6 },
  { img: b2b_logo7 },
  { img: b2b_logo8 },
]

const b2b_logoList2 = [
  { img: b2b_logo9 },
  { img: b2b_logo10 },
  { img: b2b_logo11 },
  { img: b2b_logo12 },
  { img: b2b_logo13 },
  { img: b2b_logo14 },
  { img: b2b_logo15 },
]

const b2c_logoList = [
  { img: b2c_logo1 },
  { img: b2c_logo2 },
  { img: b2c_logo3 },
  { img: b2c_logo4 },
  { img: b2c_logo5 },
  { img: b2c_logo6 },
  { img: b2c_logo7 },
  { img: b2c_logo8 },
  { img: b2c_logo9 },
  { img: b2c_logo10 },
]

const b2c_logoList2 = [
  { img: b2c_logo11 },
  { img: b2c_logo12 },
  { img: b2c_logo13 },
  { img: b2c_logo14 },
  { img: b2c_logo15 },
  { img: b2c_logo16 },
  { img: b2c_logo17 },
  { img: b2c_logo18 },
  { img: b2c_logo19 },
  { img: b2c_logo20 },
]

function Index() {
  const [selectedGameCategory, setGameCategory] = React.useState(0);
  const [selectedPartnerCategory, setPartnerCategory] = React.useState(1);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <MainNavbar />

      <div className="" id="home">
        <div className="d-none d-lg-block">
          <Fullpage>
            <FullPageSections>
              <FullpageSection className="fullPageHeight">
                <div className="header-section position-relative">
                  <div className="video-container" style={{ lineHeight: "0" }}>
                    <video autoPlay={true} playsInline muted loop id="myVideo" style={{ width: "100%", height: '100vh' }}>
                      <source src={require('../assets/img/home/promo-video.mp4').default} type="video/mp4" />
                    </video>
                  </div>
                  <div className="videoOverlay" />

                  <div className="position-absolute mx-auto text-center" style={{ left: "0", right: "0", top: "40%" }}>
                    <span className="overlaySectionTitle">Yeebet Gaming Group</span>

                    <a href="https://www.yeebet.vip/" target={"_blank"}>
                      <button className="headerBtn mx-auto mt-5">
                        TRY NOW
                        <div className="headerBtn_horizontal"></div>
                        <div className="headerBtn_vertical"></div>
                      </button>
                    </a>

                    <a href="/contact-us" target={"_blank"}>
                      <div className="text-center pt-4">
                        <span className="contactUsText">CONTACT US </span>
                        <span className="contactUsText"><FaAngleDoubleRight /></span>
                      </div>
                    </a>
                  </div>

                  <div className="position-absolute mx-auto" style={{ left: "0", right: "0", bottom: "8%" }}>
                    <div className="text-center">
                      <img src={require('../assets/img/home/scrollDown.png').default} className="img-fluid scrollDownGif" />
                    </div>
                  </div>
                </div>
              </FullpageSection>

              <FullpageSection className="fullPageHeight brandsBg d-flex align-items-center">
                <div className="our-brands position-relative pt-5">
                  <Row className="justify-content-center align-items-center pt-5 text-center">
                    <Col lg={5} md={10} sm={11} xs={11}>
                      <span className="headerTitle">Our Brands</span> <br />
                      <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                      <span className="brandsDesc">One stop solution for games api provider, online casino white label, affiliate marketing, Asia payments solution, and so much more.</span> <br />
                    </Col>

                    <Col lg={10} md={11} sm={12} xs={12}>
                      <Row className="justify-content-center align-items-center pt-5 text-center">
                        {
                          brandsLogo.map((item, i) => (
                            <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-center mb-5">
                              <Zoom duration={1500}>
                                <img src={item.img} className="brandsLogo" style={{ position: "sticky", zIndex: "10" }} />
                              </Zoom>
                            </Col>
                          ))
                        }
                      </Row>
                    </Col>
                  </Row>

                  <div className="sideItem1Pos">
                    <img src={require('../assets/img/home/sideItem1.png').default} className="img-fluid" />
                  </div>

                  <div className="sideItem2Pos">
                    <img src={require('../assets/img/home/sideItem2.png').default} className="img-fluid" />
                  </div>
                </div>
              </FullpageSection>

              <FullpageSection className="fullPageHeight brandsBg2 d-flex align-items-center">
                <div className="gamingGroupPos">
                  <Row className="justify-content-center align-items-center text-center">
                    <Col xl={9} lg={9} md={11} sm={12} xs={12}>
                      <Fade top duration={2000}>
                        <div className="gamingGroupDiv d-flex justify-content-center align-items-center py-4">
                          <div className="py-5 px-0 px-md-4 px-lg-0" style={{}}>
                            <Row className="justify-content-center align-items-center text-left">
                              <Col lg={5} md={5} sm={12} xs={12}>
                                <img src={require('../assets/img/home/gamingGroupImg.png').default} className="img-fluid" />
                              </Col>
                              <Col lg={7} md={7} sm={12} xs={12}>
                                <span className="gameGroupTitle">Yeebet Gaming Group</span> <br />
                                <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid mobileLine" /> <br /><br />
                                <Row className="justify-content-start align-items-center text-left">
                                  <Col lg={11} md={11} sm={11} xs={11} className="px-0">
                                    <p className="gamingGroupDesc">
                                      Yeebet Gaming Group is a global award-winning technology and services provider for online and land-based gaming industry. Yeebet Gaming Group’s innovative and proven offerings include Yeebet Live Dealer Games, UU Slots, Yeebet Sportsbooks Solution and more – all ready to be launched and managed through Metasoft One Api Solution, its pioneering business management environment.
                                      <br /><br />
                                      With Yeebet Gaming Group's DKG Platform, partners benefit from powerful back office tools and all-inclusive services. From standalone setup to turnkey and white label solutions, Yeebet Group offers its partners unmatched success.
                                      <br /><br />
                                      iGaming2u Yellow Page Solutions also deliver extra value by providing useful information of white label solution, payment solution, games provider solution, B2C affiliate options to our stakeholders.
                                    </p> <br />
                                  </Col>
                                </Row>
                                <a href="/about-us">
                                  <div className="gamingGroupBtn my-1 text-center">
                                    <span className="">Learn More</span>
                                  </div>
                                </a>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Fade>
                    </Col>
                  </Row>
                </div>
              </FullpageSection>

              {/* <div className="brandsBg">
              <div className="our-brands position-relative pt-5">
                <Row className="justify-content-center align-items-center pt-5 text-center">
                  <Col lg={5} md={10} sm={11} xs={11}>
                    <span className="headerTitle">Our Brands</span> <br />
                    <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                    <span className="brandsDesc">One stop solution for games api provider, online casino white label, affiliate marketing, Asia payments solution, and so much more.</span> <br />
                  </Col>

                  <Col lg={10} md={11} sm={12} xs={12}>
                    <Row className="justify-content-center align-items-center pt-5 text-center">
                      {
                        brandsLogo.map((item, i) => (
                          <Col lg={3} md={4} sm={4} xs={4} key={i} className="text-center mb-5">
                            <img src={item.img} className="brandsLogo" style={{ position: "sticky", zIndex: "10" }} />
                          </Col>
                        ))
                      }
                    </Row>
                  </Col>
                </Row>

                <div className="sideItem1Pos">
                  <img src={require('../assets/img/home/sideItem1.png').default} className="img-fluid" />
                </div>

                <div className="sideItem2Pos">
                  <img src={require('../assets/img/home/sideItem2.png').default} className="img-fluid" />
                </div>
              </div>

              <div className="gamingGroupPos">
                <Row className="justify-content-center align-items-center text-center">
                  <Col xl={9} lg={9} md={11} sm={12} xs={12}>
                    <div className="gamingGroupDiv d-flex justify-content-center align-items-center py-5">
                      <div className="py-5 px-0 px-md-4 px-lg-0" style={{}}>
                        <span className="gameGroupTitle">Yeebet Gaming Group</span> <br />
                        <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid mobileLine" /> <br /><br />
                        <Row className="justify-content-center align-items-center text-center">
                          <Col lg={8} md={12} sm={12} xs={12}>
                            <p className="gamingGroupDesc">
                              Yeebet Gaming Group is a global award-winning technology and services provider for online and land-based gaming industry. Yeebet Gaming Group’s innovative and proven offerings include Yeebet Live Dealer Games, UU Slots, MGC Slots, Yeebet Sportsbooks Solution and more – all ready to be launched and managed through Metasoft One Api Solution, its pioneering business management environment.
                              <br /><br />
                              With Yeebet Gaming Group's DKG Platform, partners benefit from powerful back office tools and all-inclusive services. From standalone setup to turnkey and white label solutions, Yeebet Group offers its partners unmatched success.
                              <br /><br />
                              iGaming2u Yellow Page Solutions also deliver extra value by providing useful information of white label solution, payment solution, games provider solution, B2C affiliate options to our stakeholders.
                            </p> <br />
                          </Col>
                        </Row>
                        <a href="/about-us">
                          <div className="learnMoreBtn my-1">
                            <span className="">Learn More</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

            </div> */}

              <FullpageSection className="fullPageHeight d-flex align-items-center">
                <div className="py-5 position-relative">
                  <Row className="justify-content-center align-items-center text-center gameCategoryContent">
                    <Col lg={10} md={10} sm={11} xs={11}>
                      <span className="headerTitle">Our Game Category</span> <br />
                      <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                      <span className="brandsDesc">The more tables and better games content for audience, the higher the traffic we could lead for you.</span> <br />
                    </Col>

                    <Col lg={11} md={11} sm={12} xs={12}>
                      <Row className="justify-content-center align-items-center pt-4 text-center mb-3 mb-lg-0">
                        <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                          <div className={selectedGameCategory === 0 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(0)}>
                            <span>Live Casino</span>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                          <div className={selectedGameCategory === 1 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(1)}>
                            <span>Slots</span>
                          </div>
                        </Col>
                        <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                          <div className={selectedGameCategory === 2 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(2)}>
                            <span>Sports</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg={8} md={11} sm={11} xs={11}>
                      <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                        <div className="d-none d-lg-block">
                          <Row className="justify-content-center align-items-center pt-4">
                            {
                              gameCat_LiveCasino.map((item, i) => (
                                <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-left mb-5">
                                  <Bounce duration={1000 + (1000 * i)}>
                                    <div className="position-relative">
                                      <img src={item.img} className="w-100" />
                                      <div className="position-absolute gameCategoryDetailsPos">
                                        <span className="gameCategoryTitle">{item.title}</span> <br />
                                        <span className="gameCategoryDesc">{item.desc}</span>
                                      </div>
                                    </div>
                                  </Bounce>
                                </Col>
                              ))
                            }
                          </Row>
                        </div>


                        <Row className="justify-content-center align-items-center pt-4">
                          <Col lg={9} md={11} sm={12} xs={12} className="pl-0 pr-0">
                            <Carousel className="mb-5 d-block d-lg-none" preventMovementUntilSwipeScrollTolerance={true} infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                              {
                                gameCat_LiveCasino.map((item, i) => (
                                  <div className="text-left mb-5 p-4 p-md-5">
                                    <Bounce duration={1000 + (1000 * i)}>
                                      <div className="position-relative">
                                        <img src={item.img} className="w-100" />
                                        <div className="position-absolute gameCategoryDetailsPos">
                                          <span className="gameCategoryTitle">{item.title}</span> <br />
                                          <span className="gameCategoryDesc">{item.desc}</span>
                                        </div>
                                      </div>
                                    </Bounce>
                                  </div>
                                ))
                              }
                            </Carousel>
                          </Col>
                        </Row>
                      </div>

                      <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                        <div className="d-none d-lg-block">
                          <Row className="justify-content-center align-items-center pt-5">
                            {
                              gameCat_Slot.map((item, i) => (
                                <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-left mb-5">
                                  <Bounce duration={1000 + (1000 * i)}>
                                    <div className="position-relative">
                                      <img src={item.img} className="w-100" />
                                      <div className="position-absolute gameCategoryDetailsPos">
                                        <span className="gameCategoryTitle">{item.title}</span> <br />
                                        <span className="gameCategoryDesc">{item.desc}</span>
                                      </div>
                                    </div>
                                  </Bounce>
                                </Col>
                              ))
                            }
                          </Row>
                        </div>

                        <Carousel className="mb-5 d-block d-lg-none" preventMovementUntilSwipeScrollTolerance={true} infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                          {
                            gameCat_Slot.map((item, i) => (
                              <div className="text-left mb-5 p-4 p-md-5">
                                <Bounce duration={1000 + (1000 * i)}>
                                  <div className="position-relative">
                                    <img src={item.img} className="w-100" />
                                    <div className="position-absolute gameCategoryDetailsPos">
                                      <span className="gameCategoryTitle">{item.title}</span> <br />
                                      <span className="gameCategoryDesc">{item.desc}</span>
                                    </div>
                                  </div>
                                </Bounce>
                              </div>
                            ))
                          }
                        </Carousel>
                      </div>

                      <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                        <div className="d-none d-lg-block">
                          <Row className="justify-content-center align-items-center pt-5">
                            {
                              gameCat_Sports.map((item, i) => (
                                <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-left mb-5">
                                  <Bounce duration={1000 + (1000 * i)}>
                                    <div className="position-relative">
                                      <img src={item.img} className="w-100" />
                                      <div className="position-absolute gameCategoryDetailsPos">
                                        <span className="gameCategoryTitle">{item.title}</span> <br />
                                        <span className="gameCategoryDesc">{item.desc}</span>
                                      </div>
                                    </div>
                                  </Bounce>
                                </Col>
                              ))
                            }
                          </Row>
                        </div>
                        <Carousel className="mb-5 d-block d-lg-none" preventMovementUntilSwipeScrollTolerance={true} infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                          {
                            gameCat_Sports.map((item, i) => (
                              <div className="text-left mb-5 p-4 p-md-5">
                                <Bounce duration={1000 + (1000 * i)}>
                                  <div className="position-relative">
                                    <img src={item.img} className="w-100" />
                                    <div className="position-absolute gameCategoryDetailsPos">
                                      <span className="gameCategoryTitle">{item.title}</span> <br />
                                      <span className="gameCategoryDesc">{item.desc}</span>
                                    </div>
                                  </div>
                                </Bounce>
                              </div>
                            ))
                          }
                        </Carousel>
                      </div>

                      <a href="/our-games">
                        <div className="learnMoreBtn">
                          <span>Learn More</span>
                        </div>
                      </a>

                    </Col>
                  </Row>

                  <div className="sideItem3Pos">
                    <img src={require('../assets/img/home/sideItem3.png').default} className="img-fluid" />
                  </div>

                  <div className="sideItem4Pos">
                    <img src={require('../assets/img/home/sideItem4.png').default} className="img-fluid" />
                  </div>
                </div>
              </FullpageSection>


              <FullpageSection className="fullPageHeight our-partner">
                <div className="pt-5">
                  <div className="position-relative pt-5">
                    <Row className="justify-content-center align-items-center text-center pt-5">
                      <Col lg={7} md={8} sm={11} xs={11}>
                        <span className="headerTitle">Our Partners</span> <br />
                        <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                        <span className="brandsDesc">
                          All over the world, we work with many of the biggest names in the industry.Discover how Yeebet Gaming Group is already benefiting gaming companies.You can also learn more about our promotional roadmaps, marketing roadmaps, games roadmaps, and more in our Client Zone for Partners.
                        </span> <br />
                      </Col>

                      <Col lg={9} md={10} sm={12} xs={12}>
                        <Row className="justify-content-center align-items-center pt-4 text-center">
                          <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                            <div className={selectedPartnerCategory === 0 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setPartnerCategory(0)}>
                              <span>Exclusive Partner</span>
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                            <div className={selectedPartnerCategory === 1 ? "gameCategoryActiveTab py-3 py-md-2" : "gameCategoryTab py-3 py-md-2"} onClick={() => setPartnerCategory(1)}>
                              <span>B2B</span>
                            </div>
                          </Col>
                          <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                            <div className={selectedPartnerCategory === 2 ? "gameCategoryActiveTab py-3 py-md-2" : "gameCategoryTab py-3 py-md-2"} onClick={() => setPartnerCategory(2)}>
                              <span>B2C</span>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col lg={8} md={11} sm={12} xs={12} className="logoPanel paddingNarrow">
                        <div className={selectedPartnerCategory === 0 ? "d-block" : "d-none"}>
                          <Fade duration={1500}>
                            <Row className="justify-content-center align-items-center pt-5">
                              {
                                ex_logoList.map((item, i) => (
                                  <Col lg={3} md={3} sm={6} xs={6} key={i} className="text-left mb-5">
                                    <div className="position-relative partnerLogoBg">
                                      <img src={item.img} className="w-100" />
                                    </div>
                                  </Col>
                                ))
                              }
                            </Row>
                          </Fade>
                        </div>

                        <div className={selectedPartnerCategory === 1 ? "d-block" : "d-none"}>
                          <Fade duration={1500}>
                            <div className="container-fluid text-center my-3 paddingNarrow">
                              <Marquee gradientColor={[19, 18, 21]} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                                {b2b_logoList.map((item, i) => (
                                  <div className="position-relative partnerLogoBg mx-4">
                                    <img
                                      key={i}
                                      src={item.img}
                                      className="img-fluid w-100"
                                      alt="brand"
                                    />
                                  </div>
                                ))}
                              </Marquee>

                              <Marquee className="mt-3" gradientColor={[19, 18, 21]} direction={"right"} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                                {b2b_logoList2.map((item, i) => (
                                  <div className="position-relative partnerLogoBg mx-4">
                                    <img
                                      key={i}
                                      src={item.img}
                                      className="img-fluid w-100"
                                      alt="brand"
                                    />
                                  </div>
                                ))}
                              </Marquee>
                            </div>
                          </Fade>
                        </div>

                        <Marquee gradientColor={[19, 18, 21]} gradientWidth={50} pauseOnClick={true} pauseOnHover={true}>
                          {b2c_logoList.map((item, i) => (
                            <div className={selectedPartnerCategory === 2 ? "d-block" : "d-none"}>
                              <Fade duration={1500}>
                                <div className={"position-relative partnerLogoBg mx-4 mt-3"}>
                                  <img
                                    key={i}
                                    src={item.img}
                                    className="img-fluid w-100"
                                    alt="brand"
                                  />
                                </div>
                              </Fade>
                            </div>
                          ))}
                        </Marquee>

                        <Marquee gradientColor={[19, 18, 21]} direction={"right"} gradientWidth={50} pauseOnClick={true} pauseOnHover={true}>
                          {b2c_logoList2.map((item, i) => (
                            <div className={selectedPartnerCategory === 2 ? "d-block" : "d-none"}>
                              <Fade duration={1500}>
                                <div className={"position-relative partnerLogoBg mx-4 mt-3"}>
                                  <img
                                    key={i}
                                    src={item.img}
                                    className="img-fluid w-100"
                                    alt="brand"
                                  />
                                </div>
                              </Fade>
                            </div>
                          ))}
                        </Marquee>

                        <a href="/our-partner">
                          <div className="learnMoreBtn mt-3">
                            <span>View More</span>
                          </div>
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </FullpageSection>

              <FullpageSection className="fullPageHeight our-partner">
                <div className="position-relative downloadDiv fullPageHeight d-flex align-items-center">
                  <div className="h-100 d-flex align-items-center">
                    <Row className="justify-content-center align-items-center text-center">
                      <Col lg={11} md={11} sm={12} xs={12} className="">
                        <Row className="justify-content-center align-items-center text-center">
                          <Col lg={6} md={12} sm={12} xs={12} className="text-center text-lg-right">
                            <Bounce duration={1500}>
                              <img src={require('../assets/img/home/downloadImg.png').default} className="img-fluid" />
                            </Bounce>
                          </Col>
                          <Col lg={5} md={12} sm={12} xs={12} className="text-center text-lg-left">
                            <span className="headerTitle">Download Yeebet</span> <br />
                            <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                            <span className="brandsDesc">Experience With Us Now</span> <br />
                            <Row className="justify-content-center justify-content-lg-start align-items-center pt-4 text-center">
                              <Col lg={4} md={4} sm={6} xs={6} className="text-center text-lg-right">
                                <Fade top duration={1500}>
                                  <img src={require('../assets/img/home/appsStoreBtn.png').default} className="img-fluid" />
                                </Fade>
                              </Col>
                              <Col lg={4} md={4} sm={6} xs={6} className="text-center text-lg-right">
                                <Fade top duration={1500}>
                                  <img src={require('../assets/img/home/androidBtn.png').default} className="img-fluid" />
                                </Fade>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="sideItem5Pos">
                    <img src={require('../assets/img/home/sideItem5.png').default} className="img-fluid" />
                  </div>

                  <div className="sideItem6Pos">
                    <img src={require('../assets/img/home/sideItem6.png').default} className="img-fluid" />
                  </div>
                </div>
              </FullpageSection>

              <FullpageSection className="fullPageHeight our-partner">
                <div className="fullPageHeight d-flex align-items-end">
                  <div className="w-100">
                    <div className="subscribe-content">
                      <Row className="justify-content-center align-items-center text-center">
                        <Col lg={10} md={10} sm={12} xs={12} className="">
                          <Row className="justify-content-center align-items-start text-center">
                            <Col lg={6} md={12} sm={12} xs={12} className="text-left mb-5 mb-lg-0">
                              <Fade bottom duration={1500}>
                                <div className="newsletterBg py-5 px-5">
                                  <span className="newsletterTitle">
                                    Get In Touch With Us
                                  </span> <br />
                                  <span className="newsletterDesc">
                                    We are ready to answer all of your enquiry.
                                  </span>
                                  <a href="/contact-us">
                                    <div className="newsletterBtn py-1 mt-4 text-center ">
                                      <span>Contact Us</span>
                                    </div>
                                  </a>
                                </div>
                              </Fade>
                            </Col>

                            <Col lg={6} md={12} sm={12} xs={12} className="text-left mb-5 mb-lg-0">
                              <Fade bottom duration={1500}>
                                <div className="newsletterBg py-5 px-5">
                                  <span className="newsletterTitle">
                                    Subscribe <br />To Our newsletter
                                  </span> <br />
                                  <Row className="justify-content-start align-items-start pt-3 text-center">
                                    <Col lg={10} md={10} sm={12} xs={12} className="text-center">
                                      <div className="subscribe-textBoxBg">
                                        <div className="d-block d-lg-flex position-relative">
                                          <input
                                            type="text"
                                            className="form-control subscribe-textBox py-3"
                                            name="otpTag"
                                            placeholder="Your Email"
                                          />
                                          <div className='subscribeBtnPos px-2'>
                                            <button className="newsletterSubscribeBtn px-3 py-2 d-flex justify-content-center align-items-center cursor-pointer">
                                              <span className="requestCodeBtnText">Subscribe</span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Fade>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <DarkFooter />
                  </div>
                </div>
              </FullpageSection>
            </FullPageSections>
          </Fullpage>
        </div>

        <div className="d-block d-lg-none">
          <div className="header-section position-relative">
            <div className="video-container" style={{ lineHeight: "0" }}>
              <video autoPlay={true} playsInline muted loop id="myVideo" style={{ width: "100%", height: '100vh' }}>
                <source src={require('../assets/img/home/promo-video.mp4').default} type="video/mp4" />
              </video>
            </div>
            <div className="videoOverlay" />

            <div className="position-absolute mx-auto text-center" style={{ left: "0", right: "0", top: "40%" }}>
              <span className="overlaySectionTitle">Yeebet Gaming Group</span>

              <a href="https://www.yeebet.vip/" target={"_blank"}>
                <button className="headerBtn mx-auto mt-5">
                  TRY NOW
                  <div className="headerBtn_horizontal"></div>
                  <div className="headerBtn_vertical"></div>
                </button>
              </a>

              <a href="/contact-us" target={"_blank"}>
                <div className="text-center pt-4">
                  <span className="contactUsText">CONTACT US </span>
                  <span className="contactUsText"><FaAngleDoubleRight /></span>
                </div>
              </a>
            </div>
          </div>

          <div className="brandsBg">
            <div className="our-brands position-relative pt-5">
              <Row className="justify-content-center align-items-center pt-5 text-center">
                <Col lg={5} md={10} sm={11} xs={11}>
                  <span className="headerTitle">Our Brands</span> <br />
                  <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                  <span className="brandsDesc">One stop solution for games api provider, online casino white label, affiliate marketing, Asia payments solution, and so much more.</span> <br />
                </Col>

                <Col lg={10} md={11} sm={12} xs={12}>
                  <Row className="justify-content-center align-items-center pt-5 text-center">
                    {
                      brandsLogo.map((item, i) => (
                        <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-center mb-5">
                          <img src={item.img} className="brandsLogo" style={{ position: "sticky", zIndex: "10" }} />
                        </Col>
                      ))
                    }
                  </Row>
                </Col>
              </Row>

              <div className="sideItem1Pos">
                <img src={require('../assets/img/home/sideItem1.png').default} className="img-fluid" />
              </div>

              <div className="sideItem2Pos">
                <img src={require('../assets/img/home/sideItem2.png').default} className="img-fluid" />
              </div>
            </div>

            <div className="gamingGroupPos">
              <Row className="justify-content-center align-items-center text-center">
                <Col xl={9} lg={9} md={11} sm={12} xs={12}>
                  <div className="gamingGroupDiv d-flex justify-content-center align-items-center py-5 mb-5">
                    <div className="px-0 px-md-4 px-lg-0" style={{}}>
                      <Row className="justify-content-center align-items-center text-center mb-4">
                        <Col lg={8} md={8} sm={10} xs={10}>
                          <img src={require('../assets/img/home/gamingGroupImg.png').default} className="img-fluid" />
                        </Col>
                      </Row>
                      <span className="gameGroupTitle">Yeebet Gaming Group</span> <br />
                      <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid mobileLine" /> <br /><br />
                      <Row className="justify-content-center align-items-center text-center">
                        <Col lg={8} md={12} sm={12} xs={12}>
                          <p className="gamingGroupDesc">
                            Yeebet Gaming Group is a global award-winning technology and services provider for online and land-based gaming industry. Yeebet Gaming Group’s innovative and proven offerings include Yeebet Live Dealer Games, UU Slots, Yeebet Sportsbooks Solution and more – all ready to be launched and managed through Metasoft One Api Solution, its pioneering business management environment.
                            <br /><br />
                            With Yeebet Gaming Group's DKG Platform, partners benefit from powerful back office tools and all-inclusive services. From standalone setup to turnkey and white label solutions, Yeebet Group offers its partners unmatched success.
                            <br /><br />
                            iGaming2u Yellow Page Solutions also deliver extra value by providing useful information of white label solution, payment solution, games provider solution, B2C affiliate options to our stakeholders.
                          </p> <br />
                        </Col>
                      </Row>
                      <a href="/about-us">
                        <div className="learnMoreBtn my-1 py-0 py-md-3">
                          <span className="">Learn More</span>
                        </div>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

          </div>


          <div className="game-category py-5 position-relative">
            <Row className="justify-content-center align-items-center text-center gameCategoryContent">
              <Col lg={10} md={10} sm={11} xs={11}>
                <span className="headerTitle">Our Game Category</span> <br />
                <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                <span className="brandsDesc">The more tables and better games content for audience, the higher the traffic we could lead for you.</span> <br />
              </Col>

              <Col lg={11} md={11} sm={12} xs={12}>
                <Row className="justify-content-center align-items-center pt-4 text-center mb-3 mb-lg-0">
                  <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                    <div className={selectedGameCategory === 0 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(0)}>
                      <span>Live Casino</span>
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                    <div className={selectedGameCategory === 1 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(1)}>
                      <span>Slots</span>
                    </div>
                  </Col>
                  <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                    <div className={selectedGameCategory === 2 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(2)}>
                      <span>Sports</span>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col lg={8} md={11} sm={11} xs={11}>
                <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                  <div className="d-none d-lg-block">
                    <Row className="justify-content-center align-items-center pt-4">
                      {
                        gameCat_LiveCasino.map((item, i) => (
                          <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-left mb-5">
                            <div className="position-relative">
                              <img src={item.img} className="w-100" />
                              <div className="position-absolute gameCategoryDetailsPos">
                                <span className="gameCategoryTitle">{item.title}</span> <br />
                                <span className="gameCategoryDesc">{item.desc}</span>
                              </div>
                            </div>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>


                  <Row className="justify-content-center align-items-center pt-4">
                    <Col lg={9} md={11} sm={12} xs={12} className="pl-0 pr-0">
                      <Carousel className="mb-5 d-block d-lg-none" preventMovementUntilSwipeScrollTolerance={true} infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                        {
                          gameCat_LiveCasino.map((item, i) => (
                            <div className="text-left mb-5 p-4 p-md-5">
                              <div className="position-relative">
                                <img src={item.img} className="w-100" />
                                <div className="position-absolute gameCategoryDetailsPos">
                                  <span className="gameCategoryTitle">{item.title}</span> <br />
                                  <span className="gameCategoryDesc">{item.desc}</span>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </Carousel>
                    </Col>
                  </Row>
                </div>

                <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                  <div className="d-none d-lg-block">
                    <Row className="justify-content-center align-items-center pt-5">
                      {
                        gameCat_Slot.map((item, i) => (
                          <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-left mb-5">
                            <div className="position-relative">
                              <img src={item.img} className="w-100" />
                              <div className="position-absolute gameCategoryDetailsPos">
                                <span className="gameCategoryTitle">{item.title}</span> <br />
                                <span className="gameCategoryDesc">{item.desc}</span>
                              </div>
                            </div>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>

                  <Carousel className="mb-5 d-block d-lg-none" preventMovementUntilSwipeScrollTolerance={true} infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                    {
                      gameCat_Slot.map((item, i) => (
                        <div className="text-left mb-5 p-4 p-md-5">
                          <div className="position-relative">
                            <img src={item.img} className="w-100" />
                            <div className="position-absolute gameCategoryDetailsPos">
                              <span className="gameCategoryTitle">{item.title}</span> <br />
                              <span className="gameCategoryDesc">{item.desc}</span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </Carousel>
                </div>

                <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                  <div className="d-none d-lg-block">
                    <Row className="justify-content-center align-items-center pt-5">
                      {
                        gameCat_Sports.map((item, i) => (
                          <Col lg={4} md={4} sm={4} xs={4} key={i} className="text-left mb-5">
                            <div className="position-relative">
                              <img src={item.img} className="w-100" />
                              <div className="position-absolute gameCategoryDetailsPos">
                                <span className="gameCategoryTitle">{item.title}</span> <br />
                                <span className="gameCategoryDesc">{item.desc}</span>
                              </div>
                            </div>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>
                  <Carousel className="mb-5 d-block d-lg-none" preventMovementUntilSwipeScrollTolerance={true} infiniteLoop autoPlay showThumbs={false} showStatus={false}>
                    {
                      gameCat_Sports.map((item, i) => (
                        <div className="text-left mb-5 p-4 p-md-5">
                          <div className="position-relative">
                            <img src={item.img} className="w-100" />
                            <div className="position-absolute gameCategoryDetailsPos">
                              <span className="gameCategoryTitle">{item.title}</span> <br />
                              <span className="gameCategoryDesc">{item.desc}</span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </Carousel>
                </div>

                <a href="/our-games">
                  <div className="learnMoreBtn py-0 py-md-3">
                    <span>Learn More</span>
                  </div>
                </a>

              </Col>
            </Row>

            <div className="sideItem3Pos">
              <img src={require('../assets/img/home/sideItem3.png').default} className="img-fluid" />
            </div>

            <div className="sideItem4Pos">
              <img src={require('../assets/img/home/sideItem4.png').default} className="img-fluid" />
            </div>
          </div>

          <div className="our-partner py-5">
            <div className="position-relative py-5">
              <Row className="justify-content-center align-items-center text-center">
                <Col lg={7} md={8} sm={11} xs={11}>
                  <span className="headerTitle">Our Partners</span> <br />
                  <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                  <span className="brandsDesc">
                    All over the world, we work with many of the biggest names in the industry.Discover how Yeebet Gaming Group is already benefiting gaming companies.You can also learn more about our promotional roadmaps, marketing roadmaps, games roadmaps, and more in our Client Zone for Partners.
                  </span> <br />
                </Col>

                <Col lg={9} md={10} sm={12} xs={12}>
                  <Row className="justify-content-center align-items-center py-5 text-center">
                    <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                      <div className={selectedPartnerCategory === 0 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setPartnerCategory(0)}>
                        <span>Exclusive Partner</span>
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                      <div className={selectedPartnerCategory === 1 ? "gameCategoryActiveTab py-2 py-md-2" : "gameCategoryTab py-2 py-md-2"} onClick={() => setPartnerCategory(1)}>
                        <span>B2B</span>
                      </div>
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                      <div className={selectedPartnerCategory === 2 ? "gameCategoryActiveTab py-2 py-md-2" : "gameCategoryTab py-2 py-md-2"} onClick={() => setPartnerCategory(2)}>
                        <span>B2C</span>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col lg={8} md={11} sm={12} xs={12} className="logoPanel paddingNarrow">
                  <div className={selectedPartnerCategory === 0 ? "d-block" : "d-none"}>
                    <Row className="justify-content-center align-items-center pt-5">
                      {
                        ex_logoList.map((item, i) => (
                          <Col lg={3} md={3} sm={6} xs={6} key={i} className="text-left mb-5">
                            <div className="position-relative partnerLogoBg">
                              <img src={item.img} className="w-100" />
                            </div>
                          </Col>
                        ))
                      }
                    </Row>
                  </div>

                  <div className={selectedPartnerCategory === 1 ? "d-block" : "d-none"}>
                    <div className="container-fluid text-center my-3 paddingNarrow">
                      <Marquee gradientColor={[19, 18, 21]} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                        {b2b_logoList.map((item, i) => (
                          <div className="position-relative partnerLogoBg mx-4">
                            <img
                              key={i}
                              src={item.img}
                              className="img-fluid w-100"
                              alt="brand"
                            />
                          </div>
                        ))}
                      </Marquee>

                      <Marquee className="mt-3" gradientColor={[19, 18, 21]} direction={"right"} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                        {b2b_logoList2.map((item, i) => (
                          <div className="position-relative partnerLogoBg mx-4">
                            <img
                              key={i}
                              src={item.img}
                              className="img-fluid w-100"
                              alt="brand"
                            />
                          </div>
                        ))}
                      </Marquee>
                    </div>
                  </div>

                  <Marquee gradientColor={[19, 18, 21]} gradientWidth={50} pauseOnClick={true} pauseOnHover={true}>
                    {b2c_logoList.map((item, i) => (
                      <div className={selectedPartnerCategory === 2 ? "position-relative partnerLogoBg mx-4 d-block mt-3" : "d-none"}>
                        <img
                          key={i}
                          src={item.img}
                          className="img-fluid w-100"
                          alt="brand"
                        />
                      </div>
                    ))}
                  </Marquee>

                  <Marquee gradientColor={[19, 18, 21]} direction={"right"} gradientWidth={50} pauseOnClick={true} pauseOnHover={true}>
                    {b2c_logoList2.map((item, i) => (
                      <div className={selectedPartnerCategory === 2 ? "position-relative partnerLogoBg mx-4 mt-3 d-block mb-3" : "d-none"}>
                        <img
                          key={i}
                          src={item.img}
                          className="img-fluid w-100"
                          alt="brand"
                        />
                      </div>
                    ))}
                  </Marquee>

                  <a href="/our-partner">
                    <div className="learnMoreBtn mt-5 py-0 py-md-3">
                      <span>View More</span>
                    </div>
                  </a>
                </Col>
              </Row>
            </div>

            <div className="position-relative downloadDiv py-5">
              <div className="h-100 d-flex align-items-center my-5 py-5">
                <Row className="justify-content-center align-items-center text-center">
                  <Col lg={11} md={11} sm={12} xs={12} className="">
                    <Row className="justify-content-center align-items-center text-center">
                      <Col lg={6} md={12} sm={12} xs={12} className="text-center text-lg-right">
                        <img src={require('../assets/img/home/downloadImg.png').default} className="img-fluid" />
                      </Col>
                      <Col lg={5} md={12} sm={12} xs={12} className="text-center text-lg-left">
                        <span className="headerTitle">Download Yeebet</span> <br />
                        <img src={require('../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                        <span className="brandsDesc">Experience With Us Now</span> <br />
                        <Row className="justify-content-center justify-content-lg-start align-items-center pt-4 text-center">
                          <Col lg={4} md={4} sm={6} xs={6} className="text-center text-lg-right">
                            <img src={require('../assets/img/home/appsStoreBtn.png').default} className="img-fluid" />
                          </Col>
                          <Col lg={4} md={4} sm={6} xs={6} className="text-center text-lg-right">
                            <img src={require('../assets/img/home/androidBtn.png').default} className="img-fluid" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="sideItem5Pos">
                <img src={require('../assets/img/home/sideItem5.png').default} className="img-fluid" />
              </div>

              <div className="sideItem6Pos">
                <img src={require('../assets/img/home/sideItem6.png').default} className="img-fluid" />
              </div>
            </div>

            <div className="subscribe-content">
              <Row className="justify-content-center align-items-center text-center">
                <Col lg={10} md={10} sm={12} xs={12} className="">
                  <Row className="justify-content-center align-items-start text-center">
                    <Col lg={6} md={12} sm={12} xs={12} className="text-left mb-5 mb-lg-0">
                      <div className="newsletterBg py-5 px-4">
                        <span className="newsletterTitle">
                          Get In Touch With Us
                        </span> <br />
                        <span className="newsletterDesc">
                          We are ready to answer all of your enquiry.
                        </span>
                        <a href="/contact-us">
                          <div className="newsletterBtn py-1 mt-4 text-center ">
                            <span>Contact Us</span>
                          </div>
                        </a>
                      </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={12} className="text-left mb-5 mb-lg-0">
                      <div className="newsletterBg py-5 px-4">
                        <span className="newsletterTitle">
                          Subscribe <br />To Our newsletter
                        </span> <br />
                        {/* <div className="newsletterBtn py-1 text-center mt-3">
                        <span>Contact Us</span>
                      </div> */}
                        <Row className="justify-content-start align-items-start pt-3 text-center">
                          <Col lg={10} md={10} sm={12} xs={12} className="text-center">
                            <div className="subscribe-textBoxBg">
                              <div className="d-block d-lg-flex position-relative">
                                <input
                                  type="text"
                                  className="form-control subscribe-textBox py-3"
                                  name="otpTag"
                                  placeholder="Your Email"
                                />
                                <div className='subscribeBtnPos px-2'>
                                  <button className="newsletterSubscribeBtn px-3 py-2 d-flex justify-content-center align-items-center cursor-pointer">
                                    {/* <span className="requestCodeBtnText">{loadingOtp ? (<FaSpinner className="fa-spin" />) : translate(props.lang, "Request code")}</span> */}
                                    <span className="requestCodeBtnText">Subscribe</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <DarkFooter />
        </div>
      </div>
      {/* <DarkFooter /> */}
    </>
  );
}

export default Index;
