import React from "react";
import { FaArrowCircleDown, FaCartArrowDown } from "react-icons/fa";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-yeebet");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("navbar-yeebet2");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-yeebet");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" >
        <Container style={{maxWidth:"90%"}}>
          <div className="navbar-translate">
            <NavbarBrand href="/index" id="navbar-brand">
              <img className="navBarLogo" src={require("../../assets/img/logo.png").default} />
            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink href="/index">
                  <span className="navItemText">Home</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about-us">
                  <span className="navItemText">About Us</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/our-brands">
                  <span className="navItemText">Our Brands</span>
                </NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="/our-brands"
                  nav
                  // onClick={(e) => e.preventDefault()}
                >
                  <span className="navItemText">Our Brands</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/index" tag={Link}>
                    Yeebet Live
                  </DropdownItem>
                  <DropdownItem to="https://ibcbet77.com/" tag={Link}>
                    Yeebet Sports
                  </DropdownItem>
                  <DropdownItem to="https://yeebet.info/" tag={Link}>
                    Yeebet News
                  </DropdownItem>
                  <DropdownItem to="https://uuslots.info/" tag={Link}>
                    Yeebet Slot
                  </DropdownItem>
                  <DropdownItem to="http://mgcslot.com/" tag={Link}>
                    MGC Slots
                  </DropdownItem>
                  <DropdownItem to="https://www.hcgame888.com/demo/#/" tag={Link}>
                    HC Slots
                  </DropdownItem>
                  <DropdownItem to="https://dkgaming.asia/" tag={Link}>
                    DK Gaming
                  </DropdownItem>
                  <DropdownItem to="https://metapay168.net/" tag={Link}>
                    Metapay
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}

              <NavItem>
                <NavLink href="/our-games">
                  <span className="navItemText">Our Games</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/news">
                  <span className="navItemText">News</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/contact-us">
                  <span className="navItemText">Contact Us</span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="https://www.yeebet.vip/" target={"_blank"}>
                  <div className="DemoDiv">
                    <span className="navItemDemoText">Demo</span>
                  </div>
                </NavLink>
              </NavItem>

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
