import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import "../../assets/css/home.scss";
import "../../assets/css/ourPartner.scss";
import { Col, Row } from "reactstrap";

function OurPartner() {
    const [selectedPartnerCategory, setPartnerCategory] = React.useState(1);

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <IndexNavbar />

            <div className="" id="ourPartner">
                <div className="our-partner pt-5">
                    <div className="position-relative pt-5">
                        <Row className="justify-content-center align-items-center text-center pt-5">
                            <Col lg={8} md={8} sm={11} xs={11} className="mb-4">
                                <span className="headerTitle">Our Partners</span> <br />
                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                <span className="brandsDesc">
                                    Logo Listing for Exclusive Partner/ B2B/ B2C respectively
                                </span> <br />
                            </Col>

                            <Col lg={11} md={11} sm={12} xs={12} className="px-0">
                                <Row className="justify-content-center align-items-center py-5 text-center">
                                    <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                        <div className={selectedPartnerCategory === 0 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setPartnerCategory(0)}>
                                            <span>Exclusive Partner</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                        <div className={selectedPartnerCategory === 1 ? "gameCategoryActiveTab py-2 py-md-2" : "gameCategoryTab py-2 py-md-2"} onClick={() => setPartnerCategory(1)}>
                                            <span>B2B</span>
                                        </div>
                                    </Col>
                                    <Col lg={2} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                        <div className={selectedPartnerCategory === 2 ? "gameCategoryActiveTab py-2 py-md-2" : "gameCategoryTab py-2 py-md-2"} onClick={() => setPartnerCategory(2)}>
                                            <span>B2C</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={12} md={12} sm={12} xs={12} className="logoPanel paddingNarrow">
                                <div className={selectedPartnerCategory === 0 ? "d-block" : "d-none"}>
                                    <Row className="justify-content-center align-items-center pt-4">
                                        <Col lg={12} md={12} sm={12} xs={12} className="text-left mb-4">
                                            <div className="partnerCatContainer pb-5 mb-5">
                                                <div className="mx-auto text-center GoldenBorder pb-5">
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">A</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">B</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">C</span>
                                                    <span className="text-white mx-3 ourPartnerABC">D</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">E</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">F</span>
                                                    <span className="text-white mx-3 ourPartnerABC">G</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">H</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">I</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">J</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">K</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">L</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">M</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">N</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">O</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">P</span>
                                                    <span className="text-white mx-3 ourPartnerABC">Q</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">R</span>
                                                    <span className="text-white mx-3 ourPartnerABC">S</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">T</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">U</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">V</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">W</span>
                                                    <span className="text-white mx-3 ourPartnerABC">X</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">Y</span>
                                                    <span className="text-white mx-3 ourPartnerABC">Z</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">#</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">D</span><br /><br />
                                                    <span className="text-white ourPartnerName">Dk Gaming</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">G</span><br /><br />
                                                    <span className="text-white ourPartnerName">GamingSoft</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">Q</span><br /><br />
                                                    <span className="text-white ourPartnerName">QK Tech</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">S</span><br /><br />
                                                    <span className="text-white ourPartnerName">SBO568win</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">X</span><br /><br />
                                                    <span className="text-white ourPartnerName">X2dynamic</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">Z</span><br /><br />
                                                    <span className="text-white ourPartnerName">Zenith Digital</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className={selectedPartnerCategory === 1 ? "d-block" : "d-none"}>
                                    <Row className="justify-content-center align-items-center pt-4">
                                        <Col lg={12} md={12} sm={12} xs={12} className="text-left mb-4">
                                            <div className="partnerCatContainer pb-5 mb-5">
                                                <div className="mx-auto text-center GoldenBorder pb-5">
                                                    <span className="text-white mx-3 ourPartnerABC">A</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">B</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">C</span>
                                                    <span className="text-white mx-3 ourPartnerABC">D</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">E</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">F</span>
                                                    <span className="text-white mx-3 ourPartnerABC">G</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">H</span>
                                                    <span className="text-white mx-3 ourPartnerABC">I</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">J</span>
                                                    <span className="text-white mx-3 ourPartnerABC">K</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">L</span>
                                                    <span className="text-white mx-3 ourPartnerABC">M</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">N</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">O</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">P</span>
                                                    <span className="text-white mx-3 ourPartnerABC">Q</span>
                                                    <span className="text-white mx-3 ourPartnerABC">R</span>
                                                    <span className="text-white mx-3 ourPartnerABC">S</span>
                                                    <span className="text-white mx-3 ourPartnerABC">T</span>
                                                    <span className="text-white mx-3 ourPartnerABC">U</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">V</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">W</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">X</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">Y</span>
                                                    <span className="text-white mx-3 ourPartnerABC">Z</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">#</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">A</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Absolute Live Gaming</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Askmebet</span>
                                                    <span className="text-white ourPartnerName">Avx</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">D</span><br /><br />
                                                    <span className="text-white ourPartnerName">Dst Gaming</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">G</span><br /><br />
                                                    <span className="text-white ourPartnerName">Gwin</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">I</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">IBS Gaming</span>
                                                    <span className="text-white ourPartnerName">Imperial Gaming</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">K</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">KG Sports</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Khmer Gaming</span>
                                                    <span className="text-white ourPartnerName">Kzing White Label</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">M</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">M8 Sports</span>
                                                    <span className="text-white ourPartnerName">Mondo Gaming</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">Q</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Q8</span>
                                                    <span className="text-white ourPartnerName">QQ288</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">R</span><br /><br />
                                                    <span className="text-white ourPartnerName">Redplay</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">S</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Sea Gaming</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Softgamings</span>
                                                    <span className="text-white ourPartnerName">Softswiss</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">T</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">T1soft</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Techplay Gaming</span>
                                                    <span className="text-white ourPartnerName">TGG</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">U</span><br /><br />
                                                    <span className="text-white ourPartnerName">UG</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">Z</span><br /><br />
                                                    <span className="text-white ourPartnerName">Zt Gaming</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className={selectedPartnerCategory === 2 ? "d-block" : "d-none"}>
                                    <Row className="justify-content-center align-items-center pt-4">
                                        <Col lg={12} md={12} sm={12} xs={12} className="text-left mb-4">
                                            <div className="partnerCatContainer pb-5 mb-5">
                                                <div className="mx-auto text-center GoldenBorder pb-5">
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">A</span>
                                                    <span className="text-white mx-3 ourPartnerABC">B</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">C</span>
                                                    <span className="text-white mx-3 ourPartnerABC">D</span>
                                                    <span className="text-white mx-3 ourPartnerABC">E</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">F</span>
                                                    <span className="text-white mx-3 ourPartnerABC">G</span>
                                                    <span className="text-white mx-3 ourPartnerABC">H</span>
                                                    <span className="text-white mx-3 ourPartnerABC">I</span>
                                                    <span className="text-white mx-3 ourPartnerABC">J</span>
                                                    <span className="text-white mx-3 ourPartnerABC">K</span>
                                                    <span className="text-white mx-3 ourPartnerABC">L</span>
                                                    <span className="text-white mx-3 ourPartnerABC">M</span>
                                                    <span className="text-white mx-3 ourPartnerABC">N</span>
                                                    <span className="text-white mx-3 ourPartnerABC">O</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">P</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">Q</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">R</span>
                                                    <span className="text-white mx-3 ourPartnerABC">S</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">T</span>
                                                    <span className="text-white mx-3 ourPartnerABC">U</span>
                                                    <span className="text-white mx-3 ourPartnerABC">V</span>
                                                    <span className="text-white mx-3 ourPartnerABC">W</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">X</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">Y</span>
                                                    <span className="text-inactive-gray mx-3 ourPartnerABC">Z</span>
                                                    <span className="text-white mx-3 ourPartnerABC">#</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">B</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Ball88</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Birdkub</span>
                                                    <span className="text-white ourPartnerName">B9BB</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">D</span><br /><br />
                                                    <span className="text-white ourPartnerName">Dreamgame33</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">E</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Eubet</span>
                                                    <span className="text-white ourPartnerName">Evo888</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">G</span><br /><br />
                                                    <span className="text-white ourPartnerName">Goal123</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">H</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">H3bet</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Hotgraph881</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Huay444</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Huay4d</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Huaylike</span>
                                                    <span className="text-white ourPartnerName">Hydra888</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">I</span><br /><br />
                                                    <span className="text-white ourPartnerName">iWIN</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">J</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Juad8881</span>
                                                    <span className="text-white ourPartnerName">Jw8</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">K</span><br /><br />
                                                    <span className="text-white ourPartnerName">K9win</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">L</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Leng4D</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Live22</span>
                                                    <span className="text-white ourPartnerName">Lockdown168</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">M</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Mahagame</span>
                                                    <span className="text-white ourPartnerName">Mooncity</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">N</span><br /><br />
                                                    <span className="text-white ourPartnerName">Nova88</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">O</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Obet33</span>
                                                    <span className="text-white ourPartnerName">Overgameclub</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">S</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Sagame350</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Sagame66</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Sagame77</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">SB288</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Sexygame666</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Siam66</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Ss350</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">SSgame56</span>
                                                    <span className="text-white ourPartnerName">Ssgame6666</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">U</span><br /><br />
                                                    <span className="text-white ourPartnerName">UUSlots</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">V</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Vip168sa</span>
                                                    <span className="text-white ourPartnerName">Vworld</span>
                                                </div>

                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">W</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">Wbet</span>
                                                    <span className="text-white ourPartnerName">Webet333</span>
                                                </div>
                                               
                                                <div className="pt-5">
                                                    <span className="text-white ourPartnerABC">#</span><br /><br />
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">1bet2u</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">22Fun</span>
                                                    <span className="text-white ourPartnerName mr-3 mr-lg-5">77bet</span>
                                                    <span className="text-white ourPartnerName">9Slotgame</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default OurPartner;
