import React from "react";
import DarkFooter from "components/Footers/DarkFooter.js";
import MainNavbar from "components/Navbars/MainNavbar";
import "../../assets/css/news.scss";
import { Col, Row } from "reactstrap";
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import moment from "moment";

function NewsDetails() {
    const { slug } = useParams();
    const { category } = useParams();

    const [newsDetailsInfo, setNewsDetailsInfo] = React.useState([]);
    const [apiLink, setApiLink] = React.useState("");
    const [loadingState, setLoadingState] = React.useState(false);

    TimeAgo.addDefaultLocale(en);
    const timeAgo = new TimeAgo('en-US');

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    React.useEffect(() => {
        if (category === "0") {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=45,46,47,48")
        }
        else if (category === "1") {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=63,1288,43,68")
        }
        else if (category === "2") {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=49,50,51,52,53,54,55,56")
        }
        else if (category === "3") {
            setApiLink("https://yeebet.info/wp-json/wp/v2/posts?categories=126")
        }
    }, []);

    React.useEffect(() => {
        setLoadingState(true);
        if (apiLink === "") { return; }
        getNewsDetails();
    }, [apiLink]);

    function getNewsDetails() {
        fetch(apiLink + "&slug=" + slug, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/JSON",
                "Content-Language": "en"
            }),
        })
            .then(response => response.json())
            .then((responseJson) => {
                setNewsDetailsInfo(responseJson);
                setLoadingState(false);
            })
            .catch(error => {
                console.error("error", error);
            });
    }

    function ConvertToMilliSecond(publishDateTime) {
        var myDate = new Date(publishDateTime);
        // var result = myDate.getTime();
        var result = moment(myDate).valueOf();
        console.log("RESULT", result);
        var dateNow = moment().valueOf();
        var dateDiff = dateNow - result;
        return dateDiff;
    }

    return (
        <>
            <MainNavbar />

            <div className="" id="news">
                <div className="news-Bg">
                    <div className="featuredVideos py-5 position-relative">
                        <Row className="justify-content-center align-items-center pb-5 text-center " style={{ position: "sticky", zIndex: "10" }}>
                            <Col lg={9} md={11} sm={12} xs={12}>
                                <div className="brands-card py-5 mx-auto position-relative" style={{ minHeight: "100vh" }}>
                                    {newsDetailsInfo && newsDetailsInfo.length && !loadingState > 0 ?
                                        <Row className="justify-content-center align-items-center text-center px-1 px-lg-5 pb-4">
                                            <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                                                <div className="text-left">
                                                    <span className="newsDetailsTitle">{(newsDetailsInfo[0].aioseo_head_json.title).substring(0, newsDetailsInfo[0].aioseo_head_json.title.length - 13)}</span> <br />
                                                    <div className="pt-4">
                                                        <div className="d-flex justify-content-start align-items-center text-center">
                                                            <div className="d-flex justify-content-center justify-content-lg-start">
                                                                <img src={newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person") && newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").image ?
                                                                    newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").image.url : "https://secure.gravatar.com/avatar/c6dff3a489168ff0434c028d870e09f0?s=96&d=mm&r=g"}
                                                                    className="mx-auto newsAuthorPic" />
                                                                <div className="ml-2 pt-1">
                                                                    <span className="newsDesc">{newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person") && newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").image ?
                                                                        newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "Person").name : "Sukizu_metasoft"}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="newsDetailsDivider d-md-block mx-3"></div>
                                                            <div className="d-flex justify-content-center justify-content-lg-start">
                                                                <img src={require('../../assets/img/news/time-icon.png').default} className="img-fluid timeIconSize" />
                                                                <div className="ml-3">
                                                                    <span className="newsDesc">{timeAgo.format(Date.now() - ConvertToMilliSecond(newsDetailsInfo[0].date))}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src={newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "WebPage") && newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "WebPage").image ?
                                                    newsDetailsInfo[0].aioseo_head_json.schema["@graph"].find(i => i["@type"] == "WebPage").image.url : require('../../assets/img/news/dummyImg.PNG').default}
                                                    className="mx-auto newsDetailsImg my-4" />
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left pt-5 pt-lg-0">
                                                {/* <span className="newsCategory">{item.aioseo_head_json.schema["@graph"][0].articleSection}</span> <br /> */}

                                                <div className="pt-3 text-left">
                                                    <span className="newsDetailsDesc">
                                                        {ReactHtmlParser(newsDetailsInfo[0].content.rendered)}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        <div className="position-absolute" style={{ left: "0", right: "0", top: "40%" }}>
                                            <div class="loader mx-auto">
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                                <div class="loader-square"></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>

                        <div className="sideItem1Pos-news">
                            <img src={require('../../assets/img/news/sideItem1.png').default} className="img-fluid" />
                        </div>

                        {/* <div className="sideItem2Pos-news">
                            <img src={require('../../assets/img/news/sideItem2.png').default} className="img-fluid" />
                        </div> */}
                    </div>

                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default NewsDetails;
