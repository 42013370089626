import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import "../../assets/css/privacyPolicy.scss";
import { Col, Row, Input } from "reactstrap";

function TermsCondition() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <IndexNavbar />

            <div className="" id="privacyPolicy">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">Terms & Condition</span>
                </div> */}

                <div className="privacyPolicyBg position-relative">
                    <div className="header-section position-relative pb-5">
                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={10} md={10} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">Terms of Use</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Version 1.0 <br />
                                            19th February 2021
                                            <br /><br />
                                            The terms of this agreement (“Terms of Use”) govern the relationship between you and Yeebet Gaming (hereinafter referred to as “Yeebet Gaming”, “us”, or “we”) regarding your use of Yeebet Gaming’ games, websites, and related services (the “Service”). Use of the Service is also governed by Yeebet Gaming’s Privacy Policy, which shall be incorporated with and considered an integral part of these Terms of Use.
                                            <br /><br />
                                            Before accessing or using the Service, including browsing any Yeebet Gaming’ website or accessing a game, you must agree to these Terms of Use and the Privacy Policy. By using the Service, you indicate that you are age 18 (or the legal age to gamble in the jurisdiction in which you reside, if higher) and that you understand and agree to these Terms of Use. If you access the Service from a Social Networking Site such as Facebook, you shall comply with its Terms of Use/use as well as these Terms of Use.
                                            <br /><br />
                                            By installing, using, or otherwise accessing the Service, you agree to these Terms of Use. If you do not agree to these Terms of Use, please do not use, or otherwise access the Service. For the avoidance of doubt, use of the Service is void where prohibited under local legislation.
                                            <br /><br />
                                            Yeebet Gaming reserves the right, at its discretion, to change, modify, add or remove portions of these Terms of Use and its Privacy Policy at any time by posting the amended terms on the Yeebet Gaming Service. You will be deemed to have accepted such changes by continuing to use the Service. If at any time you do not agree to any portion of the then-current version of our Terms of Use, Privacy Policy, or any other policy as may be established by Yeebet Gaming from time to time, rules, or codes of conduct relating to your use of the Services, your license to use the Service shall immediately terminate, and you must immediately stop using the Service.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">1.0 <span className="headerTitle">License</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            <b>1.1 Grant of License and Limitations</b>
                                            <br /><br />
                                            Subject to your agreement and continuing compliance with these Terms of Use and any other relevant Yeebet Gaming’s policies, we grant you a non-exclusive, non-transferable, non-sublicensable, revocable limited license subject to the limitations below to access and use the Service for your own non-commercial entertainment purposes. You agree not to use the Service for any other purpose.
                                            <br /><br />
                                            The following restrictions apply to the use of the Service:
                                            <br /><br />
                                            You shall not access the Service if you are under the age of 18 (or the legal age to gamble in the jurisdiction in which you reside, if higher). You shall restrict use by minors, and you will deny access to children under the age of 18 (or the legal age to gamble in the jurisdiction in which you reside, if higher). You accept full responsibility for any unauthorized use of the Service by minors.
                                            <br /><br />
                                            You shall not use the Service if you have previously been removed by Yeebet Gaming, or previously been banned from playing any Yeebet Gaming’s games.
                                            You shall use the Service only for non-commercial purposes. You shall not use the Service to advertise, solicit, or transmit any commercial advertisements, including chain letters, junk, or spam e-mail or repetitive messages to anyone. You shall not use the Service or any portion thereof (including but in no way limited to any image, graphic, title) in conjunction with any other games, products, services or software without Yeebet Gaming’s express written consent.
                                            <br /><br />
                                            As a condition of your use of the Service, you warrant and undertake to Yeebet Gaming that you will not use the Service for any purpose that is unlawful or prohibited by these terms, conditions and notices. You may not use the Service in any manner which could damage, disable, overburden, or impair the Service or interfere with any other party’s use of the Service. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided through the Service. You must not attempt to gain unauthorised access to the Service, the servers on which the Service is stored or any server, computer or database connected to the Service. When breaching this provision we will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Service will cease immediately.
                                            <br /><br />
                                            Any use of the Service in violation of these License Limitations is strictly prohibited and may result in the immediate revocation of your limited license and may subject you to liability for violations of law. You agree that you will not, under any circumstances:
                                            <br /><br />
                                            <ul className="text-left">
                                                <li>
                                                    Engage in any act that Yeebet Gaming deems to be in conflict with the spirit or intent of the Service or make improper use of Yeebet Gaming’s support services.
                                                </li>
                                                <li>
                                                    Use exploits, automation software, bots, hacks, mods, or any unauthorized third party software designed to modify or interfere with the Service or any Yeebet Gaming game, without express written consent from us.
                                                </li>
                                                <li>
                                                    Disrupt, overburden, or aid or assist in the disruption or overburdening of any computer or server (“Server”) used to offer or support the Service or any Yeebet Gaming game environment.
                                                </li>
                                                <li>
                                                    Institute, assist, or become involved in any type of attack, including without limitation distribution of a virus, denial of service attacks upon the Service, or other attempts to disrupt the Service or any other person’s use or enjoyment of the Service.
                                                </li>

                                                <li>
                                                    Attempt to gain unauthorized access to the Service, Servers, or networks connected to the Service by any means other than the user interface provided by Yeebet Gaming.
                                                </li>
                                                <li>
                                                    Post any information that is abusive, threatening, obscene, defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable or offensive.
                                                </li>
                                                <li>
                                                    Attempt to, or harass, abuse, or harm, or advocate or incite harassment, abuse, or harm of another person or group, including Yeebet Gaming employees, including customer service representatives.
                                                </li>
                                                <li>
                                                    Make available through the Service any material or information that infringes any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or other right of any person or entity or impersonates any other person, including without limitation a Yeebet Gaming employee.
                                                </li>
                                                <li>
                                                    Reverse engineer, decompile, disassemble, decipher, or otherwise attempt to derive the source code for any underlying software or other intellectual property used to provide the
                                                </li>
                                                <li>
                                                    Obtain any information from the Service or any Yeebet Gaming game using any method not expressly permitted by Yeebet Gaming.
                                                </li>
                                                <li>
                                                    Yeebet Gaming reserves the right to determine what conduct it considers to be in violation of the rules of use or otherwise outside the intent or spirit of these Terms of Use or the Service itself. Yeebet Gaming reserves the right to take action as a result, which may include prohibiting you from using the Service in whole or in part.
                                                </li>
                                            </ul>
                                            <br />
                                            <b>1.2 Suspension and Termination of Account and Service</b>
                                            <br /><br />
                                            Without limiting any other remedies, Yeebet Gaming may limit, suspend, terminate, modify, or delete accounts or access to our Service or portions thereof if you are (or Yeebet Gaming suspects that you are) failing to comply with any of these Terms of Use or for any actual or suspected illegal or improper use of the Service, with or without notice to you.
                                            <br /><br />
                                            Without limiting our other remedies, we may limit, suspend, or terminate the Service, prohibit access to our games and sites, and their content, services, and tools, delay or remove hosted content, and take technical and legal steps to prevent users from accessing the Service if we believe that they are creating risk or possible legal liabilities infringing the intellectual property rights of third parties, or acting inconsistently with the letter or spirit of our terms or policies.
                                            <br /><br />
                                            Yeebet Gaming reserves the right to stop offering and/or supporting the Service or a particular game or part of the Service at any time, at which point your license to use the Service or a part thereof will be automatically terminated. In such event and for the avoidance of doubt, Yeebet Gaming shall not be required to provide refunds, benefits, or other compensation to users in connection with such discontinued Services.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">2.0 <span className="headerTitle">Ownership</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            <b>2.1 Intellectual Property Rights</b>
                                            <br /><br />
                                            All rights, title and interest in and to the Service (including without limitation any website design, text, graphics, photographs, games, titles, computer code, themes, objects, characters, character names, stories, dialogue, catch phrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, methods of operation, moral rights, documentation, in-game chat transcripts, character profile information. Underlying source code, software and other proprietary rights) are owned by or licensed to Yeebet Gaming. We reserve all rights, including without limitation, all intellectual property rights or other proprietary rights, in connection with its game and the Service. You acknowledge you are not allowed to reproduce the Service or any part of it in any form whatsoever without Yeebet Gaming’s express written consent. To the extent that any material may be downloaded or printed then such material may be downloaded to a single personal computer only and hard copy portions may be printed solely for your own personal and non-commercial use. Under no circumstances shall the use of the Service grant you any interest in any intellectual property rights owned by us or by any third party whatsoever. No rights whatsoever are granted to use or reproduce any trade names, trademarks or logos except if specifically permitted in writing by the owner of such rights. Any breach of this requirement which we become aware of will be notified within reasonable time to the respective intellectual property right owner for undertaking appropriate action and enforcement.
                                            <br /><br />
                                            <b>2.2 User Content</b>
                                            <br /><br />
                                            “User Content” means any communications, images, sounds, and all the material, data, and information that you upload or transmit through a Yeebet Gaming client or the Service, or that other users upload or transmit, including without limitation any chat text. By transmitting or submitting any User Content while using the Service, you affirm, represent and warrant that such transmission or submission is (a) accurate and not confidential; (b) not in violation of any laws, contractual restrictions or other third party rights, and that you have permission from any third party whose personal information or intellectual property is compromised in the User Content; (c) free of viruses, adware, spyware, worms or other malicious code; and (d) you acknowledge and agree that any of your personal information within such content will at all times be processed by Yeebet Gaming in accordance with its Privacy Policy.
                                            <br /><br />
                                            Yeebet Gaming reserves that right in its sole discretion to review, monitor, prohibit, edit, delete, disable access to or otherwise make unavailable any User Content (including without limitation your User Content) without notice for any reason or for no reason at any time.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">3.0 <span className="headerTitle">User Content</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            <b>3.1 Content Screening</b>
                                            <br /><br />
                                            Yeebet Gaming assumes no responsibility for the conduct of any user submitting any User Content and assumes no responsibility for monitoring the Service for inappropriate content or conduct. We do not, and cannot, pre-screen or monitor all User Content. Your use of the Service is at your own risk. By using the Service, you may be exposed to User Content that is offensive, indecent, or otherwise not in line with your expectations. You bear all risks associated with the use of any User Content available in connection with the Service. At our discretion, our representatives or technology may monitor and/or record your interaction with the Service or communications (including without limitation chat text) when you are using the Service.
                                            <br /><br />
                                            By entering into these Terms of Use, you hereby provide your irrevocable consent to such monitoring and recording. You acknowledge and agree that you have no expectation of privacy concerning the transmission of any User Content, including without limitation chat text or voice communications.
                                            If at any time Yeebet Gaming chooses, in its sole discretion, to monitor the Service, Yeebet Gaming nonetheless assumes no responsibility for User Content and assumes no obligation to modify or remove any inappropriate User Content. We have the right, but not the obligation, in our sole discretion to edit, refuse to post, or remove any User Content.
                                            <br /><br />
                                            <b>3.2 Information Use by Other Members of the Service</b>
                                            <br /><br />
                                            The Service may include various forums, blogs, and chat features where you can post User Content, including your observations and comments on designated topics. Yeebet Gaming cannot guarantee that other members will not use the ideas and information that you share. Therefore, if you have an idea or information that you would like to keep confidential and/or do not want others to use, do not post it on the Service. Yeebet Gaming has no responsibility to evaluate, use or compensate you for any ideas or information you may choose to submit.
                                            <br /><br />
                                            You are solely responsible for the information you post on, through, or in connection with the Service and that you provide to others. Yeebet Gaming may reject, refuse to post, or delete any User Content for any or no reason, including but not limited to User Content that in the sole judgment of Yeebet Gaming violates these Terms of Use.
                                            <br /><br />
                                            <b>3.3 License </b>
                                            <br /><br />
                                            You hereby grant to Yeebet Gaming an irrevocable, perpetual, transferable, fully paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize, publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly perform, or provide access to electronically, broadcast, communicate to the public by telecommunication, display, performance, enter into computer memory, and use and practice in any way, your User Content as well as all modified and derivative works thereof in connection with our provision of the Service, including marketing and promotions of the Service. You also hereby grant to Yeebet Gaming the right to authorize others to exercise any of the rights granted to Yeebet Gaming under these Terms of Use. You further hereby grant to Yeebet Gaming the right to use your name, likeness, and any other information or material included in any User Content and in connection with any User Content, in accordance with our Privacy Policy. Except as prohibited by law, you waive any rights of attribution and/or any moral rights you may have in your User Content, regardless of whether your User Content is altered or changed in any manner. Yeebet Gaming does not claim any ownership rights in your User Content and nothing in these Terms of Use is intended to restrict any rights that you may have to use and exploit your User Content. Yeebet Gaming has no obligation to monitor or enforce your intellectual property rights in or to your User Content.
                                            <br /><br />
                                            <b>3.4 User Interactions</b>
                                            <br /><br />
                                            You are solely responsible for your interactions with other users of the Service and any other parties with whom you interact through the Service and/or Yeebet Gaming games. We reserve the right, but have no obligation, to become involved in any way with these disputes. You will fully cooperate with Yeebet Gaming to investigate any suspected unlawful, fraudulent, or improper activity.
                                            <br /><br />
                                            If you have a dispute with one or more users, you release us (and our officers, directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
                                        </span> <br />
                                    </Col>
                                </Row>


                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">4.0 <span className="headerTitle">Updates To The Service</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            You understand that the Service is an evolving one. Yeebet Gaming may require that you accept updates to the Service and to Yeebet Gaming’ games. You acknowledge and agree that Yeebet Gaming may update the Service with or without notifying you. You may need to update third party software from time to time in order to receive the Service and play Yeebet Gaming’ games.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">5.0 <span className="headerTitle">Disclaimer Of Warranties</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Without limiting Yeebet Gaming’s liability under Section 6 below, the Service is provided on an “as is” and “as available” basis for your use, without warranties of any kind, express or implied, including without limitation the warranties of merchantability, fitness for a particular purpose, title, non-infringement, and those arising from course of dealing or usage of trade. Yeebet Gaming does not warrant that you will be able to access or use the service at the time or locations of your choosing; that the Service will be uninterrupted or error-free; that defects will be corrected; or that the game or Service are free of viruses or other harmful components.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">6.0 <span className="headerTitle">Limitation Of Liability, Sole And Exclusive Remedy, Indemnification</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Yeebet Gaming shall not be liable to you for any indirect, incidental, consequential, special, punitive or other similar damages, including but not limited to loss of revenues, lost profits, post data or business interruption or other intangible losses (however such losses are qualified), arising out of or relating in any way to these terms of use or the service itself, whether based on contract, tort, or any other legal theory, and whether or not Yeebet Gaming has been advised of the possibility of such damages. Yeebet Gaming shall not be liable to you for more than the amount you have paid to Yeebet Gaming in accordance with these Terms of Use in the six months immediately preceding the date on which you first assert a claim. You acknowledge and agree that if you have not paid anything to Yeebet Gaming during such time period, your sole remedy (and Yeebet Gaming’s exclusive liability) for any dispute with Yeebet Gaming is to stop using the service.
                                            <br /><br />
                                            Some jurisdictions do not allow the exclusion of certain warranties or other limitation or exclusion of liability for certain types of damages. Accordingly, some of the above disclaimers and limitations may not apply to you. To the extent that Yeebet Gaming may not, as a matter of applicable law, disclaim any warranty or limit its liability as set forth herein, the scope of such warranty and the extent of Yeebet Gaming’s liability shall be the minimum permitted under such applicable law. In particular, nothing in these Terms of Use shall affect the statutory rights of any consumer or exclude or restrict any liability for death or personal injury arising from any negligence or fraud of Yeebet Gaming.
                                            <br /><br />
                                            You agree to indemnify, defend, and hold Yeebet Gaming harmless for any claim, demand, damages, or other losses, including reasonable attorneys’ fees, asserted by any third party resulting from or arising out of your use of the Service, or any breach by you of these Terms of Use, however the foregoing does not apply if the infringement of rights is not attributable to your intentional or negligent behaviour.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">7.0 <span className="headerTitle">Links To Third Party Sites</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            The Yeebet Gaming website may contain links to other websites (“Linked Sites“). The Linked Sites are not under the control of Yeebet Gaming and Yeebet Gaming is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Yeebet Gaming is not responsible for webcasting or any other form of transmission received from any Linked Site. Yeebet Gaming is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Yeebet Gaming of the site or any association with its operators. You acknowledge that Yeebet Gaming cannot be held liable for the content of Linked Sites or for any consequence arising from you accessing the same and you acknowledge that such access or use of Linked Sites shall be is at your sole option, discretion and risk.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">8.0 <span className="headerTitle">Dispute Resolution And Law</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            If a dispute arises between you and Yeebet Gaming, we strongly encourage you to first contact us directly. These Terms of Use and any dispute arising out of or related to it or Privacy Policy or the Service shall be governed in all respects by the laws of Malta, without regard to conflict of law decisions.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">9.0 <span className="headerTitle">Dispute Resolution And Law</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            You and Yeebet Gaming agree that if any portion of these Terms of Use or of the Yeebet Gaming Privacy Policy is found illegal or unenforceable, in whole or in part by any court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the extent of such determination of invalidity or unenforceability without affecting the validity or enforceability thereof in any other manner or jurisdiction and without affecting the remaining provision of the terms, which shall continue to be in full force and effect.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">10.0 <span className="headerTitle">General Provisions</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            <b>10.1 Assignment</b>
                                            <br /><br />
                                            Yeebet Gaming may assign or delegate these Terms of Use and/or the Yeebet Gaming Privacy Policy, in whole or in part, to any person or entity at any time with or without your consent. You may not assign or delegate any rights or obligations under the Terms of Use or Privacy Policy without Yeebet Gaming’ prior written consent, and any unauthorized assignment and delegation by you is ineffective.
                                            <br /><br />
                                            <b>10.2 Entire Agreement</b>
                                            <br /><br />
                                            These Terms of Use, and Supplemental Policies and any documents expressly incorporated by reference herein (including the Yeebet Gaming Privacy Policy), contain the entire understanding of you and Yeebet Gaming, and supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether electronic, oral or written, or whether established by custom, practice, policy or precedent, between you and us with respect to the Service.
                                            <br /><br />
                                            <b>10.3 No Waiver</b>
                                            <br /><br />
                                            The failure of Yeebet Gaming to require or enforce strict performance by you of any provision of these Terms of Use or of the Yeebet Gaming Privacy Policy or failure to exercise any right under them shall not be construed as a waiver or relinquishment of Yeebet Gaming’ right to assert or rely upon any such provision or right in that or any other instance.
                                            <br /><br />
                                            The express waiver by Yeebet Gaming of any provision, condition, or requirement of these Terms of Use or of the Yeebet Gaming Privacy Policy shall not constitute a waiver of any future obligation to comply with such provision, condition, or requirement.
                                            <br /><br />
                                            Except as expressly and specifically set forth in these Terms of Use, no representations, statements, consents, waivers, or other acts or omissions by Yeebet Gaming shall be deemed a modification of these Terms of Use nor legally binding, unless documented in physical writing, hand signed by You and a dully appointed offer of Yeebet Gaming.
                                            <br /><br />
                                            <b>10.4 Noticess</b>
                                            <br /><br />
                                            We may notify you via postings on www.yeebet.org, or any other communications means to contact information you provide to us. All notices given by you are required from you under these Terms of Use or the Yeebet Gaming Privacy Policy shall be in writing and addressed to: Yeebet Gaming, 144, Tower Road, Sliema SLM1804, Malta. Any notices that you provide without compliance in this Section on Notices shall have no legal effect.
                                            <br /><br />
                                            <b>10.5 Equitable Remedies</b>
                                            <br /><br />
                                            You acknowledge that the rights granted and obligations made under these Terms of Use to Yeebet Gaming are of a unique irreplaceable nature, the loss of which shall irreparably harm Yeebet Gaming and which cannot be replaced by monetary damages alone so that Yeebet Gaming shall be entitled to injunctive or other equitable relief (without the obligations of posting any bond or surety or proof or damages) in the event of any breach or anticipatory breach by you.
                                            <br /><br />
                                            You irrevocably waive all rights to seek injunctive or other equitable relief, or to enjoin or restrain the operation of the Service or any Yeebet Gaming game, exploitation of any advertising or other materials issues in connection therewith, or exploitation of the Service or any content or other material used or displayed through the Service and agree to limit your claims to claims for monetary damages, limited by Section 6 (if any).
                                            <br /><br />
                                            <b>10.6 Force Majeure</b>
                                            <br /><br />
                                            Yeebet Gaming shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of Yeebet Gaming, including without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond Yeebet Gaming’ control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labour, or materials.
                                            <br /><br />
                                        </span> <br />
                                    </Col>
                                </Row>


                            </Col>
                        </Row>
                        {/* <div className="sideItem1Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem2.png').default} className="img-fluid" />
                        </div> */}
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default TermsCondition;
