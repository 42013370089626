import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import "./assets/css/custom.scss";
import AboutUs from "views/index-sections/AboutUs";
import OurBrands from "views/index-sections/OurBrands";
import ContactUs from "views/index-sections/ContactUs";
import News from "views/index-sections/News";
import OurGames from "views/index-sections/OurGames";
import YeebetLive from "views/index-sections/YeebetLive";
import OurPartner from "views/index-sections/OurPartner";
import PrivacyPolicy from "views/index-sections/PrivacyPolicy";
import TermsCondition from "views/index-sections/TermsCondition";
import CookiePolicy from "views/index-sections/CookiePolicy";
import NewsDetails from "views/index-sections/NewsDetails";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
        <Route path="/our-brands" render={(props) => <OurBrands {...props} />} />
        <Route path="/our-games" render={(props) => <OurGames {...props} />} />
        <Route path="/news" render={(props) => <News {...props} />} />
        <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
        <Route path="/yeebet-live" render={(props) => <YeebetLive {...props} />} />
        <Route path="/our-partner" render={(props) => <OurPartner {...props} />} />
        <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
        <Route path="/terms-condition" render={(props) => <TermsCondition {...props} />} />
        <Route path="/cookie-policy" render={(props) => <CookiePolicy {...props} />} />
        <Route path="/news-details/:category/:slug" render={(props) => <NewsDetails {...props} />} />

        <Redirect to="/index" />
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
