import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import "../../assets/css/privacyPolicy.scss";
import { Col, Row, Input } from "reactstrap";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { FaAngleRight } from 'react-icons/fa'

const career = [
    {
        position: 'Right to access your personal data',
        summaryOfDuties: "You may request at any time the confirmation whether we process your personal data. If we process your personal data, you can request to receive a copy of your personal data. For example: if you are Yeebet Gaming employee, you can request to receive information on your data processed and for what reasons.",
    },
    {
        position: 'Right to rectify your personal data',
        summaryOfDuties: "You have the right at any time request to correct your personal data that is inaccurate, as well as supplement your personal data that is incomplete. For example: if you are Yeebet Gaming business partner and your contact information such as telephone number or email address have been changed, you can request to edit this data.",
    },
    {
        position: 'Right to erase your personal data',
        summaryOfDuties: "You may request to erase your personal data under certain circumstances such as when your personal data is no longer needed for the purposes for which it was collected. For example: if you were Yeebet Gaming employee and you have provided your contact information for employment related communication purposes. When terminating employment relations, you can ask to delete your contact information unless it is requested to be stored otherwise by law.",
    },
    {
        position: 'Right to object to certain personal data processing',
        summaryOfDuties: "You may object to the processing of your personal data if you have a particular situation, your data are being used for direct marketing purposes and the legal grounds of data processing are legitimate interests. For example: If you receive any marketing related materials that you have not agreed to, you can object and request to stop receiving them.",
    },
    {
        position: 'Right to restrict the processing of personal data',
        summaryOfDuties: "You may ask us to restrict the processing of your personal data under certain circumstances, such as when the processing is unlawful, but you do not wish your personal data erased. Additionally, in case if you have concerns about your data accuracy, you can ask us to restrict data usage until we verify the accuracy of your data. For example: In you are Yeebet Gaming employee and you have concerns that your provided bank account data are not accurate, we will stop using this data until we will verify the accuracy of it.",
    },
    {
        position: 'Right to withdraw your consent',
        summaryOfDuties: "If you have agreed on data processing based on your consent, you have the right at any time withdraw your consent. The consent withdrawal will not affect the legality of the previous processing. For example: In case if you have agreed to receive newsletters from Yeebet Gaming and you would not like to receive them anymore. You can withdraw your consent at any time, and you won’t receive them in future.",
    },
    {
        position: "Right to transfer your personal data",
        summaryOfDuties: "You may ask to provide you the copy of your personal data and if technically possible you can request to transfer this data to another Data Controller (Company or Institution, where possible). This is only applicable if personal data are processed on the legal basis of your consent or on necessity to comply with agreement concluded with you, and only for the data that is provided by you. For example: if you are Yeebet Gaming employee you could ask to transfer your provided data to Public Revenue Service or any other Public Institution.",
    },
    {
        position: 'The right to complain to the data protection supervisory authority',
        summaryOfDuties: "You have the right to lodge complaints regarding your personal data processing to the relevant data protection supervisory authority. The list of national supervisory authorities can be found here: https://edpb.europa.eu/about-edpb/board/members_en",
    },
]


function PrivacyPolicy() {
    const [selectedContent, setSelectedContent] = React.useState(0);

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <IndexNavbar />

            <div className="" id="privacyPolicy">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">Privacy Policy</span>
                </div> */}

                <div className="privacyPolicyBg position-relative">
                    <div className="header-section position-relative py-5">
                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={10} md={10} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center text-center" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={11} xs={11} className="text-center text-lg-left">
                                        <span className="brandsDesc">
                                            Effective date: 16/12/2022
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">Introduction</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            This privacy policy (hereinafter “Policy”) applies to Yeebet Gaming Group (hereinafter “Yeebet Gaming”, “we”, “us”, or “Group”) websites and applications that links to this Policy. The Policy explains how we collect, share and use personal data, and how you can exercise your privacy rights. We recommend that you read this Policy in full to ensure you are fully informed.
                                            <br /><br />
                                            In case you have any questions regarding your personal data processing, you can contact our Data Protection Team at dataprivacy@yeebetgaming.com
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">How Do We Collect Your Personal Data?</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            When you are using Yeebet Gaming websites and social media accounts, Yeebet Gaming collects personal data from you either actively or passively.
                                            <br /><br />
                                            Personal data is actively collected from you when you voluntarily provide us with your personal data. For example, when you are applying for an open job position, building business relationships with us, communicating with us, or subscribing to newsletters, etc.
                                            <br /><br />
                                            Depending on your preferences and/or choices we might collect information from cookies and similar technologies. It might collect certain information about you, for example, IP address or online activity, some of which will constitute your personal data. You can find more information about the use of cookies in our Cookies privacy policy.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">What Personal Data Do We Collect And For What Purpose?</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            The personal data that we collect, and process might differ from the choices you make in the privacy settings while visiting our websites. It also depends upon what services, functionalities, or experiences you use, your location and applicable law.
                                            <br /><br />
                                            We will only process your personal data when we have a lawful basis for doing so. The lawful basis might be:
                                            <br /><br />
                                            a) you have agreed to data processing by giving your consent.<br />
                                            b) it is necessary to provide services to you under the performance of the contract we have with you.<br />
                                            c) we are required to do so in accordance with legal or regulatory obligations.<br />
                                            d) it is in our legitimate interests to process your personal data, if none of these interests prejudice your own rights, freedoms and interests.
                                            <br /><br />
                                            In the table below we indicate the business purposes where we may use your personal data:
                                        </span> <br />
                                    </Col>
                                </Row>

                                {/* table  */}

                                <Row className="justify-content-center align-items-center text-center py-4 tableBg" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={11} md={11} sm={11} xs={11} className="text-center">
                                        <table class="table table-bordered table-responsive text-white my-3">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "33.33%" }}>Purpose</th>
                                                    <th style={{ width: "33.33%" }}>Personal Data</th>
                                                    <th style={{ width: "33.33%" }}>Legal Basis</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>To communicate with you and handle your inquiries, requests, comments, and feedback etc.</td>
                                                    <td>First name, Last name, E-mail address, Feedback and comments made by you.</td>
                                                    <td>Legitimate interests to handle, adequately and in a timely manner, your’ inquiries, complaints, and suggestions regarding the services we provide in our sites.</td>
                                                </tr>
                                                <tr>
                                                    <td>To provide you with information such as Investor information, Newsletters, Press Releases etc.</td>
                                                    <td>First name, E-mail address, Phone number, Company name, Language settings.</td>
                                                    <td>Your freely given consent.</td>
                                                </tr>
                                                <tr>
                                                    <td>To inform you about our newly released products, events, or other promotional purposes.</td>
                                                    <td>First name, Last name, E-mail address.</td>
                                                    <td>Your freely given consent.</td>
                                                </tr>
                                                <tr>
                                                    <td>To establish, exercise and defend legal claims.</td>
                                                    <td>First name, Last name, E-mail address, Telephone number, Other information as necessary for the purpose.</td>
                                                    <td>Legitimate interests to defend ourselves in legal claims.</td>
                                                </tr>
                                                <tr>
                                                    <td>To permit you to participate in social sharing, including live social media feeds.</td>
                                                    <td>Social networks including your profile information, User ID associated with your social media account, and any other information you allow the social network to share with third parties.</td>
                                                    <td>Legitimate interests to provide you better services and permit you to participate in social sharing.</td>
                                                </tr>
                                                <tr>
                                                    <td>To allow participate in polls, lotteries, promotions, contests and to administer these activities.</td>
                                                    <td>First name, Last name, E-mail address, Telephone number, Other information as necessary for the purpose.</td>
                                                    <td>Your freely given consent.</td>
                                                </tr>
                                                <tr>
                                                    <td>To manage your work applications.</td>
                                                    <td>First name, Last name, E-mail address, Telephone, Information in your CV and other information related with purpose.</td>
                                                    <td>Your freely given consent.</td>
                                                </tr>
                                                <tr>
                                                    <td>To organize brand promotional campaigns, including job fairs.</td>
                                                    <td>First name, Last name, E-mail address, Telephone, Photography, and other information related with purpose.</td>
                                                    <td>Legitimate interests to promote Yeebet Gaming as brand.</td>
                                                </tr>
                                                <tr>
                                                    <td>To detect, prevent, or investigate potential security incidents or fraud.</td>
                                                    <td>IP address, Log data, Other data that is necessary for the purpose.</td>
                                                    <td>Legitimate interests to ensure security for our systems and services provided.</td>
                                                </tr>
                                                <tr>
                                                    <td>To perform analytics, quality control, market research, and determine the effectiveness of our websites, promotional campaigns, and develop new products and services.</td>
                                                    <td>IP address, Location, Device information, User activity.</td>
                                                    <td>Legitimate interests to perform marketing and research activities in order to provide you better service.</td>
                                                </tr>
                                                <tr>
                                                    <td>To perform due-diligence checks to fulfill our regulatory obligations.</td>
                                                    <td>First name, Last name, Company name, Company registration number, Other information as necessary for the purpose.</td>
                                                    <td>Necessary to comply with a legal or regulatory obligations.</td>
                                                </tr>
                                                <tr>
                                                    <td>To comply with our obligations under applicable laws (including gambling legislation, and regulatory and licensing requirements related.</td>
                                                    <td>First name, Last name, Company name, Other information as necessary for the purpose.</td>
                                                    <td>Necessary to comply with a legal or regulatory obligations.</td>
                                                </tr>
                                                <tr>
                                                    <td>To identify and disclose any suspected unlawful, fraudulent, or other improper activity connected with our websites, and services (including money laundering).</td>
                                                    <td>First name, Last name, Company name, Other information as necessary for the purpose.</td>
                                                    <td>Necessary to comply with a legal or regulatory obligations.</td>
                                                </tr>
                                                <tr>
                                                    <td>To share your personal data with other members of the Group entities who assist in providing the</td>
                                                    <td>First name, Last name, E-mail address, Telephone number, Company name, Other information as necessary for the purpose.</td>
                                                    <td>Legitimate interests to allow us to provide services.</td>
                                                </tr>
                                                <tr>
                                                    <td>To share your personal data with gambling regulators or other legally required regulators in order to assist and comply with legal requirements and any investigation if applicable.</td>
                                                    <td>First name, Last name, Address, Telephone number, Other information as necessary for the purpose.</td>
                                                    <td>Necessary for a legal or regulatory obligation.</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <Row className="justify-content-start align-items-center text-center">
                                            <Col lg={9} md={11} sm={11} xs={11} className="text-center">
                                                <div className="py-4 text-left">
                                                    <span className="brandsDesc" style={{ color: "#CAB48E" }}>
                                                        Please note that the processes and lawful basis set above are those who primarily apply, however this might change and be supplemented due to business operations and in order to comply with legal requirements.                                            </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">What Rights Do You Have?</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            You have several rights towards your personal data. You have rights to request access, rectify, erase, object, restrict personal data processing, withdraw consent if given and transfer your personal data. In addition, you have the right to contact Data Protection Supervisory authority in the country where you are located.
                                            <br /><br />
                                            We will respond to all individual requests wishing to exercise their data protection rights. We may request to verify your identity or ask for additional information in order to help us respond efficiently to your request and to protect personal data from unauthorized disclosure.
                                            <br /><br />
                                            In case you would like to exercise your rights related to our developed games, we kindly ask you to contact the Casino you played with, as we do not process information of players by which the person can be identified directly. This action is done by online casinos as they are Data Controllers of the players personal data.
                                            <br /><br />
                                            If you wish to exercise your data protection rights, please contact our Data Protection Team at dataprivacy@yeebetgaming.com.
                                            <br /><br />
                                            Please be informed that certain rights might not be fulfilled entirely due to legal or regulatory requirements.
                                        </span> <br />

                                        <Accordion preExpanded={[0]} allowZeroExpanded className="pt-4">
                                            {career.map((item, i) => (
                                                <AccordionItem key={i} uuid={i} className={i === selectedContent ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(i)}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <div className="row justify-content-center align-items-center">
                                                                <div className="col-10">
                                                                    <div className="">
                                                                        <span className={i === selectedContent ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                            {item.position}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="">
                                                                        <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: i === selectedContent ? "#CAB48E" : "#fff" }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <div className="row justify-content-start align-items-center">
                                                            <div className="col-12">
                                                                <span className="accordionContent">
                                                                    {item.summaryOfDuties}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </Col>
                                </Row>


                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">Where May We Disclose And Transfer Your Personal Data?</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Since Yeebet Gaming operates worldwide your personal data might be shared within Yeebet Gaming Group and/or contracted third parties for various business purposes. The personal data might be transferred based on legal grounds such as legitimate interests to fulfill the services agreed or based on mutually concluded contractual obligations.
                                            <br /><br />
                                            Additionally, there might be situations where Yeebet Gaming might transfer personal data to Yeebet Gaming Group entities or external parties located outside the EU/EEA. In such a case Yeebet Gaming will ensure that the Standard Contractual Clauses or other legally required documentations are concluded to ensure an adequate level of protection before transferring personal data outside EU/EEA.
                                            <br /><br />
                                            Please note, that for all personal data transfers an adequate security level is ensured, and all legal requirements are met.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">Yeebet Gaming Group Entities</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            In order to fulfill data processing purposes Yeebet Gaming may disclose your personal data to other Yeebet Gaming Group entities. The data transfers between Yeebet Gaming entities are governed with concluded Intra-Group data processing agreement which includes the Standard Contractual Clauses.
                                            <br /><br />
                                            Please note, that other legal safeguards for data transfer might apply in certain cases.<br />
                                            The data transfer purposes may be:
                                            <br /><br />
                                            <ul className="text-left">
                                                <li>
                                                    To answer various of your requests and questions regarding our provided services (based on necessary to comply with a legal/regulatory requirements).
                                                </li>
                                                <li>
                                                    To better understand our business practices, services, and products across the Yeebet Gaming Group entities (based on legitimate interests).
                                                </li>
                                                <li>
                                                    To provide Yeebet Gaming Group entities with marketing and promotional materials (based on your provided consent).
                                                </li>
                                                <li>
                                                    To identify fraud and other criminal activities including anti money-laundering (based on necessary to comply with a legal/regulatory requirement).
                                                </li>
                                                <li>
                                                    To provide you with the customer support including the obligation to ensure that information is up-to-date and accurate (based on necessary to comply with a legal/regulatory obligation).
                                                </li>
                                                <li>
                                                    To comply with legal requirements towards identification of responsible gaming measures across the Yeebet Gaming Group entities (based on necessary to comply with a legal/regulatory requirement).
                                                </li>
                                                <li>
                                                    To provide you services agreed, ensuring the implementation of security measures (based on necessary to comply with a legal/regulatory requirement).
                                                </li>
                                            </ul>
                                            <br />
                                            In case you have any questions regarding data transfer between the Yeebet Gaming Group entities, please contact our Data Protection Team at dataprivacy@yeebetgaming.com.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">Data Processors Acting On Behalf Of Yeebet Gaming</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Yeebet Gaming may disclose personal data to external parties, such as vendors and service providers. Third parties might process personal data on behalf of and under the instructions of Yeebet Gaming.
                                            <br /><br />
                                            The Yeebet Gaming engages with data processors (incl. sub-processors) in order to deliver you the agreed services and to comply with legal and regulatory requirements. The data processors might vary from such as cloud services for data storage purposes, marketing companies in preparing and delivering marketing related materials, until external advisors and auditors. In case of engagement of processors, we inform you as far as it is required by legal and regulatory requirements.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">What Are The Security Measures Implemented?</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            We ensure that all necessary appropriate security measures are in place to keep and process your personal data securely. In support of this commitment, we have implemented appropriate technical, physical, and organizational measures to protect your personal data against unauthorized or accidental destruction, alteration or disclosure, misuse, damage, theft or accidental loss, or unauthorized access.
                                            <br /><br />
                                            For more details, please contact us at dataprivacy@yeebetgaming.com.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">What Is The Data Retention Period?</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            The personal data are kept for period that is either required by law and regulatory requirements or for as long as necessary to fulfill the personal data collection purpose. The storage period might differ from the location and services that you are receiving.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">What Is The Controller And What Is Our Contact Information?</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            If you have any questions or concerns regarding the processing of your personal data regarding of websites or applications, please contact Data Protection Team at dataprivacy@yeebetgaming.com.
                                            <br /><br />
                                            <b>Data Controller:</b><br />
                                            <b>Yeebet Gaming Gaming Ltd.</b><br />
                                            Registration No. 05943966,<br />
                                            Sackwille House, 55 Picadilly, W1J 0DR, London, United Kingdom.<br />
                                        </span> <br />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* <div className="sideItem1Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem2.png').default} className="img-fluid" />
                        </div> */}
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default PrivacyPolicy;
