import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import MainNavbar from "components/Navbars/MainNavbar";
import "../../assets/css/games.scss";
import { Col, Row } from "reactstrap";
import Modal from 'react-modal';
import { MenuItem, Select } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Prev from "../../assets/img/ourGames/prevArrow.png";
import Next from "../../assets/img/ourGames/nextArrow.png";
import Fullpage, { FullPageSections, FullpageSection } from '@ap.cx/react-fullpage';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import ld_game1 from "../../assets/img/ourGames/liveDealer/game1.png";
import ld_game2 from "../../assets/img/ourGames/liveDealer/game2.png";
import ld_game3 from "../../assets/img/ourGames/liveDealer/game3.png";
import ld_game4 from "../../assets/img/ourGames/liveDealer/game4.png";
import ld_game5 from "../../assets/img/ourGames/liveDealer/game5.png";
import ld_game6 from "../../assets/img/ourGames/liveDealer/game6.png";
import ld_game7 from "../../assets/img/ourGames/liveDealer/game7.png";
import ld_game8 from "../../assets/img/ourGames/liveDealer/game8.png";
import ld_game9 from "../../assets/img/ourGames/liveDealer/game9.png";

import slot_game1 from "../../assets/img/ourGames/slots/game1.png";
import slot_game2 from "../../assets/img/ourGames/slots/game2.png";
import slot_game3 from "../../assets/img/ourGames/slots/game3.png";
import slot_game4 from "../../assets/img/ourGames/slots/game4.png";
import slot_game5 from "../../assets/img/ourGames/slots/game5.png";
import slot_game6 from "../../assets/img/ourGames/slots/game6.png";
import slot_game7 from "../../assets/img/ourGames/slots/game7.png";
import slot_game8 from "../../assets/img/ourGames/slots/game8.png";
import slot_game9 from "../../assets/img/ourGames/slots/game9.png";
import slot_game10 from "../../assets/img/ourGames/slots/game10.png";
import slot_game11 from "../../assets/img/ourGames/slots/game11.png";
import slot_game12 from "../../assets/img/ourGames/slots/game12.png";
import slot_game13 from "../../assets/img/ourGames/slots/game13.png";
import slot_game14 from "../../assets/img/ourGames/slots/game14.png";
import slot_game15 from "../../assets/img/ourGames/slots/game15.png";
import slot_game16 from "../../assets/img/ourGames/slots/game16.png";
import slot_game17 from "../../assets/img/ourGames/slots/game17.png";
import slot_game18 from "../../assets/img/ourGames/slots/game18.png";
import slot_game19 from "../../assets/img/ourGames/slots/game19.png";
import slot_game20 from "../../assets/img/ourGames/slots/game20.png";
import slot_game21 from "../../assets/img/ourGames/slots/game21.png";
import slot_game22 from "../../assets/img/ourGames/slots/game22.png";
import slot_game23 from "../../assets/img/ourGames/slots/game23.png";
import slot_game24 from "../../assets/img/ourGames/slots/game24.png";
import slot_game25 from "../../assets/img/ourGames/slots/game25.png";
import slot_game26 from "../../assets/img/ourGames/slots/game26.png";
import slot_game27 from "../../assets/img/ourGames/slots/game27.png";
import slot_game28 from "../../assets/img/ourGames/slots/game28.png";
import slot_game29 from "../../assets/img/ourGames/slots/game29.png";
import slot_game30 from "../../assets/img/ourGames/slots/game30.png";
import slot_game31 from "../../assets/img/ourGames/slots/game31.png";
import slot_game32 from "../../assets/img/ourGames/slots/game32.png";
import slot_game33 from "../../assets/img/ourGames/slots/game33.png";
import slot_game34 from "../../assets/img/ourGames/slots/game34.png";
import slot_game35 from "../../assets/img/ourGames/slots/game35.png";
import slot_game36 from "../../assets/img/ourGames/slots/game36.png";
import slot_game37 from "../../assets/img/ourGames/slots/game37.png";
import slot_game38 from "../../assets/img/ourGames/slots/game38.png";
import slot_game39 from "../../assets/img/ourGames/slots/game39.png";

import sports_game1 from "../../assets/img/ourGames/sports/game1.png";
import sports_game2 from "../../assets/img/ourGames/sports/game2.png";
import sports_game3 from "../../assets/img/ourGames/sports/game3.png";
import { FaTimes } from "react-icons/fa";

const liveDealer_Array = [
    { title: "" },
    { title: "" },
    { img: ld_game1, title: "Speed Baccarat", short: "Fast check in 15 seconds to 18 seconds with high return", long: "Live Speed Baccarat is the super-fast alternative to our standard Baccarat game and its many variations for players looking for maximum betting opportunities. A standard Baccarat game round lasts 30 seconds, but Live Speed Baccarat offers a frantic pace. Cards are dealt face-up, the results time is kept to a minimum, and each round is completed with plenty of suspense." },
    { img: ld_game2, title: "Classis Baccarat", short: "Slow check in 30 seconds with lower return.", long: "There's no better way for players to experience Asia's most popular casino game than with our new and improved Live Classic Baccarat.There are also special bets, such as Pairs side bets, extra statistics viewable through a wide variety of roads, and players can see what other players are doing. This creates an authentic and dynamic gaming experience for your players by enriching the playing experience." },
    { img: ld_game3, title: "Baccarat Multi-play", short: "Bet all you can in one screen.", long: "Our new, simple user interface tool, Baccarat Multi-play, allows players to place bets at all our Live Baccarat tables at the same time, without joining each table individually. Videos, a small version of the betting grid, and the player's choice of road are displayed on all Baccarat tables. With a click of a button, the player can sort all the tables according to the longest streak for his selected road." },
    { img: ld_game4, title: "Auto Roulette", short: "Spinning non stop like your money cash in.", long: "Auto Roulette is a type of casino game that is played on a machine, rather than with a live dealer. The game is based on the European style of Roulette, and uses a Random Number Generator (RNG) to determine the outcome of each spin. Players can bet on any number or combination of numbers, and can also place special bets such as red/black or odd/even. Auto Roulette games typically have a higher house edge than live dealer games, but can be faster and more convenient to play." },
    { img: ld_game5, title: "Europe Roulette", short: "Spin Till You Hit The Number", long: "European/Single Zero Roulette has become one of the most widely popular games around the globe, both online and in land-based casinos. The excitement starts as gamers predict which pocket on the wheel the ball will drop into after its rotation along the outer ring. With Yeebet Gaming Group, there's an elaborate selection of wagers such as numbers, columns and colors. You can also take advantage of various features like bet statistics, auto bet, favorite bets and a live chat! It's exhilarating to be able to pick your dealer and share your game with other players!" },
    { img: ld_game6, title: "Dragon Tiger", short: "Either Dragon or Tiger.", long: "Dragon Tiger, often compared to Baccarat, has proved to be a lucrative game due to its fame among players from Asia and increasing recognition globally. It's easy to understand why it appeals so much - it's simple and runs quickly, with good odds. This makes it particularly attractive for high rollers and die-hard bettors. Exquisite user interface is another bonus that adds to the game's desirability in the market." },
    { img: ld_game7, title: "Blackjack", short: "Calculate it & Win", long: "The card game known today as Blackjack can be traced back to the 18th century, when it was called Vingt-Un ('Twenty-One'). In the early 20th century, casinos across the world enticed players to participate by offering them bonuses for certain winning combinations. If a player managed to draw a ‘Blackjack (Jack of spades or clubs)’, along with an Ace of Spades, they were generously rewarded. This bonus has since been discontinued, although the name ‘Blackjack’ is still used. At Yeebet Gaming, we strive to provide players with the ideal platform that ensures their gaming experience is as smooth and quick as possible. Our selection of tables and seats means that both new and experienced players can practice and develop their skill sets; while our professional dealers create an immersive atmosphere akin to being in the heart of Vegas itself. Additionally, operators are provided multiple bet-on features and side bets to maximize their profitability." },
    { img: ld_game8, title: "Teen Patti", short: "", long: "Teen Patti is a typical Indian game that is played with three cards. The game is also known as flash or flush, and is similar to the western game of poker. In Teen Patti, each player is dealt three cards face down, and the objective is to create the best hand possible using those three cards. The player with the best hand wins the pot. \n\n The game can be played with anywhere from two to eight players. If there are more than four players, it is common for there to be two pots - one for the best hand, and one for the worst hand. \n\n The betting in Teen Patti starts with the player to the left of the dealer, and each subsequent player can either bet or fold. Once all players have had a chance to bet or fold, the dealer will then reveal their cards. If there are any players still in the pot at this point, they will show their hands and the best hand will win the pot." },
    { img: ld_game9, title: "Andar Bahar", short: "", long: "Andar Bahar is a popular game that is often played in India. The game is simple to learn and can be played with any number of people. All that is needed is a deck of cards and some players. The object of the game is to guess which card will be the next to appear from the deck. \n\n To begin, one person is chosen to be the dealer. The dealer shuffles the cards and then places them in a line face down. The player to the dealers left starts the game by guessing whether the next card will be higher or lower than the card currently at the start of the line. If they guess correctly, they move the card to their side and become the new dealer. If they guess incorrectly, they lose their turn and play passes to the next player. The game continues until all of the cards have been guessed correctly, at which point the player with the most cards on their side wins." },
    { title: "" },
    { title: "" },
]

const slots_Array = [
    { img: "" },
    { img: "" },
    { img: slot_game1 },
    { img: slot_game2 },
    { img: slot_game3 },
    { img: slot_game4 },
    { img: slot_game5 },
    { img: slot_game6 },
    { img: slot_game7 },
    { img: slot_game8 },
    { img: slot_game9 },
    { img: slot_game10 },
    { img: slot_game11 },
    { img: slot_game12 },
    { img: slot_game13 },
    { img: slot_game14 },
    { img: slot_game15 },
    { img: slot_game16 },
    { img: slot_game17 },
    { img: slot_game18 },
    { img: slot_game19 },
    { img: slot_game20 },
    { img: slot_game21 },
    { img: slot_game22 },
    { img: slot_game23 },
    { img: slot_game24 },
    { img: slot_game25 },
    { img: slot_game26 },
    { img: slot_game27 },
    { img: slot_game28 },
    { img: slot_game29 },
    { img: slot_game30 },
    { img: slot_game31 },
    { img: slot_game32 },
    { img: slot_game33 },
    { img: slot_game34 },
    { img: slot_game35 },
    { img: slot_game36 },
    { img: slot_game37 },
    { img: slot_game38 },
    { img: slot_game39 },
    { img: "" },
    { img: "" },
]

const sports_Array = [
    { img: sports_game1 },
    { img: sports_game2 },
    { img: sports_game3 },
]

const customStyles = {
    content: {
        border: '0',
        bottom: 'auto',
        left: '50%',
        position: 'fixed',
        zIndex: 999999,
        right: 'auto',
        top: '50%', // start from center
        transform: 'translate(-50%, -50%)', // adjust top "up" based on height
        width: window.innerWidth > 992 ? '60%' : window.innerWidth > 667 ? '90%' : '100%',
        background: "#000",
        padding: '0'
    },
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        zIndex: 9999
    },
};


function OurGames() {
    const [selectedGameCategory, setGameCategory] = React.useState(0);
    const [selectedGameDetails, setSelectedGameDetails] = React.useState(-1);
    const [openModal2, setOpenModal2] = React.useState(false);

    const NextArrow = ({ onClick }) => {
        return (
            <div className="arrow Wnext" id="nextArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <img src={Next} style={{ width: 40, height: 40 }} />
            </div>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <div className="arrow Wprev" id="prevArrow" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <img src={Prev} style={{ width: 40, height: 40 }} />
            </div>
        );
    };

    const NextArrow2 = ({ onClick }) => {
        return (
            <div className="arrow Wnext" id="nextArrow2" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <img src={Next} style={{ width: 40, height: 40 }} />
            </div>
        );
    };

    const PrevArrow2 = ({ onClick }) => {
        return (
            <div className="arrow Wprev" id="prevArrow2" onClick={onClick} style={{ backgroundColor: "transparent" }}>
                <img src={Prev} style={{ width: 40, height: 40 }} />
            </div>
        );
    };

    const [imageIndex, setImageIndex] = React.useState(0);
    const [imageIndex2, setImageIndex2] = React.useState(0);

    const settings = {
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        speed: 300,
        swipe: false,
        slidesToShow: 4.5,
        centerMode: false,
        centerPadding: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: (current, next) => { setImageIndex(next) },
    };

    const settings2 = {
        dots: false,
        infinite: false,
        lazyLoad: 'ondemand',
        initOnLoad: true,
        nextSlidesToPreload: 1,
        autoplay: false,
        swipe: false,
        speed: 300,
        slidesToShow: 4.5,
        centerMode: false,
        centerPadding: 0,
        nextArrow: <NextArrow2 />,
        prevArrow: <PrevArrow2 />,
        beforeChange: (current, next) => { setImageIndex2(next) },
    };

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    React.useEffect(() => {
        if (selectedGameDetails === -1) {
            return;
        }
        setOpenModal2(true);
    }, [selectedGameDetails]);

    React.useEffect(() => {
        if (selectedGameCategory === 0) {
            if (imageIndex === 0) {
                document.getElementById("prevArrow").style.display = "none";
            }

            if (imageIndex === 8.5) {
                document.getElementById("nextArrow").style.display = "none";
            }
        }
    }, [imageIndex, selectedGameCategory]);

    React.useEffect(() => {
        if (selectedGameCategory === 1) {
            if (imageIndex2 === 0) {
                document.getElementById("prevArrow2").style.display = "none";
            }

            if (imageIndex2 === 37.5) {
                document.getElementById("nextArrow2").style.display = "none";
            }
        }
    }, [imageIndex2, selectedGameCategory]);



    return (
        <>
            <MainNavbar />

            <div className="" id="games">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">Our Games</span>
                </div> */}

                <div className="d-none d-lg-block">
                    <Fullpage>
                        <FullPageSections>
                            <FullpageSection className="fullPageHeight">
                                <div className="header-section position-relative">
                                    <Row className="justify-content-center align-items-center text-center">
                                        <Col lg={10} md={10} sm={11} xs={11}>
                                            <Row className="justify-content-center align-items-center text-center">
                                                <Col lg={4} md={6} sm={8} xs={8}>
                                                    <img src={require('../../assets/img/aboutUs/header-logo.png').default} className="img-fluid" />
                                                </Col>
                                                <Col lg={5} md={9} sm={11} xs={11} className="text-center text-lg-left">
                                                    <span className="headerTitle">Game Products</span> <br />
                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                                    <span className="brandsDesc">
                                                        Thousands of casino games from MetaSoft and other major suppliers to extend your offer. Engage players and keep them coming back for more.
                                                    </span> <br />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </FullpageSection>
                            <FullpageSection className="fullPageHeight">
                                <div className="live-dealer position-relative">
                                    <div className="row justify-content-center align-items-center vh-100" style={{ position: "sticky", zIndex: "10" }}>
                                        <div className="col-12 col-lg-10 pl-r pr-0">
                                            <div className="mx-auto">
                                                <div className="row justify-content-center align-items-start">
                                                    <div className="col-12 col-md-11 col-lg-9 pl-0 pr-0">
                                                        <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                                                            <Row className="justify-content-center align-items-center text-center pt-3">
                                                                <Col lg={11} md={11} sm={11} xs={11}>
                                                                    <span className="headerTitle">Live Dealer</span> <br />
                                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /><br />
                                                                    <div className="mt-3">
                                                                        <span className="brandsDesc">Yeebet Live offer comes in 3 main solutions: Yeebet API, Yeebet Dedicated Tables Solution & Yeebet Feed Solution. Business growth opportunities go hand in hand with the excitement of Live Dealer Games.</span> <br />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                                                            <Row className="justify-content-center align-items-center text-center pt-3">
                                                                <Col lg={11} md={11} sm={11} xs={11}>
                                                                    <span className="headerTitle">Slots</span> <br />
                                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /><br />
                                                                    <div className="mt-3">
                                                                        <span className="brandsDesc">Yeebet Gaming Group ‘s Metasoft provides an expansive portfolio of slot games, boasting a total of more than 500 innovative in-house and branded titles. These include fan favourite such as Father of Olympus, Four Seasons Dragons, The Silver Tiger, Jungle Diary, Queen Of The South & Candy Rush, through to exclusive content like Lady Of Kyoto and Lady Valkyrie. </span> <br />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                                                            <Row className="justify-content-center align-items-center text-center pt-3">
                                                                <Col lg={11} md={11} sm={11} xs={11}>
                                                                    <span className="headerTitle">Sports</span> <br />
                                                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /><br />
                                                                    <div className="mt-3">
                                                                        <span className="brandsDesc">With all-in-one sports betting software, you can offer over 70K live matches and 140K pre-match events each month. 120+ sports types: from football betting to tennis to volleyball and hockey.</span> <br />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-11 col-lg-9">
                                                        <Row className="justify-content-center align-items-center pt-4 text-center mb-3 mb-lg-0">
                                                            <Col lg={3} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                                                <div className={selectedGameCategory === 0 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(0)}>
                                                                    <span>Live Casino</span>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                                                <div className={selectedGameCategory === 1 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(1)}>
                                                                    <span>Slots</span>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                                                <div className={selectedGameCategory === 2 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(2)}>
                                                                    <span>Sports</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="col-12" >
                                                        <div className="d-none d-lg-block">
                                                            <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                                                                <Row className="justify-content-center align-items-center text-left pt-3">
                                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                                        <Slider {...settings}>
                                                                            {liveDealer_Array.map((item, i) => (
                                                                                item.title !== "" ?
                                                                                    <div>
                                                                                        <div className="gameList cursor-pointer p-2">
                                                                                            <div className="position-relative" onClick={() => setSelectedGameDetails(i)}>
                                                                                                <img src={item.img} className="w-100 h-100" />
                                                                                                <div className="position-absolute hoverLayer w-100 h-100">
                                                                                                    <Row className="justify-content-center align-items-center text-center py-5" style={{ height: "100%", overflowY: "auto" }}>
                                                                                                        <Col lg={10} md={11} sm={11} xs={11}>
                                                                                                            <div className="expandedDesc" style={{ height: "auto" }}>
                                                                                                                <span className="learnMoreText">
                                                                                                Learn More >
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className={item.title !== "" ? "gameDetailsBox pt-4 px-4" : ""}>
                                                                                                <div className="text-left">
                                                                                                    <span className="gameDetailsTitle">{item.title}</span> <br />
                                                                                                    <span className="gameDetailsShort">{item.short}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div />
                                                                            ))}
                                                                        </Slider>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                                                                <Row className="justify-content-center align-items-center text-left pt-5">
                                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                                        <Slider {...settings2}>
                                                                            {slots_Array.map((item, i) => (
                                                                                item.img !== "" ?
                                                                                    <div>
                                                                                        <div className="gameList p-2">
                                                                                            <div className="position-relative">
                                                                                                <img src={item.img} className="w-100 h-100" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : <div />
                                                                            ))}
                                                                        </Slider>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                                                                <Row className="justify-content-center align-items-center text-left pt-5">
                                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                                        <Row className="justify-content-center align-items-start text-center">
                                                                            {
                                                                                sports_Array.map((item, i) => (
                                                                                    <Col lg={3} md={6} sm={12} xs={12} key={i} className="text-center mb-5 px-2" style={{ position: "sticky", zIndex: "10" }}>
                                                                                        <div className="position-relative gameList cursor-pointer">
                                                                                            <img src={item.img} className="w-100 h-100" />
                                                                                        </div>
                                                                                    </Col>
                                                                                ))
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>

                                                        <div className="d-block d-lg-none">
                                                            <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                                                                <Row className="justify-content-center align-items-center text-left pt-2">
                                                                    {liveDealer_Array.map((item, i) => (
                                                                        item.title !== "" ?
                                                                            <Col lg={3} md={4} sm={12} xs={12} className="paddingNarrow">
                                                                                <div className="gameList cursor-pointer pb-4 pb-md-2">
                                                                                    <div className="position-relative" onClick={() => setSelectedGameDetails(i)}>
                                                                                        <img src={item.img} className="w-100 h-100" />
                                                                                        <div className="position-absolute hoverLayer w-100 h-100">
                                                                                            <Row className="justify-content-center align-items-center text-center py-5" style={{ height: "100%", overflowY: "auto" }}>
                                                                                                <Col lg={10} md={11} sm={11} xs={11}>
                                                                                                    <div className="expandedDesc" style={{ height: "auto" }}>
                                                                                                        <span className="learnMoreText">
                                                                                                Learn More >
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className={item.title !== "" ? "gameDetailsBox pt-4 px-4" : ""}>
                                                                                        <div className="text-left">
                                                                                            <span className="gameDetailsTitle">{item.title}</span> <br />
                                                                                            <span className="gameDetailsShort">{item.short}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            : ""
                                                                    ))}
                                                                </Row>
                                                            </div>

                                                            <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                                                                <Row className="justify-content-center align-items-center text-left pt-2">
                                                                    {slots_Array.map((item, i) => (
                                                                        item.img !== "" ?
                                                                            <Col lg={3} md={3} sm={6} xs={6} className="paddingNarrow">
                                                                                <div className="gameList cursor-pointer pb-2 pb-md-2">
                                                                                    <div className="position-relative">
                                                                                        <img src={item.img} className="w-100 h-100" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            : ""
                                                                    ))}
                                                                </Row>
                                                            </div>

                                                            <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                                                                <Row className="justify-content-center align-items-center text-left pt-2">
                                                                    {
                                                                        sports_Array.map((item, i) => (
                                                                            <Col lg={3} md={3} sm={6} xs={6} className="paddingNarrow">
                                                                                <div className="gameList cursor-pointer pb-2 pb-md-2">
                                                                                    <div className="position-relative">
                                                                                        <img src={item.img} className="w-100 h-100" />
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        ))
                                                                    }

                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="sideItem1Pos-games">
                                        <img src={require('../../assets/img/ourGames/sideItem1.png').default} className="img-fluid" />
                                    </div>

                                    <div className="sideItem2Pos-games">
                                        <img src={require('../../assets/img/ourGames/sideItem2.png').default} className="img-fluid" />
                                    </div>

                                    <div className="sideItem3Pos-games">
                                        <img src={require('../../assets/img/ourGames/sideItem4.png').default} className="img-fluid" />
                                    </div>
                                </div>
                            </FullpageSection>
                        </FullPageSections>
                    </Fullpage>
                </div>

                <div className="d-block d-lg-none">
                    <div className="header-section position-relative">
                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={10} md={10} sm={11} xs={11}>
                                <Row className="justify-content-center align-items-center text-center mb-5 pb-5">
                                    <Col lg={4} md={6} sm={8} xs={8}>
                                        <img src={require('../../assets/img/aboutUs/header-logo.png').default} className="img-fluid" />
                                    </Col>
                                    <Col lg={5} md={9} sm={11} xs={11} className="text-center text-lg-left">
                                        <span className="headerTitle">Game Products</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Thousands of casino games from MetaSoft and other major suppliers to extend your offer. Engage players and keep them coming back for more.
                                        </span> <br />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <div className="live-dealer position-relative py-5">
                        <div className="row justify-content-center align-items-center py-5" style={{ position: "sticky", zIndex: "10" }}>
                            <div className="col-12 col-lg-10 pl-r pr-0">
                                <div className="mx-auto">
                                    <div className="row justify-content-center align-items-start">
                                        <div className="col-12 col-md-11 col-lg-9 pl-0 pr-0">
                                            <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                                                <Row className="justify-content-center align-items-center text-center pt-5">
                                                    <Col lg={11} md={11} sm={11} xs={11}>
                                                        <span className="headerTitle">Live Dealer</span> <br />
                                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /><br />
                                                        <div className="mt-3">
                                                            <span className="brandsDesc">Yeebet Live offer comes in 3 main solutions: Yeebet API, Yeebet Dedicated Tables Solution & Yeebet Feed Solution. Business growth opportunities go hand in hand with the excitement of Live Dealer Games.</span> <br />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                                                <Row className="justify-content-center align-items-center text-center pt-5">
                                                    <Col lg={11} md={11} sm={11} xs={11}>
                                                        <span className="headerTitle">Slots</span> <br />
                                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /><br />
                                                        <div className="mt-3">
                                                            <span className="brandsDesc">Yeebet Gaming Group ‘s Metasoft provides an expansive portfolio of slot games, boasting a total of more than 500 innovative in-house and branded titles. These include fan favourite such as Father of Olympus, Four Seasons Dragons, The Silver Tiger, Jungle Diary, Queen Of The South & Candy Rush, through to exclusive content like Lady Of Kyoto and Lady Valkyrie. </span> <br />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                                                <Row className="justify-content-center align-items-center text-center pt-5">
                                                    <Col lg={11} md={11} sm={11} xs={11}>
                                                        <span className="headerTitle">Sports</span> <br />
                                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /><br />
                                                        <div className="mt-3">
                                                            <span className="brandsDesc">With all-in-one sports betting software, you can offer over 70K live matches and 140K pre-match events each month. 120+ sports types: from football betting to tennis to volleyball and hockey.</span> <br />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-11 col-lg-9">
                                            <Row className="justify-content-center align-items-center pt-4 text-center mb-3 mb-lg-0">
                                                <Col lg={3} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                                    <div className={selectedGameCategory === 0 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(0)}>
                                                        <span>Live Casino</span>
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                                    <div className={selectedGameCategory === 1 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(1)}>
                                                        <span>Slots</span>
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={4} sm={4} xs={4} className="mb-3 paddingNarrow">
                                                    <div className={selectedGameCategory === 2 ? "gameCategoryActiveTab py-2" : "gameCategoryTab py-2"} onClick={() => setGameCategory(2)}>
                                                        <span>Sports</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="col-12" >
                                            <div className="d-none d-lg-block">
                                                <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                                                    <Row className="justify-content-center align-items-center text-left pt-5">
                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                            <Slider {...settings}>
                                                                {liveDealer_Array.map((item, i) => (
                                                                    item.title !== "" ?
                                                                        <div>
                                                                            <div className="gameList cursor-pointer p-2">
                                                                                <div className="position-relative" onClick={() => setSelectedGameDetails(i)}>
                                                                                    <img src={item.img} className="w-100 h-100" />
                                                                                    <div className="position-absolute hoverLayer w-100 h-100">
                                                                                        <Row className="justify-content-center align-items-center text-center py-5" style={{ height: "100%", overflowY: "auto" }}>
                                                                                            <Col lg={10} md={11} sm={11} xs={11}>
                                                                                                <div className="expandedDesc" style={{ height: "auto" }}>
                                                                                                    <span className="learnMoreText">
                                                                                                Learn More >
                                                                                                    </span>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                </div>

                                                                                <div className={item.title !== "" ? "gameDetailsBox pt-4 px-4" : ""}>
                                                                                    <div className="text-left">
                                                                                        <span className="gameDetailsTitle">{item.title}</span> <br />
                                                                                        <span className="gameDetailsShort">{item.short}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : <div />
                                                                ))}
                                                            </Slider>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                                                    <Row className="justify-content-center align-items-center text-left pt-5">
                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                            <Slider {...settings2}>
                                                                {slots_Array.map((item, i) => (
                                                                    item.img !== "" ?
                                                                        <div>
                                                                            <div className="gameList p-2">
                                                                                <div className="position-relative">
                                                                                    <img src={item.img} className="w-100 h-100" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : <div />
                                                                ))}
                                                            </Slider>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                                                    <Row className="justify-content-center align-items-center text-left pt-5">
                                                        <Col lg={12} md={12} sm={12} xs={12}>
                                                            <Row className="justify-content-center align-items-start text-center">
                                                                {
                                                                    sports_Array.map((item, i) => (
                                                                        <Col lg={3} md={6} sm={12} xs={12} key={i} className="text-center mb-5 px-2" style={{ position: "sticky", zIndex: "10" }}>
                                                                            <div className="position-relative gameList cursor-pointer">
                                                                                <img src={item.img} className="w-100 h-100" />
                                                                            </div>
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                            <div className="d-block d-lg-none">
                                                <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                                                    <Row className="justify-content-center align-items-center text-left pt-2">
                                                        {liveDealer_Array.map((item, i) => (
                                                            item.title !== "" ?
                                                                <Col lg={3} md={4} sm={12} xs={12} className="paddingNarrow">
                                                                    <div className="gameList cursor-pointer pb-4 pb-md-2">
                                                                        <div className="position-relative" onClick={() => setSelectedGameDetails(i)}>
                                                                            <img src={item.img} className="w-100 h-100" />
                                                                            <div className="position-absolute hoverLayer w-100 h-100">
                                                                                <Row className="justify-content-center align-items-center text-center py-5" style={{ height: "100%", overflowY: "auto" }}>
                                                                                    <Col lg={10} md={11} sm={11} xs={11}>
                                                                                        <div className="expandedDesc" style={{ height: "auto" }}>
                                                                                            <span className="learnMoreText">
                                                                                                Learn More >
                                                                                            </span>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </div>

                                                                        <div className={item.title !== "" ? "gameDetailsBox pt-4 px-4" : ""}>
                                                                            <div className="text-left">
                                                                                <span className="gameDetailsTitle">{item.title}</span> <br />
                                                                                <span className="gameDetailsShort">{item.short}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                : ""
                                                        ))}
                                                    </Row>
                                                </div>

                                                <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                                                    <Row className="justify-content-center align-items-center text-left pt-2">
                                                        {slots_Array.map((item, i) => (
                                                            item.img !== "" ?
                                                                <Col lg={3} md={3} sm={6} xs={6} className="paddingNarrow">
                                                                    <div className="gameList cursor-pointer pb-2 pb-md-2">
                                                                        <div className="position-relative">
                                                                            <img src={item.img} className="w-100 h-100" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                : ""
                                                        ))}
                                                    </Row>
                                                </div>

                                                <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                                                    <Row className="justify-content-center align-items-center text-left pt-2">
                                                        {
                                                            sports_Array.map((item, i) => (
                                                                <Col lg={3} md={3} sm={6} xs={6} className="paddingNarrow">
                                                                    <div className="gameList cursor-pointer pb-2 pb-md-2">
                                                                        <div className="position-relative">
                                                                            <img src={item.img} className="w-100 h-100" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ))
                                                        }

                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sideItem1Pos-games">
                            <img src={require('../../assets/img/ourGames/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-games">
                            <img src={require('../../assets/img/ourGames/sideItem2.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem3Pos-games">
                            <img src={require('../../assets/img/ourGames/sideItem4.png').default} className="img-fluid" />
                        </div>
                    </div>

                    {/* <div className="live-dealer position-relative py-5">
                    <div className="row justify-content-center align-items-center py-5" style={{ position: "sticky", zIndex: "10" }}>
                        <div className="col-12">
                            <div className="mx-auto" style={{ maxWidth: "1300px" }}>
                                <div className="row justify-content-center align-items-start gameListTableBg">
                                    <div className="col-9 col-md-3">
                                        <div className="d-none d-md-block">
                                            <div className="d-flex justify-content-start align-items-center py-3 gameCatBorder pl-0 pl-lg-4 cursor-pointer" onClick={() => setGameCategory(0)}>
                                                <img src={require('../../assets/img/ourGames/icon-liveDealer.png').default} className="img-fluid iconSize" />
                                                <div className="ml-3">
                                                    <span className="categoryTabTitle font-weight-bold text-white">Live Dealer</span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-start align-items-center py-3 gameCatBorder pl-0 pl-lg-4 cursor-pointer" onClick={() => setGameCategory(1)}>
                                                <img src={require('../../assets/img/ourGames/icon-slots.png').default} className="img-fluid iconSize" />
                                                <div className="ml-3">
                                                    <span className="categoryTabTitle font-weight-bold text-white">Slots</span>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-start align-items-center py-3 gameCatBorder pl-0 pl-lg-4 cursor-pointer" onClick={() => setGameCategory(2)}>
                                                <img src={require('../../assets/img/ourGames/icon-sports.png').default} className="img-fluid iconSize" />
                                                <div className="ml-3">
                                                    <span className="categoryTabTitle font-weight-bold text-white">Sports</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-block d-md-none py-3" id="gameCatMenu">
                                            <Select
                                                defaultValue={0}
                                                onChange={(e) => {
                                                    // console.log("Test", e);
                                                    setGameCategory(e.target.value);
                                                }}
                                                displayEmpty
                                                // inputProps={{ 'aria-label': 'Without label' }}
                                                style={{
                                                    width: "100%",
                                                    background: "rgba(217, 217, 217, 0.1)",
                                                    borderRadius: "15px",
                                                    padding: "0",
                                                    border: "2px solid #A18C68"
                                                }}
                                            >
                                                <MenuItem value={0}>
                                                    <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setGameCategory(0)}>
                                                        <img src={require('../../assets/img/ourGames/icon-liveDealer.png').default} className="img-fluid iconSize" style={{ width: "40px" }} />
                                                        <div className="ml-3">
                                                            <span className="categoryTabTitle font-weight-bold text-white">Live Dealer</span>
                                                        </div>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setGameCategory(1)}>
                                                        <img src={require('../../assets/img/ourGames/icon-slots.png').default} className="img-fluid iconSize" style={{ width: "40px" }} />
                                                        <div className="ml-3">
                                                            <span className="categoryTabTitle font-weight-bold text-white">Slots</span>
                                                        </div>
                                                    </div>
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    <div className="d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setGameCategory(2)}>
                                                        <img src={require('../../assets/img/ourGames/icon-sports.png').default} className="img-fluid iconSize" style={{ width: "40px" }} />
                                                        <div className="ml-3">
                                                            <span className="categoryTabTitle font-weight-bold text-white">Sports</span>
                                                        </div>
                                                    </div>
                                                </MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-9 gameDetailsBg" >
                                        <div className={selectedGameCategory === 0 ? "d-block" : "d-none"}>
                                            <Row className="justify-content-center align-items-center text-left pt-5">
                                                <Col lg={11} md={11} sm={11} xs={11}>
                                                    <span className="headerTitle">Live Dealer</span> <br />
                                                    <span className="brandsDesc">Yeebet Live offer comes in 3 main solutions: Yeebet API, Yeebet Dedicated Tables Solution & Yeebet Feed Solution. Business growth opportunities go hand in hand with the excitement of Live Dealer Games.</span> <br />
                                                    <br />
                                                    <hr style={{ border: "1.5px solid #A18C68" }} />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <Row className="justify-content-center align-items-start my-5 text-center" style={{ height: "50vh", overflow: "auto" }}>
                                                        {
                                                            liveDealer_Array.map((item, i) => (
                                                                <Col lg={4} md={6} sm={12} xs={12} key={i} className="text-center mb-5 px-2" style={{ position: "sticky", zIndex: "10" }}>
                                                                    <div className="gameList cursor-pointer">
                                                                        <div className="position-relative" onClick={() => setSelectedGameDetails(i)}>
                                                                            <img src={item.img} className="w-100 h-100" />
                                                                            <div className="position-absolute hoverLayer w-100 h-100">
                                                                                <Row className="justify-content-center align-items-center text-center py-5" style={{ height: "100%", overflowY: "auto" }}>
                                                                                    <Col lg={10} md={11} sm={11} xs={11}>
                                                                                        <div className="expandedDesc" style={{ height: "auto" }}>
                                                                                            <span className="learnMoreText">
                                                                                                Learn More >
                                                                                            </span>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </div>

                                                                        <div className="gameDetailsBox pt-4 px-4">
                                                                            <div className="text-left">
                                                                                <span className="gameDetailsTitle">{item.title}</span> <br />
                                                                                <span className="gameDetailsShort">{item.short}</span>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className={selectedGameCategory === 1 ? "d-block" : "d-none"}>
                                            <Row className="justify-content-center align-items-center text-left pt-5">
                                                <Col lg={11} md={11} sm={11} xs={11}>
                                                    <span className="headerTitle">Slots</span> <br />
                                                    <span className="brandsDesc">
                                                        Yeebet Gaming Group ‘s Metasoft provides an expansive portfolio of slot games, boasting a total of more than 500 innovative in-house and branded titles. These include fan favourite such as Father of Olympus, Four Seasons Dragons, The Silver Tiger, Jungle Diary, Queen Of The South & Candy Rush, through to exclusive content like Lady Of Kyoto and Lady Valkyrie.
                                                    </span> <br />
                                                    <br />
                                                    <hr style={{ border: "1.5px solid #A18C68" }} />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <Row className="justify-content-center align-items-start my-5 text-center" style={{ height: "50vh", overflow: "auto" }}>
                                                        {
                                                            slots_Array.map((item, i) => (
                                                                <Col lg={4} md={6} sm={12} xs={12} key={i} className="text-center mb-5 px-2" style={{ position: "sticky", zIndex: "10" }}>
                                                                    <div className="position-relative gameList cursor-pointer">
                                                                        <img src={item.img} className="w-100 h-100" />
                                                                    </div>
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className={selectedGameCategory === 2 ? "d-block" : "d-none"}>
                                            <Row className="justify-content-center align-items-center text-left pt-5">
                                                <Col lg={11} md={11} sm={11} xs={11}>
                                                    <span className="headerTitle">Sports</span> <br />
                                                    <span className="brandsDesc">With all-in-one sports betting software, you can offer over 70K live matches and 140K pre-match events each month. 120+ sports types: from football betting to tennis to volleyball and hockey.</span> <br />
                                                    <br />
                                                    <hr style={{ border: "1.5px solid #A18C68" }} />
                                                </Col>
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <Row className="justify-content-center align-items-start my-5 text-center" style={{ height: "50vh", overflow: "auto" }}>
                                                        {
                                                            sports_Array.map((item, i) => (
                                                                <Col lg={4} md={6} sm={12} xs={12} key={i} className="text-center mb-5 px-2" style={{ position: "sticky", zIndex: "10" }}>
                                                                    <div className="position-relative gameList cursor-pointer">
                                                                        <img src={item.img} className="w-100 h-100" />
                                                                    </div>
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sideItem1Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem1.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem2Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem2.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem3Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem4.png').default} className="img-fluid" />
                    </div>
                </div> */}

                    {/* <div className="live-dealer position-relative py-5">
                    <Row className="justify-content-center align-items-center text-center pt-5">
                        <Col lg={7} md={10} sm={11} xs={11}>
                            <span className="headerTitle">Live Dealer</span> <br />
                            <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                            <span className="brandsDesc">Yeebet Live offer comes in 3 main solutions: Yeebet API, Yeebet Dedicated Tables Solution & Yeebet Feed Solution. Business growth opportunities go hand in hand with the excitement of Live Dealer Games.</span> <br />
                        </Col>


                        <Col lg={9} md={11} sm={12} xs={12}>
                            <Row className="justify-content-center align-items-center py-5 text-center">
                                {
                                    liveDealer_Array.map((item, i) => (
                                        <Col lg={4} md={6} sm={12} xs={12} key={i} className="text-center mb-5" style={{position:"sticky", zIndex:"10"}}>
                                            <div className="position-relative gameList cursor-pointer">
                                                <img src={item.img} className="w-100 h-100" />
                                                <div className="position-absolute gameDetailsPos text-left">
                                                    <span className="gameDetailsTitle">{item.title}</span> <br />
                                                    <span className="gameDetailsShort">{item.short}</span>
                                                </div>

                                                <div className="position-absolute hoverLayer w-100 h-100">
                                                    <Row className="justify-content-center align-items-center text-center py-5" style={{ height: "100%", overflowY: "auto" }}>
                                                        <Col lg={10} md={11} sm={11} xs={11}>
                                                            <div className="expandedDesc" style={{ height: "auto" }}>
                                                                <span className="gameDetailsLong">
                                                                    {item.long}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row className="justify-content-center align-items-center text-center pt-5">
                        <Col lg={7} md={10} sm={11} xs={11} className="pt-5">
                            <span className="headerTitle">Slots</span> <br />
                            <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                            <span className="brandsDesc">Yeebet Gaming Group ‘s Metasoft provides an expansive portfolio of slot games, boasting a total of more than 500 innovative in-house and branded titles. These include fan favourite such as Father of Olympus, Four Seasons Dragons, The Silver Tiger, Jungle Diary, Queen Of The South & Candy Rush, through to exclusive content like Lady Of Kyoto and Lady Valkyrie.
                                <br /><br />
                                Our Yeebet Gaming Group’s Metasoft ONE API solutions offer players access to a single account and wallet across multiple devices. Combined with our IMS platform, Business Intelligence technology and automated marketing tools, licensees can offer their customers an unbeatable casino experience</span> <br />
                        </Col>
                        <Col lg={9} md={11} sm={12} xs={12}>
                            <Row className="justify-content-start align-items-center pt-5 text-center">
                                {
                                    slots_Array.map((item, i) => (
                                        <Col lg={3} md={4} sm={4} xs={4} key={i} className="text-center mb-5 paddingNarrow"  style={{position:"sticky", zIndex:"10"}}>
                                            <div className="position-relative gameList cursor-pointer">
                                                <img src={item.img} className="w-100 h-100" />
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row className="justify-content-center align-items-center text-center py-5">
                        <Col lg={7} md={10} sm={11} xs={11} className="pt-5">
                            <span className="headerTitle">Sports</span> <br />
                            <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                            <span className="brandsDesc">
                                With all-in-one sports betting software, you can offer over 70K live matches and 140K pre-match events each month. 120+ sports types: from football betting to tennis to volleyball and hockey.
                            </span> <br />
                        </Col>
                        <Col lg={9} md={11} sm={12} xs={12}>
                            <Row className="justify-content-start align-items-center pt-5 text-center">
                                {
                                    sports_Array.map((item, i) => (
                                        <Col lg={4} md={4} sm={12} xs={12} key={i} className="text-center mb-5"  style={{position:"sticky", zIndex:"10"}}>
                                            <div className="position-relative gameList cursor-pointer">
                                                <img src={item.img} className="w-100 h-100" />
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>



                    <div className="sideItem1Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem1.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem2Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem2.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem3Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem3.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem4Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem4.png').default} className="img-fluid" />
                    </div>

                     <div className="sideItem5Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem5.png').default} className="img-fluid" />
                    </div>

                  <div className="sideItem6Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem6.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem7Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem7.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem8Pos-games">
                        <img src={require('../../assets/img/ourGames/sideItem8.png').default} className="img-fluid" />
                    </div>
                </div> */}


                    <Modal
                        isOpen={openModal2}
                        onRequestClose={() => setOpenModal2(false)}
                        style={customStyles}
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        contentLabel="Details Modal"
                    >
                        {
                            selectedGameDetails >= 0 ?
                                <div className="gameDetailsPopUpBg row justify-content-center align-items-start position-relative">
                                    <div className="col-12 col-md-6">
                                        <img src={liveDealer_Array[selectedGameDetails].img} className="w-100 h-100 object-cover" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="py-5 px-3">
                                            <span className="popUpGameTitle">{liveDealer_Array[selectedGameDetails].title}</span> <br /><br />
                                            <span className="popUpGameDesc">{liveDealer_Array[selectedGameDetails].long}</span>
                                        </div>
                                    </div>
                                    <FaTimes className="modalCloseBtn cursor-pointer" onClick={() => setOpenModal2(false)} />
                                </div>
                                : ""
                        }
                    </Modal>
                </div>

                <div className="position-sticky">
                    <DarkFooter />
                </div>
            </div>
        </>
    );
}

export default OurGames;
