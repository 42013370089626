import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import MainNavbar from "components/Navbars/MainNavbar";

import "../../assets/css/brands.scss";
import { Col, Row } from "reactstrap";
import Bounce from 'react-reveal/Bounce';
import ReadMoreReact from 'read-more-react';

import brands1 from "../../assets/img/ourBrands/brands1.png";
import brands2 from "../../assets/img/ourBrands/brands2.png";
import brands3 from "../../assets/img/ourBrands/brands3.png";
import brands4 from "../../assets/img/ourBrands/brands4.png";
import brands5 from "../../assets/img/ourBrands/brands5.png";
import brands6 from "../../assets/img/ourBrands/brands6.png";
import brands7 from "../../assets/img/ourBrands/brands7.png";
import brands8 from "../../assets/img/ourBrands/brands8.png";
import brands9 from "../../assets/img/ourBrands/brands9.png";
import brands11 from "../../assets/img/ourBrands/brands11.png";
import brands12 from "../../assets/img/ourBrands/brands12.png";
import brands13 from "../../assets/img/ourBrands/brands13.png";

const brandsList = [
    { img: brands1, newTab: false, title: "Yeebet Live", desc: "We offer innovative solutions in our multi-award winning Live Dealer. Make it easy for your players to enjoy their favorite live dealer games such as Classic Baccarat, Speedy Baccarat, Classic Roulette, Auto Roulette etc.", url: "/yeebet-live" },
    // { img: brands2, title: "Yeebet Sports", desc: "With this all-in-one sports betting software, you will be able to offer your players over 70K live matches and 140K pre-match events every month. All sports are available in more than 20 languages, from football betting to tennis to volleyball to hockey.", url: "https://ibcbet77.com/" },
    { img: brands11, newTab: true, title: "PLAYXOS", desc: "PLAYXOS’s platform includes player account management (PAM), anti-fraud, game management and reporting engine, payment management module, cashflow reporting, and CMS.", url: "https://playxos.com/" },
    { img: brands12, newTab: true, title: "Metapay", desc: "Metapay has aggregate all the Asia payment provider by providing P2P and Ewallet service with different currency as in MYR, HKD, IDR, INR, VND, JYP, BDT, BRL, SGD, THB and etc.", url: "https://metapay168.net/" },
    { img: brands13, newTab: true, title: "MetaSoft", desc: "World first advanced automation aggregator of games and payment solution where it saves your time cost and control everything back office at your own.", url: "http://metasoftapi.com" },
    { img: brands3, newTab: true, title: "Yeebet News", desc: "We partner with industry leaders to bring you the most insightful content in igaming. We supply news, data, in-depth analysis and interviews across our digital and print channels so that you can stay informed and do your job to the highest standard. We provide directory of game api providers, payment gateway providers and online casino white label solution across the Asia.", url: "https://yeebet.info/" },
    // { img: brands5, newTab: true, title: "MGC Slots", desc: "MGC Slots is a warehouse of all your favorite casino-based games. Whether you’re a fan of poker, slots, or blackjack, MGC Slots has a little bit of something to give to everyone.", url: "http://mgcslot.com/" },
    { img: brands4, newTab: true, title: "UU Slots", desc: "UU Slots is dedicated to creating unique and exciting online video slots and other games, leading innovation in the industry and staying ahead of the competition. We are committed to providing the best service to our business partners in order to ensure mutual benefits for all parties.", url: "https://uuslots.info/" },
    // { img: brands6, title: "HC Slots", desc: "", url: "https://www.hcgame888.com/demo/#/" },
]



function OurBrands() {

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <MainNavbar />

            <div className="" id="brands">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">Our Brands</span>
                </div> */}

                <div className="brands-liveBg">
                    {/* <div className="header-section position-relative py-5">
                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={10} md={10} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center text-center">
                                    <Col lg={6} md={6} sm={8} xs={8}>
                                        <img src={require('../../assets/img/aboutUs/header-logo.png').default} className="img-fluid" />
                                    </Col>
                                    <Col lg={6} md={9} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle">Introduction</span> <br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vel finibus mi, vel feugiat velit. Mauris at condimentum lacus. Nulla turpis mi, pretium non metus sit amet, tempor porttitor neque. Integer id risus augue. Suspendisse varius neque augue, vitae lacinia libero rhoncus sit amet.
                                        </span> <br />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div> */}

                    <div className="position-relative py-5" >
                        <Row className="justify-content-center align-items-center pb-5 text-center" style={{ position: "sticky", zIndex: "10" }}>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="mx-auto" style={{ maxWidth: "1300px" }}>
                                    <Row className="justify-content-start align-items-center text-center brands-card px-2 px-md-4 px-lg-5 py-5">
                                        {brandsList.map((item, i) => (
                                            <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-4" style={{ paddingLeft: "7px", paddingRight: "7px" }}>
                                                <Bounce duration={1500 + (400 * i)}>
                                                    <div className="brandsList-container py-5">
                                                        <div className="minHeight-20">
                                                            <img src={item.img} className="img-fluid brandsList-logo" /> <br /> <br />
                                                            <span className="brandsList-title">{item.title}</span>

                                                            <div className="pt-2">
                                                                <p className="brandsList-desc">
                                                                    {/* <ReadMoreReact text={item.desc} 
                                                                     min={180}
                                                                     ideal={180}
                                                                     max={800}
                                                                     readMoreText={"Read More"}
                                                                    /> */}
                                                                    {item.desc}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <a href={item.url} target={item.newTab ? "_blank" : ""}>
                                                            <div className="pt-4">
                                                                <span className="learnMoreBrandText">Learn More > </span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </Bounce>
                                            </Col>
                                        ))}

                                    </Row>
                                </div>
                            </Col>
                        </Row>

                        <div className="sideItem1Pos-brands">
                            <img src={require('../../assets/img/ourBrands/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-brands">
                            <img src={require('../../assets/img/ourBrands/sideItem2.png').default} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default OurBrands;
