import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import "../../assets/css/privacyPolicy.scss";
import { Col, Row, Input } from "reactstrap";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { FaAngleRight } from 'react-icons/fa'

const career = [
    {
        position: 'Functional',
        summaryOfDuties: "The technical storage or access is strictly necessary for the legitimate purpose of enabling the use of a specific service explicitly requested by the subscriber or user, or for the sole purpose of carrying out the transmission of a communication over an electronic communications network.",
    },
    {
        position: 'Preferences',
        summaryOfDuties: "The technical storage or access is necessary for the legitimate purpose of storing preferences that are not requested by the subscriber or user.",
    },
    {
        position: 'Statistics',
        summaryOfDuties: "The technical storage or access that is used exclusively for statistical purposes. The technical storage or access that is used exclusively for anonymous statistical purposes. Without a subpoena, voluntary compliance on the part of your Internet Service Provider, or additional records from a third party, information stored or retrieved for this purpose alone cannot usually be used to identify you.",
    },
    {
        position: 'Marketing',
        summaryOfDuties: "The technical storage or access is required to create user profiles to send advertising, or to track the user on a website or across several websites for similar marketing purposes.",
    },
]

const placedCookies = [
    {
        title: 'Add This',
        properties: "Statistics",
        shareDataDesc: "This data is not shared with third parties.",
        statsName: "_at.hist.*",
        statsExpiration: "persistent",
        function: "Store the user’s usage history"
    },
    {
        title: 'Wistia',
        properties: "Statistics",
        shareDataDesc: "This data is not shared with third parties.",
        statsName: "wistia-video-progress-*",
        statsExpiration: "persistent",
        function: "Store if the user has seen embedded content"
    },
    {
        title: 'WPForms',
        properties: "Preferences",
        shareDataDesc: "This data is not shared with third parties.",
        statsName: "_wpfuuid",
        statsExpiration: "11 years",
        function: "Store a unique user ID"
    },
    {
        title: 'Hotjar',
        properties: "Statistics, Functional",
        shareDataDesc: "This data is not shared with third parties.",
        statsName: "_wpfuuid",
        statsExpiration: "11 years",
        function: "Store a unique user ID"
    },

]


function CookiePolicy() {
    const [selectedContent, setSelectedContent] = React.useState(0);
    const [selectedContent2, setSelectedContent2] = React.useState(0);

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <IndexNavbar />

            <div className="" id="privacyPolicy">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">Cookie Policy</span>
                </div> */}

                <div className="privacyPolicyBg position-relative">
                    <div className="header-section position-relative py-5">
                        <Row className="justify-content-center align-items-center text-center">
                            <Col lg={10} md={10} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center text-center" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={11} xs={11} className="text-center text-lg-left">
                                        <span className="brandsDesc">
                                            This page was last changed on December 15, 2022, last checked on December 15, 2022 and applies to citizens of Australia.                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">1.0 <span className="headerTitle">Introduction</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            Our website, yeebet.org (hereinafter: “the website”) uses cookies and other related technologies (for convenience all technologies are referred to as “cookies”). Cookies are also placed by third parties we have engaged. In the document below we inform you about the use of cookies on our website.
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">2.0 <span className="headerTitle">What Are Cookies?</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            A cookie is a small simple file that is sent along with pages of this website and stored by your browser on the hard drive of your computer or another device. The information stored therein may be returned to our servers or to the servers of the relevant third parties during a subsequent visit.
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">3.0 <span className="headerTitle">What Are Scripts?</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            A script is a piece of program code that is used to make our website function properly and interactively. This code is executed on our server or on your device.
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">4.0 <span className="headerTitle">What Is A Web Beacon?</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            A web beacon (or a pixel tag) is a small, invisible piece of text or image on a website that is used to monitor traffic on a website. In order to do this, various data about you is stored using web beacons.
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">5.0 <span className="headerTitle">Consent</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            When you visit our website for the first time, we will show you a pop-up with an explanation about cookies. You do have the right to opt-out and to object against the further use of non-functional cookies.
                                            <br /><br />
                                            <b>5.1 Manage your consent settings</b>
                                        </span> <br />
                                        <Accordion preExpanded={[0]} allowZeroExpanded className="pt-2 text-left">
                                            {career.map((item, i) => (
                                                <AccordionItem key={i} uuid={i} className={i === selectedContent2 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent2(i)}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton>
                                                            <div className="row justify-content-center align-items-center">
                                                                <div className="col-10">
                                                                    <div className="">
                                                                        <span className={i === selectedContent2 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                            {item.position}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="">
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{color: i === selectedContent2 ? "#CAB48E": "#fff"}}/>                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel>
                                                        <div className="row justify-content-start align-items-center">
                                                            <div className="col-12">
                                                                <span className="accordionContent">
                                                                    {item.summaryOfDuties}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>

                                        <div className="pt-3">
                                            <span className="brandsDesc">
                                                You can also disable the use of cookies via your browser, but please note that our website may no longer work properly.
                                            </span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">6.0 <span className="headerTitle">Cookies</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            <b>6.1 Technical or functional cookies</b>
                                            <br /><br />
                                            Some cookies ensure that certain parts of the website work properly and that your user preferences remain known. By placing functional cookies, we make it easier for you to visit our website. This way, you do not need to repeatedly enter the same information when visiting our website and, for example, the items remain in your shopping cart until you have paid. We may place these cookies without your consent.
                                            <br /><br />
                                            <b>6.2 Analytical cookies</b>
                                            <br /><br />
                                            We use analytical cookies to optimize the website experience for our users. With these analytical cookies we get insights in the usage of our website.
                                            <br /><br />
                                            <b>6.3 Marketing/Tracking cookies</b>
                                            <br /><br />
                                            Marketing/Tracking cookies are cookies or any other form of local storage, used to create user profiles to display advertising or to track the user on this website or across several websites for similar marketing purposes.
                                            <br /><br />
                                            <b>6.4 Social media</b>
                                            <br /><br />
                                            On our website, we have included content to promote web pages (e.g. “like”, “pin”) or share (e.g. “tweet”) on social networks. This content is embedded with code derived from third parties and places cookies. This content might store and process certain information for personalized advertising.
                                            <br /><br />
                                            Please read the privacy statement of these social networks (which can change regularly) to read what they do with your (personal) data which they process using these cookies. The data that is retrieved is anonymized as much as possible.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">7.0 <span className="headerTitle">Placed Cookies</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <Accordion preExpanded={[0]} allowZeroExpanded className="text-left">
                                            <AccordionItem className={selectedContent == 0 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(0)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-6">
                                                                <div className="">
                                                                    <span className={selectedContent == 0 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        Add This
                                                                    </span> <br />
                                                                    <span className={selectedContent == 0 ? "accordionSideItemText d-block d-md-none" : "accordionSideItemText-inactive d-block d-md-none"}>
                                                                        Statistics
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-6">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 0 ? "accordionSideItemText d-none d-md-block" : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Statistics
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 0 ? "#CAB48E" : "#fff" }} />                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                This data is not shared with third parties.
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Statistics</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>_at.hist.*</td>
                                                                        <td>persistent</td>
                                                                        <td>Store the user’s usage history</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className={selectedContent == 1 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(1)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-4">
                                                                <div className="">
                                                                    <span className={selectedContent == 1 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        Wistia
                                                                    </span> <br />
                                                                    <span className={selectedContent == 1 ? "accordionSideItemText d-block d-md-none" : "accordionSideItemText-inactive d-block d-md-none"}>
                                                                        Statistics
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-8">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 1 ? "accordionSideItemText d-none d-md-block" : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Statistics
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 1 ? "#CAB48E" : "#fff" }} />                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                This data is not shared with third parties.
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Statistics</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>wistia-video-progress-*</td>
                                                                        <td>persistent</td>
                                                                        <td>Store if the user has seen embedded content</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className={selectedContent == 2 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(2)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-4">
                                                                <div className="">
                                                                    <span className={selectedContent == 2 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        WPForms
                                                                    </span> <br />
                                                                    <span className={selectedContent == 2 ? "accordionSideItemText d-block d-md-none" : "accordionSideItemText-inactive d-block d-md-none"}>
                                                                        Preferences
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-8">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 2 ? "accordionSideItemText d-none d-md-block" : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Preferences
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 2 ? "#CAB48E" : "#fff" }} />                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                This data is not shared with third parties.
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Statistics</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>_wpfuuid</td>
                                                                        <td>11 years</td>
                                                                        <td>Store a unique user ID</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className={selectedContent == 3 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(3)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-4">
                                                                <div className="">
                                                                    <span className={selectedContent == 3 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        Hotjar
                                                                    </span> <br />
                                                                    <span className={selectedContent == 3 ? "accordionSideItemText d-block d-md-none " : "accordionSideItemText-inactive d-block d-md-none"}>
                                                                        Statistics, Functional
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-8">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 3 ? "accordionSideItemText d-none d-md-block " : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Statistics, Functional
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 3 ? "#CAB48E" : "#fff" }} />                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                This data is not shared with third parties.
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Statistics</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>_hjSessionUser_*</td>
                                                                        <td>1 year</td>
                                                                        <td>Store a unique user ID</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_hjAbsoluteSessionInProgress</td>
                                                                        <td>session</td>
                                                                        <td>Store unique visits</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_hjIncludedInPageviewSample</td>
                                                                        <td>30 minutes</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_hjIncludedInSessionSample</td>
                                                                        <td>30 minutes</td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="table-responsive mt-4">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Functional</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>_hjSession_*</td>
                                                                        <td>session</td>
                                                                        <td>Provide functions across pages</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className={selectedContent == 4 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(4)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-4">
                                                                <div className="">
                                                                    <span className={selectedContent == 4 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        Google Analytics
                                                                    </span> <br />
                                                                    <span className={selectedContent == 4 ? "accordionSideItemText d-block d-md-none" : "accordionSideItemText-inactive d-block d-md-none"}>
                                                                        Statistics, Functional
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-8">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 4 ? "accordionSideItemText d-none d-md-block" : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Statistics, Functional
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 4 ? "#CAB48E" : "#fff" }} />                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                This data is not shared with third parties.
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Statistics</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>_gid</td>
                                                                        <td>1 day</td>
                                                                        <td>Store and count pageviews</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_ga_*</td>
                                                                        <td>1 day</td>
                                                                        <td>Store and count pageviews</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_ga</td>
                                                                        <td>2 years</td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_hjIncludedInSessionSample</td>
                                                                        <td>30 minutes</td>
                                                                        <td>Store and count pageviews</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div className="table-responsive mt-4">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Functional</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>_gat_UA-*</td>
                                                                        <td>session</td>
                                                                        <td>Provide technical monitoring</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className={selectedContent == 5 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(5)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-4">
                                                                <div className="">
                                                                    <span className={selectedContent == 5 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        Criteo
                                                                    </span> <br />
                                                                    <span className={selectedContent == 5 ? "accordionSideItemText d-block d-md-none" : "accordionSideItemText-inactive d-block d-md-non"}>
                                                                        Functional
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-8">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 5 ? "accordionSideItemText d-none d-md-block" : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Functional
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 5 ? "#CAB48E" : "#fff" }} />                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                This data is not shared with third parties.
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Statistics</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>has_js</td>
                                                                        <td>session</td>
                                                                        <td>Provide functions across pages</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className={selectedContent == 6 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(6)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-4">
                                                                <div className="">
                                                                    <span className={selectedContent == 6 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        Complianz
                                                                    </span> <br />
                                                                    <span className={selectedContent == 6 ? "accordionSideItemText d-block d-md-none" : "accordionSideItemText-inactive d-block d-md-none"}>
                                                                        Functional
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-8">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 6 ? "accordionSideItemText d-none d-md-block" : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Functional
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 6 ? "#CAB48E" : "#fff" }} />                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                This data is not shared with third parties. For more information, please read the Complianz Privacy Statement.
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Functional</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>cmplz_user_data</td>
                                                                        <td>365 days</td>
                                                                        <td>Read to determine which cookie banner to show</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_consented_services</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_policy_id</td>
                                                                        <td>365 days</td>
                                                                        <td>Store accepted cookie policy ID</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_marketing</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_statistics</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_preferences</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_functional</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_id</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_banner-status</td>
                                                                        <td>365 days</td>
                                                                        <td>Store if the cookie banner has been dismissed</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_saved_categories</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>cmplz_saved_services</td>
                                                                        <td>365 days</td>
                                                                        <td>Store cookie consent preferences</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className={selectedContent == 7 ? "mb-4 accordion-item borderBottomWhite" : "mb-4 accordion-item-inactive borderBottomWhite"} onClick={() => setSelectedContent(7)}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        <div className="row justify-content-center align-items-center">
                                                            <div className="col-10 col-md-4">
                                                                <div className="">
                                                                    <span className={selectedContent == 7 ? "accordionTitle lh-1" : "accordionTitle-inactive lh-1"}>
                                                                        Miscellaneous
                                                                    </span> <br />
                                                                    <span className={selectedContent == 7 ? "accordionSideItemText d-block d-md-none" : "accordionSideItemText-inactive d-block d-md-none"}>
                                                                        Purpose pending investigation
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-2 col-md-8">
                                                                <div className="text-right d-flex justify-content-end">
                                                                    <span className={selectedContent == 7 ? "accordionSideItemText d-none d-md-block" : "accordionSideItemText-inactive d-none d-md-block"}>
                                                                        Purpose pending investigation
                                                                    </span>
                                                                    <FaAngleRight className="h3 my-0 ml-3 accordionIcon" style={{ color: selectedContent == 7 ? "#CAB48E" : "#fff" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div className="row justify-content-start align-items-start borderTop pt-4">
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Usage</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-6">
                                                            <span className="accordionContent">
                                                                <b>Sharing Data</b>
                                                            </span> <br /><br />
                                                            <span className="accordionContent">
                                                                Sharing of data is pending investigation
                                                            </span>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table  table-bordered text-white text-center mt-5">
                                                                <thead className="w-100">
                                                                    <tr>
                                                                        <th colspan="3">Purpose pending investigation</th>
                                                                    </tr>
                                                                    <tr className="tableTitleRow">
                                                                        <th style={{ width: "33.33%" }}>Name</th>
                                                                        <th style={{ width: "33.33%" }}>Expiration</th>
                                                                        <th style={{ width: "33.33%" }}>Function</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="w-100">
                                                                    <tr>
                                                                        <td>u_sclid</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>WP_DATA_USER_2</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>wistia</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_at.cww</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_sclid</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>at-rand</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>loglevel</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_scid</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_fbp</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>__atuvc</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>OptanonAlertBoxClosed</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>OptanonConsent</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Drupal_tableDrag_showWeight</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>tt_sessionId</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>tt_pageId</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>tt_pixel_session_index</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>tt_appInfo</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>__atssc</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_sctr</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_tt_enable_cookie</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>_ttp</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Drupal_visitor_mbp_current_folder</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">8.0 <span className="headerTitle">Enabling/Disabling And Deleting Cookies</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            You can use your internet browser to automatically or manually delete cookies. You can also specify that certain cookies may not be placed. Another option is to change the settings of your internet browser so that you receive a message each time a cookie is placed. For more information about these options, please refer to the instructions in the Help section of your browser.
                                            <br /><br />
                                            Please note that our website may not work properly if all cookies are disabled. If you do delete the cookies in your browser, they will be placed again after your consent when you visit our websites again.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">9.0 <span className="headerTitle">Your Rights With Respect To Personal Data</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            You have the following rights with respect to your personal data:
                                            <br /><br />
                                            <ul className="text-left">
                                                <li>
                                                    you may submit a request for access to the data we process about you;
                                                </li>
                                                <li>
                                                    you may request an overview, in a commonly used format, of the data we process about you;
                                                </li>
                                                <li>
                                                    you may request correction or deletion of the data if it is incorrect or not or no longer relevant for any purpose under the Privacy Act.
                                                </li>
                                            </ul>
                                            <br />
                                            To exercise these rights, please contact us. Please refer to the contact details at the bottom of this cookie statement. If you have a complaint about how we handle your data, we would like to hear from you.
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="headerTitle_Gold">10.0 <span className="headerTitle">Contact Details</span> </span><br />
                                        <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                        <span className="brandsDesc">
                                            For questions and/or comments about our Cookie Policy and this statement, please contact us by using the following contact details:
                                            <br /><br />
                                            <b>Yeebet Gaming Ltd.</b><br />
                                            Sackwille House, 55 Picadilly, W1J 0DR, London, United Kingdom.<br />
                                            United Kingdom<br />
                                            Website: https://www.yeebet.org<br />
                                            Email: info@yeebetgaming.com<br />
                                        </span> <br />
                                    </Col>
                                </Row>

                                <Row className="justify-content-center align-items-center text-center py-4" style={{ position: "sticky", zIndex: "10" }}>
                                    <Col lg={12} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                        <span className="brandsDesc">
                                            This Cookie Policy was synchronised with cookiedatabase.org on January 5, 2023
                                        </span> <br />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        {/* <div className="sideItem1Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem1.png').default} className="img-fluid" />
                        </div>

                        <div className="sideItem2Pos-contactUs">
                            <img src={require('../../assets/img/contactUs/sideItem2.png').default} className="img-fluid" />
                        </div> */}
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default CookiePolicy;
