import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import MainNavbar from "components/Navbars/MainNavbar";
import DarkFooter from "components/Footers/DarkFooter.js";
import "../../assets/css/aboutUs.scss";
import { Col, Row } from "reactstrap";
import Marquee from "react-fast-marquee";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

import licensed_logo1 from "../../assets/img/aboutUs/licensed/logo1.png";
import licensed_logo2 from "../../assets/img/aboutUs/licensed/logo2.png";
import licensed_logo3 from "../../assets/img/aboutUs/licensed/logo3.png";
import licensed_logo4 from "../../assets/img/aboutUs/licensed/logo4.png";
import licensed_logo5 from "../../assets/img/aboutUs/licensed/logo5.png";
import licensed_logo6 from "../../assets/img/aboutUs/licensed/logo6.png";
import licensed_logo7 from "../../assets/img/aboutUs/licensed/logo7.png";
import licensed_logo8 from "../../assets/img/aboutUs/licensed/logo8.png";
import licensed_logo9 from "../../assets/img/aboutUs/licensed/logo9.png";
import licensed_logo10 from "../../assets/img/aboutUs/licensed/logo10.png";
import licensed_logo11 from "../../assets/img/aboutUs/licensed/logo11.png";
import licensed_logo12 from "../../assets/img/aboutUs/licensed/logo12.png";

import award_logo1 from "../../assets/img/aboutUs/awarded/logo1.png";
import award_logo2 from "../../assets/img/aboutUs/awarded/logo2.png";
import award_logo3 from "../../assets/img/aboutUs/awarded/logo3.png";
import award_logo4 from "../../assets/img/aboutUs/awarded/logo4.png";
import award_logo5 from "../../assets/img/aboutUs/awarded/logo5.png";
import award_logo6 from "../../assets/img/aboutUs/awarded/logo6.png";
import award_logo7 from "../../assets/img/aboutUs/awarded/logo7.png";
import award_logo8 from "../../assets/img/aboutUs/awarded/logo8.png";
import award_logo9 from "../../assets/img/aboutUs/awarded/logo9.png";
import award_logo10 from "../../assets/img/aboutUs/awarded/logo10.png";

const licensed_logoList = [
    { img: licensed_logo1 },
    { img: licensed_logo2 },
    { img: licensed_logo3 },
    { img: licensed_logo4 },
    { img: licensed_logo5 },
    { img: licensed_logo6 },
]

const licensed_logoList2 = [
    { img: licensed_logo7 },
    { img: licensed_logo8 },
    { img: licensed_logo9 },
    { img: licensed_logo10 },
    { img: licensed_logo11 },
    { img: licensed_logo12 },
]

const award_logoList = [
    { img: award_logo1 },
    { img: award_logo2 },
    { img: award_logo3 },
    { img: award_logo4 },
    { img: award_logo5 },
]

const award_logoList2 = [
    { img: award_logo6 },
    { img: award_logo7 },
    { img: award_logo8 },
    { img: award_logo9 },
    { img: award_logo10 },
]

function AboutUs() {
    const [selectedCountry, setCountry] = React.useState(0);

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <MainNavbar />

            <div className="" id="aboutUs">
                {/* <div className="title-section d-flex justify-content-center align-items-center text-center">
                    <span className="titleSectionText">About Us</span>
                </div> */}

                <div className="header-section position-relative py-5">
                    <Row className="justify-content-center align-items-center text-center pb-5 pb-md-0">
                        <Col lg={10} md={10} sm={12} xs={12}>
                            <Row className="justify-content-center align-items-center text-center">
                                <Col lg={4} md={6} sm={8} xs={8}>
                                    <img src={require('../../assets/img/aboutUs/header-logo.png').default} className="img-fluid" />
                                </Col>
                                <Col lg={5} md={12} sm={12} xs={12} className="text-center text-lg-left">
                                    <span className="headerTitle">Who We Are</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    <span className="brandsDesc">
                                        In the iGaming industry, Yeebet Gaming delivers player-favorites to the most successful global brands. Through one API, we offer award-winning slots, live casino, bingo, virtual sports, sportsbook and more, available in all major regulated markets, languages, and currencies.
                                        <br /><br />
                                        Dedicated to creating games that players love time and time again, our professional team consistently delivers best qualities of games in class services to our partners worldwide.
                                        <br /><br />
                                        Our B2B online casino services are delivered through multiple channels and devices to our customers' players by working with more top-tier operators than any other provider in our market. Our innovative software, enterprise-class infrastructure, and people enable us to add real value to our customers' gaming businesses.
                                    </span> <br />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="what-we-do position-relative py-5">
                    <Row className="justify-content-center align-items-center text-center py-5">
                        <Col lg={11} md={11} sm={12} xs={12}>
                            <Row className="justify-content-center align-items-center pt-5 text-center">
                                <Col lg={6} md={10} sm={11} xs={11}>
                                    <span className="headerTitle">What We Do</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    <span className="brandsDesc">
                                        We develop to deliver the premium iGaming solutions from 0 to 1 where it helps our clients to boost sales performance, increase traffic and balance cost saving.
                                    </span> <br />
                                </Col>

                                <Col lg={10} md={11} sm={12} xs={12}>
                                    <Row className="justify-content-center align-items-center pt-5 mt-5 text-center">
                                        <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                            <Bounce duration={1500}>
                                                <div className="secondLayer-container" />
                                                <div className="whatWeDo-container py-5 px-4">
                                                    <img src={require('../../assets/img/aboutUs/vision-logo.png').default} className="img-fluid whatwedo-logo mb-3" /> <br />
                                                    <span className="whatwedo-title">Our Vision</span>

                                                    <div className="pt-4">
                                                        <span className="whatwedo-desc">To be the world best class live dealer provider which has the most quantity tables and diversified game content.</span>
                                                    </div>
                                                </div>
                                            </Bounce>
                                        </Col>

                                        <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                            <Bounce duration={1800}>
                                                <div className="secondLayer-container" />
                                                <div className="whatWeDo-container py-5 px-4">
                                                    <img src={require('../../assets/img/aboutUs/mission-logo.png').default} className="img-fluid whatwedo-logo mb-3" /> <br />
                                                    <span className="whatwedo-title">Our Mission</span>

                                                    <div className="pt-4">
                                                        <span className="whatwedo-desc">To provide outstanding quality services to our clients and create the best experiences to our players love time to time again.</span>
                                                    </div>
                                                </div>
                                            </Bounce>
                                        </Col>

                                        <Col lg={4} md={8} sm={12} xs={12} className="text-center mb-5">
                                            <Bounce duration={2200}>
                                                <div className="secondLayer-container" />
                                                <div className="whatWeDo-container py-5 px-4">
                                                    <img src={require('../../assets/img/aboutUs/value-logo.png').default} className="img-fluid whatwedo-logo mb-3" /> <br />
                                                    <span className="whatwedo-title">Our Value</span>

                                                    <div className="pt-4">
                                                        <span className="whatwedo-desc">With regard to our customers, external stakeholders, and surrounding communities, we believe in a culture of openness, respect, creativity, integrity, credibility, and care for others.</span>
                                                    </div>
                                                </div>
                                            </Bounce>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <div className="sideItem1Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem1.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem2Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem2.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className="licensed-By position-relative py-5">
                    <div className="mh-100">
                        <Row className="justify-content-center align-items-center text-center pt-5">
                            <Col lg={7} md={10} sm={11} xs={11}>
                                <span className="headerTitle">Licensed By</span> <br />
                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                <span className="brandsDesc">Committed to operators and players - Yeebet Gaming is a B2B provider of a full range of gaming categories. While our contact with end-users is limited, we look to ensure that responsible gaming remains at the forefront of our ethos as we strive for fair and compliant services. For more information on this matter, please visit begambleaware.org.</span> <br />
                            </Col>

                            <Col lg={8} md={11} sm={12} xs={12} className="pt-4 paddingNarrow">
                                <div >
                                    <div className="container-fluid text-center my-3 paddingNarrow">
                                        <Fade duration={1500}>
                                            <Marquee gradientColor={[33, 32, 34]} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                                                {licensed_logoList.map((item, i) => (
                                                    <div className="position-relative partnerLogoBg mx-4">
                                                        <img
                                                            key={i}
                                                            src={item.img}
                                                            className="img-fluid w-100"
                                                            alt="brand"
                                                        />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </Fade>

                                        <Fade duration={2000}>
                                            <Marquee className="mt-3" gradientColor={[33, 32, 34]} direction={"right"} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                                                {licensed_logoList2.map((item, i) => (
                                                    <div className="position-relative partnerLogoBg mx-4">
                                                        <img
                                                            key={i}
                                                            src={item.img}
                                                            className="img-fluid w-100"
                                                            alt="brand"
                                                        />
                                                    </div>
                                                ))}
                                            </Marquee>
                                        </Fade>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row className="justify-content-center align-items-center text-center pb-5" style={{ paddingTop: '10vh' }}>
                        <Col lg={7} md={10} sm={11} xs={11}>
                            <span className="headerTitle">Awarded By</span> <br />
                            <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                            <span className="brandsDesc">Over the years, we have won over SIXTY industry and non-industry awards. Here are a few of our favorites…</span> <br />
                        </Col>

                        <Col lg={8} md={11} sm={12} xs={12} className="pt-4 paddingNarrow">
                            <div >
                                <div className="container-fluid text-center my-3 paddingNarrow">
                                    <Fade duration={1500}>
                                        <Marquee gradientColor={[33, 32, 34]} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                                            {award_logoList.map((item, i) => (
                                                <div className="position-relative partnerLogoBg mx-4">
                                                    <img
                                                        key={i}
                                                        src={item.img}
                                                        className="img-fluid w-100"
                                                        alt="brand"
                                                    />
                                                </div>
                                            ))}
                                        </Marquee>
                                    </Fade>

                                    <Fade duration={2000}>
                                        <Marquee className="mt-3" gradientColor={[33, 32, 34]} direction={"right"} gradientWidth={window.innerWidth > 992 ? 50 : 0} pauseOnClick={true} pauseOnHover={true}>
                                            {award_logoList2.map((item, i) => (
                                                <div className="position-relative partnerLogoBg mx-4">
                                                    <img
                                                        key={i}
                                                        src={item.img}
                                                        className="img-fluid w-100"
                                                        alt="brand"
                                                    />
                                                </div>
                                            ))}
                                        </Marquee>
                                    </Fade>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="sideItem3Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem3.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem4Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem4.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem5Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem5.png').default} className="img-fluid" />
                    </div>

                    <div className="sideItem6Pos-about">
                        <img src={require('../../assets/img/aboutUs/sideItem6.png').default} className="img-fluid" />
                    </div>
                </div>

                <div className="social-responsibility">
                    <div className="position-relative">
                        <Row className="justify-content-center align-items-center text-center pt-5">
                            <Col lg={7} md={8} sm={11} xs={11}>
                                <span className="headerTitle">Social Responsibility</span> <br />
                                <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                <span className="brandsDesc">
                                    It is impossible for our industry to thrive without the trust and respect of players. We wouldn't be where we are today if we hadn't incorporated principles of fair play and responsible gaming into our culture.                                </span> <br />
                            </Col>
                        </Row>
                        <Row className="justify-content-center align-items-center text-center pt-4">
                            <Col lg={10} md={11} sm={12} xs={12}>
                                <Row className="justify-content-center align-items-center text-center">

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row className="justify-content-center align-items-center pt-5 text-center">
                                            <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                                <Bounce duration={1500}>
                                                    <div className="secondLayerRS-container" />
                                                    <div className="socialRespon-container py-5 px-4">
                                                        <img src={require('../../assets/img/aboutUs/audit-icon.png').default} className="img-fluid whatwedo-logo mb-3" /> <br />
                                                        <span className="whatwedo-title">Audit and Control</span>

                                                        <div className="pt-4">
                                                            <span className="whatwedo-desc">As a result, both randomness and fairness are ensured by independent bodies that are formally accredited by Yeebet Gaming . Our games are therefore tested and certified by Gaming Laboratories International, Quinel, and Gaming Associates for Random Number Generators, which is also a prerequisite for all markets we serve.</span>
                                                        </div>
                                                    </div>
                                                </Bounce>
                                            </Col>

                                            <Col lg={4} md={6} sm={12} xs={12} className="text-center mb-5">
                                                <Bounce duration={1800}>
                                                    <div className="secondLayerRS-container" />
                                                    <div className="socialRespon-container py-5 px-4">
                                                        <img src={require('../../assets/img/aboutUs/compilance-logo.png').default} className="img-fluid whatwedo-logo mb-3" /> <br />
                                                        <span className="whatwedo-title">Compliance</span>

                                                        <div className="pt-4">
                                                            <span className="whatwedo-desc">In addition to being licensed and regulated by several industry governing bodies, Yeebet Gaming releases games only when they are approved by these bodies. For all jurisdictions in which we operate or where our games are provided, we also follow strict protocol to comply with responsible gaming law.</span>
                                                        </div>
                                                    </div>
                                                </Bounce>
                                            </Col>

                                            <Col lg={4} md={8} sm={12} xs={12} className="text-center mb-5">
                                                <Bounce duration={2200}>
                                                    <div className="secondLayerRS-container" />
                                                    <div className="socialRespon-container py-5 px-4">
                                                        <img src={require('../../assets/img/aboutUs/protect-icon.png').default} className="img-fluid whatwedo-logo mb-3" /> <br />
                                                        <span className="whatwedo-title">User Protection</span>

                                                        <div className="pt-4">
                                                            <span className="whatwedo-desc">In accordance with our license conditions, Yeebet games are embedded with player protection software. As part of our commitment to social responsibility, we fully support the GambleAware program and conduct periodic internal testing for quality assurance, which is then verified by an independent third party. Our procedures are constantly reviewed to ensure that our technology and systems meet the requirements of the industry, our clients and regulated jurisdictions.</span>
                                                        </div>
                                                    </div>
                                                </Bounce>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>

                    <div className="position-relative mapDiv pt-5">
                        <div className="video-container d-none d-md-block" style={{ lineHeight: "0" }}>
                            <video autoPlay={true} playsInline muted loop id="mapVideo" style={{ width: "100%", height: 'auto' }}>
                                <source src={require('../../assets/img/aboutUs/map-video.mp4').default} type="video/mp4" />
                            </video>
                        </div>

                        <div className="video-container d-block d-md-none" style={{ lineHeight: "0" }}>
                            <video autoPlay={true} playsInline muted loop id="mapVideo" style={{ width: "100%", height: 'auto' }}>
                                <source src={require('../../assets/img/aboutUs/map-video-mobile.mp4').default} type="video/mp4" />
                            </video>
                        </div>

                        <div className="position-absolute officePos">
                            <Row className="justify-content-center align-items-center text-center pt-5">
                                <Col lg={4} md={8} sm={11} xs={11}>
                                    <span className="headerTitle">Our Offices</span> <br />
                                    <img src={require('../../assets/img/home/headerTitle-line.png').default} className="img-fluid" /> <br /><br />
                                    <span className="brandsDesc">
                                        Please feel free to contact us if you would like more information about Yeebet Gaming or if you are interested in applying for a new role.
                                    </span> <br />
                                </Col>
                            </Row>
                        </div>

                        <div className="position-absolute countryPos">
                            <Row className="justify-content-center align-items-center text-center pt-5">
                                <Col lg={4} md={9} sm={11} xs={11} className="position-relative">
                                    <div className="mt-5 py-3 px-4 locationDetailsDiv locationDetailsDiv1" style={{ display: selectedCountry === 0 ? "block" : "none" }}>
                                        <div className="text-left mb-4">
                                            <span className="locationTitle">Latvia</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <img src={require('../../assets/img/contactUs/office_name.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3">
                                                <span className="contactUs-detailsText">Yeebet Headquarter Office</span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-start pt-4">
                                            <img src={require('../../assets/img/contactUs/address.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3 text-left">
                                                <span className="contactUs-detailsText">
                                                    Tērbatas iela 73, Centra rajons,<br />
                                                    Rīga, LV-1001, Latvia
                                                </span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-center pt-4">
                                            <img src={require('../../assets/img/contactUs/email.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3">
                                                <span className="contactUs-detailsText">info@yeebetgaming.com</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 py-3 px-4 locationDetailsDiv locationDetailsDiv2" style={{ display: selectedCountry === 1 ? "block" : "none" }}>
                                        <div className="text-left mb-4">
                                            <span className="locationTitle">Hong Kong</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <img src={require('../../assets/img/contactUs/office_name.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3">
                                                <span className="contactUs-detailsText">Yeebet Asia Head Office</span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-start pt-4">
                                            <img src={require('../../assets/img/contactUs/address.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3 text-left">
                                                <span className="contactUs-detailsText">
                                                    34/F, Henley Building, 5 Queen's Road Central,<br />
                                                    Central, Hong Kong
                                                </span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-center pt-4">
                                            <img src={require('../../assets/img/contactUs/email.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3">
                                                <span className="contactUs-detailsText">info@yeebetgaming.com</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 py-3 px-4 locationDetailsDiv locationDetailsDiv3" style={{ display: selectedCountry === 2 ? "block" : "none" }}>
                                        <div className="text-left mb-4">
                                            <span className="locationTitle">Philippines</span>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center">
                                            <img src={require('../../assets/img/contactUs/office_name.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3">
                                                <span className="contactUs-detailsText">Yeebet Asia Office</span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-start pt-4">
                                            <img src={require('../../assets/img/contactUs/address.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3 text-left">
                                                <span className="contactUs-detailsText">
                                                    216 Sen. Gil J. Puyat Ave, <br />
                                                    Makati, Metro Manila, Philippines
                                                </span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-start align-items-center pt-4">
                                            <img src={require('../../assets/img/contactUs/email.png').default} className="img-fluid contactUs-iconSize" />
                                            <div className="ml-3">
                                                <span className="contactUs-detailsText">info@yeebetgaming.com</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center text-center pt-4">
                                <Col lg={4} md={7} sm={11} xs={11}>
                                    <Row className="justify-content-center align-items-center text-center pt-2">
                                        <Col lg={4} md={4} sm={4} xs={4}>
                                            <img src={require('../../assets/img/aboutUs/country1.png').default} className={selectedCountry === 0 ? "img-fluid countryActive" : "img-fluid countryInactive cursor-pointer"} onClick={() => setCountry(0)} />
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4}>
                                            <img src={require('../../assets/img/aboutUs/country2.png').default} className={selectedCountry === 1 ? "img-fluid countryActive" : "img-fluid countryInactive cursor-pointer"} onClick={() => setCountry(1)} />
                                        </Col>
                                        <Col lg={4} md={4} sm={4} xs={4}>
                                            <img src={require('../../assets/img/aboutUs/country3.png').default} className={selectedCountry === 2 ? "img-fluid countryActive" : "img-fluid countryInactive cursor-pointer"} onClick={() => setCountry(2)} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            <DarkFooter />
        </>
    );
}

export default AboutUs;
